import React from "react";
import noUser from '../../../assets/images/noUser.png';
import {
    AnswerBox,
    DetailContents, GrayText,
    Image, InlineEmphasisText, PreviewList,
    QuestionBox,
    QuestionContents,
    QuestionInfo, QuestionText,
    UserImageBox, UserInfo
} from "./service.style";
import dayjs from "dayjs";
import PreviewFile from "../../share/PreviewFile";
import theme from "../../../styles/theme";

const QuestionDetailContents = ({
                                    questionData,
                                    answerData,
                                    attachedFile = []
                                }) => {
    return (
        <DetailContents>
            <QuestionBox>
                <QuestionInfo>
                    <UserImageBox>
                        <Image
                            src={questionData.creatorImage}
                            onError={e => e.target.src = noUser}
                        />
                    </UserImageBox>
                    <QuestionContents>
                        <UserInfo>
                            <InlineEmphasisText>
                                {questionData.creatorName}&nbsp;
                                {questionData.creatorRank}
                            </InlineEmphasisText>
                            <GrayText $margin='0 0 0 10px'>
                                {dayjs(questionData.createdAt).format('YYYY-MM-DD HH:mm')}
                            </GrayText>
                        </UserInfo>
                        <QuestionText>
                            {questionData.title}
                            {questionData.contents}
                        </QuestionText>

                        {attachedFile.length > 0 && (
                            <>
                                <GrayText $margin='30px 0 12px'>{attachedFile.length}개의 첨부파일</GrayText>
                                <PreviewList>
                                    {attachedFile.map((file, index) => (
                                        <PreviewFile
                                            DOWNLOAD
                                            key={`${index}-file-list`}
                                            file={file}
                                        />
                                    ))}
                                </PreviewList>
                            </>
                        )}
                    </QuestionContents>
                </QuestionInfo>
            </QuestionBox>
            <AnswerBox>
                {questionData.isAnswered
                    ? <QuestionInfo>
                        <UserImageBox $border={`1px solid ${theme.colors.activeLightOrange}`}>
                            <Image
                                src={answerData.creatorImage}
                                onError={e => e.target.src = noUser}
                            />
                        </UserImageBox>
                        <QuestionContents>
                            <UserInfo>
                                <InlineEmphasisText>
                                    {answerData.creatorName}&nbsp;
                                    {answerData.creatorRank}
                                </InlineEmphasisText>
                                <GrayText $margin='0 0 0 10px'>
                                    {dayjs(answerData.createdAt).format('YYYY-MM-DD HH:mm')}
                                </GrayText>
                            </UserInfo>
                            <QuestionText>
                                {answerData.answer}
                            </QuestionText>

                        </QuestionContents>
                    </QuestionInfo>
                    : '등록된 답변이 없습니다.'
                }
            </AnswerBox>
        </DetailContents>
    )
}

export default QuestionDetailContents;
