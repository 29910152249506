import React, {useCallback, useEffect, useRef, useState} from "react";
import ModalBackground from "../../layout/ModalBackground";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_PATIENT_DETAIL, SEE_PATIENT_MEMO} from "../../../graphql/Patient/query";
import {
    Contents,
    DefaultText,
    EmphasisText, HistoryInfo, HistoryTableBody, HistoryTableRow, HistoryTableCell,
    HistoryTableHeader,
    InfoRow,
    InfoTitle,
    InlineBoldText,
    Section, MoreViewIcon, HistoryMessage
} from "./patient.style";
import Loader from "../../share/Loader";
import {autoHyphen, errorMessage, handleBodyScroll} from "../../../utils/commons";
import StyledFlexBox from "../../styled/StyledFlexBox";
import dayjs from "dayjs";
import {filterChange} from "../../../pages/Appointment/appointment.lib";
import {IoChevronDownOutline} from 'react-icons/io5';
import {CREATE_PATIENT_MEMO, DELETE_PATIENT_MEMO, UPDATE_PATIENT_MEMO} from "../../../graphql/Patient/mutation";
import {toast} from "react-toastify";
import CommentList from "../Home/Notice/CommentList";

const HistoryList = ({list, index, handleMoreView, moreView}) => {

    return (
        <HistoryTableRow>
            <HistoryInfo>
                <HistoryTableCell>
                    {dayjs(list?.re_desireDate).format('YYYY-MM-DD')}
                </HistoryTableCell>
                <HistoryTableCell>
                    {list?.re_desireTime || '-'}
                </HistoryTableCell>
                <HistoryTableCell>
                    {list?.re_status ? filterChange(list?.re_status) : '-'}
                </HistoryTableCell>
                <HistoryTableCell $flex={1}>
                    <MoreViewIcon $active={index === moreView} onClick={() => handleMoreView(index)}>
                        <IoChevronDownOutline fontSize={22}/>
                    </MoreViewIcon>
                </HistoryTableCell>
            </HistoryInfo>
            <HistoryMessage $active={index === moreView}>
                {list?.re_oneLineMem || ''}
            </HistoryMessage>
        </HistoryTableRow>
    )
}

const PatientModal = ({patientModal, setPatientModal, selectedId, setSelectedId}) => {
    const inputRef = useRef();
    const [moreView, setMoreView] = useState(null);
    const [memoList, setMemoList] = useState([]);
    const [input, setInput] = useState('');
    const [memoId, setMemoId] = useState(null);

    // 환자 상세 정보
    const {data, loading} = useQuery(SEE_PATIENT_DETAIL, {
        variables: {
            patiId: selectedId
        },
        fetchPolicy: 'network-only'
    });
    // 메모 리스트
    const {data: memoData, loading: memoLoading, refetch: memoRefetch} = useQuery(SEE_PATIENT_MEMO, {
        variables: {
            patiId: selectedId
        },
        fetchPolicy: 'network-only'
    });
    const [createPatientMemo] = useMutation(CREATE_PATIENT_MEMO); // 메모 등록
    const [updatePatientMemo] = useMutation(UPDATE_PATIENT_MEMO); // 메모 수정
    const [deletePatientMemo] = useMutation(DELETE_PATIENT_MEMO); // 메모 삭제

    const handleModifyComment = useCallback(id => { // 댓글 수정하기 버튼 클릭
        const findMemo = memoList.find(list => list.commentId === id);
        if (findMemo) {
            setInput(findMemo.comment);
            setMemoId(id);
            inputRef?.current?.focus();
            return;
        }
        toast.error('수정할 수 없는 댓글입니다.');
    }, [memoList, inputRef]);

    const handleCreateMemo = useCallback(async () => { // 메모 등록
        if (input === '') {
            toast.error('메모를 입력해주세요.');
            return;
        }

        try {
            const {data} = await createPatientMemo({
                variables: {
                    patiId: selectedId,
                    text: input
                }
            });

            if (data.createPatientMemo) {
                toast.info('메모를 등록했습니다.');
                await memoRefetch();
                setInput('');
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [input]);

    const handleUpdateMemo = useCallback(async () => { // 메모 수정
        if (input === '') {
            toast.error('메모를 입력해주세요.');
            return;
        }

        try {
            const {data} = await updatePatientMemo({
                variables: {
                    prmId: memoId,
                    text: input
                }
            });

            if (data.updatePatientMemo) {
                toast.info('메모를 수정했습니다.');
                await memoRefetch();
                setInput('');
                setMemoId(null);
            }
        } catch (e) {
            errorMessage(e.message);
        } finally {
            setMemoId(null);
        }
    }, [input, memoId]);

    const handleDeleteComment = useCallback(async id => { // 메모 삭제
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deletePatientMemo({
                variables: {
                    prmId: id
                }
            });

            if (data.deletePatientMemo) {
                toast.info('댓글을 삭제했습니다.');
                await memoRefetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const onInputKeyPress = useCallback(async e => { // input keypress
        if (e.key === 'Enter') {
            if (input === '') {
                toast.error('댓글을 입력해주세요.');
                return;
            }

            if (memoId) {
                await handleUpdateMemo();
            } else {
                await handleCreateMemo();
            }
        }
    }, [memoId, input]);

    const handleMoreView = useCallback(index => { // 더보기 클릭
        if (moreView === index) {
            setMoreView(null);
            return;
        }
        setMoreView(index);
    }, [moreView]);

    const handleClose = useCallback(() => { // 모달 닫기
        setSelectedId(null);
        setPatientModal(false);
        handleBodyScroll('initial');
    }, []);

    useEffect(() => {
        if (!memoLoading && memoData) {
            const tmpMemoList = memoData?.seePatientMemo.map(memo => {
                if (!memo) return;

                return {
                    commentId: memo.prm_id || 0,
                    creatorId: memo.prm_creatorId || null,
                    createDate: memo.prm_createdAt || new Date(),
                    creator: memo.prm_creatorName || '',
                    creatorRank: memo.prm_creatorRank || '',
                    creatorImage: memo.prm_creatorImg || '',
                    creatorName: memo.prm_creatorName || '',
                    comment: memo.prm_text || ''
                }
            });

            setMemoList(tmpMemoList);
        }
    }, [memoLoading, memoData]);

    return (
        <ModalBackground
            title='환자 상세 정보'
            width={1040}
            height={782}
            visible={patientModal}
            onClose={handleClose}>
            <Contents>
                {loading
                    ? <Loader/>
                    : (
                        <>
                            <Section>
                                <EmphasisText $margin='0 0 30px'>환자 개인 정보</EmphasisText>
                                <InfoRow>
                                    <InfoTitle>이름</InfoTitle>
                                    <DefaultText>
                                        {data?.seePatientDetail?.pati_name || ''}
                                    </DefaultText>
                                </InfoRow>
                                <InfoRow>
                                    <InfoTitle>연락처</InfoTitle>
                                    <DefaultText>
                                        {autoHyphen(data?.seePatientDetail?.pati_cellphone) || ''}
                                    </DefaultText>
                                </InfoRow>
                                <InfoRow $margin='0 0 40px'>
                                    <InfoTitle>생년월일</InfoTitle>
                                    <DefaultText>
                                        {data?.seePatientDetail?.pati_rrn || ''}
                                    </DefaultText>
                                </InfoRow>
                                <StyledFlexBox justifyContent='space-between'>
                                    <InlineBoldText>예약 기록</InlineBoldText>
                                    <InlineBoldText>총 {data?.seePatientDetail?.resList?.length || 0} 건</InlineBoldText>
                                </StyledFlexBox>

                                <HistoryTableHeader>
                                    <HistoryTableCell>예약 희망일</HistoryTableCell>
                                    <HistoryTableCell>희망시간</HistoryTableCell>
                                    <HistoryTableCell>최종상태</HistoryTableCell>
                                    <HistoryTableCell $flex={1}>더보기</HistoryTableCell>
                                </HistoryTableHeader>
                                <HistoryTableBody>
                                    {data?.seePatientDetail?.resList?.map((list, index) => (
                                        <HistoryList
                                            key={`${index}-history-list`}
                                            list={list}
                                            index={index}
                                            moreView={moreView}
                                            handleMoreView={handleMoreView}
                                        />
                                    ))}
                                </HistoryTableBody>
                            </Section>

                            <Section>
                                <DefaultText $margin='0 40px 10px'>메모</DefaultText>
                                <CommentList
                                    NO_COUNT
                                    minHeight='500px'
                                    listHeight='540px'
                                    commentInputWidth='calc(100% - 60px)'
                                    input={input}
                                    setInput={setInput}
                                    onInputKeyPress={onInputKeyPress}
                                    handleSaveComment={memoId ? handleUpdateMemo : handleCreateMemo}
                                    commentList={memoList}
                                    handleDeleteComment={handleDeleteComment}
                                    handleModifyComment={handleModifyComment}
                                    ref={{inputRef}}
                                    placeholder='메모를 입력해주세요.'
                                />
                            </Section>
                        </>
                    )
                }
            </Contents>
        </ModalBackground>
    )
}

export default PatientModal;
