import React from 'react';
import styled, {css} from 'styled-components';
import theme from "../../styles/theme";

const Wrapper = styled.div`
  margin: ${({$margin}) => ($margin ? $margin : 0)};
`;
const Box = styled.div`
  position: relative;
  margin-left: 26px;
`;
const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${({$fontColor}) => ($fontColor ? $fontColor : theme.colors.fontDarkGrayColor)};
  font-family: ${({$fontFamily}) => $fontFamily};
  padding-bottom: 2px;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
  display: inline-block;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    top: 5px;
    left: -24px;
    vertical-align: top;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 1;
    width: 10px;
    height: 4px;
    border: 2px solid ${theme.colors.lightGrayBgColor};
    border-top-style: none;
    border-right-style: none;
    position: absolute;
  }

  &:after {
    content: '';
    border: 2px solid ${theme.colors.lightGrayBorder};
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -20px;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    left: -8px;
    top: -1px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: ${theme.colors.lightGrayBgColor};
  }

  ${({checked}) => checked && css`
    &:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-color: ${theme.colors.whiteColor};
    }

    &:after {
      border-color: ${theme.colors.activeBlue};
      background-color: ${theme.colors.activeBlue};
    }
  `}
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: auto;
  z-index: -1;
`;

const StyledCheckbox = ({
                            label,
                            fontColor,
                            fontFamily = 'AppleSDGothicNeoMedium',
                            margin,
                            id,
                            name = 'checkbox',
                            checked,
                            onChange
                        }) => {
    return (
        <Wrapper $margin={margin}>
            <Box>
                <Input
                    id={id}
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    checked={checked}
                />
                <Label
                    htmlFor={id}
                    $fontColor={fontColor}
                    $fontFamily={fontFamily}
                    name={name}
                    checked={checked}>
                    {label}
                </Label>
            </Box>
        </Wrapper>
    );
};

export default React.memo(StyledCheckbox);
