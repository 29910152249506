import React, {forwardRef} from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import {IoArrowUpCircleSharp} from "react-icons/io5";

const InputBox = styled.div`
  width: ${({ $width }) => $width};
  height: 50px;
  position: relative;
  margin: 0 auto;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 48px 0 12px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.lightGrayBorder};
  background-color: ${theme.colors.ultraLightGrayBorder};

  &:focus {
    background-color: ${theme.colors.whiteColor};
  }
`;
const SendButton = styled.button`
  position: absolute;
  right: 12px;
  top: 30%;
  background-color: inherit;
`;

const CommentInput = forwardRef((
    {
        width = 'calc(100% - 80px)',
        input,
        setInput,
        onKeyPress,
        handleSaveComment,
        disabled,
        placeholder
    },
    inputRef
) => {
    return (
        <InputBox $width={width}>
            <Input
                ref={inputRef}
                value={input}
                onChange={e => setInput(e.target.value)}
                onKeyPress={onKeyPress}
                // onBlur={() => setInput('')}
                maxLength={200}
                disabled={disabled}
                placeholder={placeholder}
            />
            <SendButton onClick={handleSaveComment}>
                <IoArrowUpCircleSharp fontSize={22} color={theme.colors.grayBorder}/>
            </SendButton>
        </InputBox>
    )
});

export default CommentInput;
