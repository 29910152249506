import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";

export const Wrapper = styled.div`
  padding: 20px 40px;
`;
export const CountTextBox = styled.div`
  display: flex;
`;
export const InlineEmphasisText = styled.span`
  font-family: 'AppleSDGothicNeoBold';
`;
export const DayOffTableBody = styled.div`
  
`;
// 리스트
export const TableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const IconBox = styled.div`
  width: 42px;
  height: 42px;
  color: ${theme.colors.activeBlue};
  font-family: 'AppleSDGothicNeoBold';
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
export const TableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;

// 쉬는날 등록 모달
export const DayOffModalContents = styled.div`
  display: flex;
`;
export const Section = styled.div`
  position: relative;
  flex: 1;
`;
export const Line = styled.div`
  margin: 0 40px;

  &:after {
    content: '';
    width: 1px;
    height: calc(100% + 96px);
    top: -94px;
    position: absolute;
    background-color: ${theme.colors.ultraLightGrayBorder};
  };
`;

// 월별 병원 쉬는날
export const ScheduleBox = styled.div`
  width: 100%;
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  text-align: left;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  background-color: ${({ $bgColor }) => $bgColor ? $bgColor : theme.colors.whiteColor};
`;
