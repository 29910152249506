import {getHourMinute} from "../utils/commons";
import {toast} from "react-toastify";

export const operationTimeValidator = (
    startHour,
    startMin,
    endHour,
    endMin,
    lunchStartHour,
    lunchStartMin,
    lunchEndHour,
    lunchEndMin,
    isLunchTime
) => {
    let validate = false;

    let operationStartTime = getHourMinute(
        parseInt(startHour.replace('시', ''), 10),
        parseInt(startMin.replace('분', ''), 10)
    );

    let operationEndTime = getHourMinute(
        parseInt(endHour.replace('시', ''), 10),
        parseInt(endMin.replace('분', ''), 10)
    );

    let lunchStartTime = getHourMinute(
        parseInt(lunchStartHour.replace('시', ''), 10),
        parseInt(lunchStartMin.replace('분', ''), 10)
    );

    let lunchEndTime = getHourMinute(
        parseInt(lunchEndHour.replace('시', ''), 10),
        parseInt(lunchEndMin.replace('분', ''), 10)
    );

    if (operationStartTime >= operationEndTime) {
        toast.error('운영 종료시간은 시작시간보다 이후로 설정해주세요.');
        validate = true;
    } else if (isLunchTime === 'true' && (lunchStartTime < operationStartTime || lunchEndTime > operationEndTime)) {
        toast.error('점심시간을 운영시간 사이로 설정해주세요.');
        validate = true;
    } else if (isLunchTime === 'true' && (lunchStartTime >= lunchEndTime)) {
        toast.error('점심 종료시간은 시작시간보다 이후로 설정해주세요.');
        validate = true;
    }

    return validate;
}
