import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import dayjs from "dayjs";
import {IoCheckmarkCircleOutline, IoTimeOutline, IoBanOutline} from 'react-icons/io5';
import {ScheduleContext} from "../schedule";
import ScheduleModal from "../../../components/feature/Schedule/ScheduleModal";
import Loader from "../../../components/share/Loader";
import {SEE_SPECIAL_SCHEDULE} from "../../../graphql/Schedule/query";
import {RegularText} from "../../../components/styled/StyledText";
import {
    SpecialScheduleSection,
    SpecialScheduleTable,
    SpecialScheduleTableRow as TableRow,
    SpecialScheduleTableCell as TableCell,
    StatusIcon,
    EmphasisText,
    DefaultText
} from "../schedule.style";
import theme from "../../../styles/theme";
import StyledIconSelect from "../../../components/styled/StyledIconSelect";
import StyledIconButton from "../../../components/styled/StyledIconButton";
import {statusChange} from "../schedule.lib";
import {errorMessage, handleBodyScroll} from "../../../utils/commons";
import {DELETE_SPECIAL_SCHEDULE, UPDATE_SPECIAL_SCHEDULE_STATUS} from "../../../graphql/Schedule/mutation";
import {toast} from "react-toastify";
import ScheduleDetailModal from "../../../components/feature/Schedule/ScheduleDetailModal";
import {SEE_BY_MONTH_SCHEDULE} from "../../../graphql/Home/query";

// schedule list component
const SpecialScheduleList = ({list, refetch, setScheduleModal, setSelectedId, setScheduleDetailModal, handleDeleteSchedule}) => {
    const selectCellRef = useRef(null);
    const buttonCellRef = useRef(null);

    const [updateSpecialScheduleStatus] = useMutation(UPDATE_SPECIAL_SCHEDULE_STATUS);

    const handleOpenDetail = useCallback(e => { // 툭별 일정 상세보기
        if (!selectCellRef?.current?.contains(e.target) && !buttonCellRef?.current?.contains(e.target)) {
            setScheduleDetailModal(true);
            setSelectedId(list?.ss_id);
            handleBodyScroll('hidden');
        }
    }, [list, setScheduleDetailModal]);

    const handleUpdateSchedule = useCallback(() => { // 특별 일정 수정
        setScheduleModal(true);
        setSelectedId(list?.ss_id);
    }, [list, selectCellRef, buttonCellRef]);

    const handleReject = useCallback(async status => {
        if (status === '승인거절' && !window.confirm('정말 거절하시겠습니까?')) return;

        try {
            const {data} = await updateSpecialScheduleStatus({
                variables: {
                    ssId: list.ss_id,
                    status: statusChange(status)
                }
            });

            if (data.updateSpecialScheduleStatus) {
                await refetch();
                toast.info(`선택한 일정을 ${status} 하였습니다.`);
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, [list]);

    return (
        <TableRow onClick={handleOpenDetail}>
            <TableCell
                $minWidth={42}
                $width={2}
                $marginRight={16}>
                <StatusIcon>
                    {list?.ss_status === 'notSign'
                        ? <IoTimeOutline fontSize={22} color={theme.colors.activeOrange}/>
                        : list?.ss_status === 'reject'
                            ? <IoBanOutline fontSize={22} color={theme.colors.activeRed}/>
                            : <IoCheckmarkCircleOutline fontSize={22} color={theme.colors.activeBlue}/>
                    }
                </StatusIcon>
            </TableCell>
            <TableCell
                $column
                $minWidth={120}
                $width={10}>
                <EmphasisText>
                    {list?.ss_doctorRoomName || ''}
                </EmphasisText>
                <DefaultText>
                    {list?.ss_subDoctorUsed ? '대체 의사 진행' : '휴무 진행'}
                </DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={220}
                $width={18}>
                <EmphasisText>조정일</EmphasisText>
                <DefaultText>
                    {dayjs(new Date(parseInt(list?.ss_startDate, 10))).format('YYYY-MM-DD')}
                    &nbsp;~&nbsp;
                    {dayjs(new Date(parseInt(list?.ss_endDate, 10))).format('YYYY-MM-DD')}
                </DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={120}
                $width={12}>
                <EmphasisText>시간 단위 조정</EmphasisText>
                <DefaultText>
                    {list?.ss_startTime || ''}
                    &nbsp;~&nbsp;
                    {list?.ss_endTime || ''}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={350}
                $width={32}>
                <DefaultText>
                    {list?.ss_memo || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                ref={selectCellRef}
                $minWidth={270}
                $width={24}>
                <StyledIconSelect
                    width={128}
                    value={statusChange(list?.ss_status)}
                    options={['승인완료', '반려']}
                    disabled={list?.ss_status !== 'notSign'}
                    handleChangeStatus={handleReject}
                />
            </TableCell>
            <TableCell
                ref={buttonCellRef}
                $minWidth={68}
                $width={2}>
                <StyledIconButton
                    UPDATE
                    topRightRadius={0}
                    bottomRightRadius={0}
                    margin='0 0 0 -1px'
                    onClick={handleUpdateSchedule}
                />
                <StyledIconButton
                    DELETE
                    topLeftRadius={0}
                    bottomLeftRadius={0}
                    margin='0 0 0 -1px'
                    onClick={() => handleDeleteSchedule(list?.ss_id)}
                />
            </TableCell>
        </TableRow>
    );
}

const SpecialSchedule = () => {
    const {date, sortType, scheduleModal, setScheduleModal, selectedId, setSelectedId, searchTerm} = useContext(ScheduleContext);
    const [scheduleDetailModal, setScheduleDetailModal] = useState(false);
    const [offDateList, setOffDateList] = useState([]);

    const {data: offDayData, loading: offDayLoading} = useQuery(SEE_BY_MONTH_SCHEDULE, {
        variables: {
            year: dayjs(date).get('year'),
            month: dayjs(date).get('month') + 1,
            searchTerm: '',
            orderBy: sortType
        }
    });
    const {data, loading, refetch} = useQuery(SEE_SPECIAL_SCHEDULE, {
        variables: {
            year: dayjs(date).get('year'),
            month: dayjs(date).get('month') + 1,
            searchTerm: searchTerm,
            orderBy: sortType
        }
    });
    const [deleteSpecialSchedule] = useMutation(DELETE_SPECIAL_SCHEDULE);

    const handleDeleteSchedule = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const { data } = await deleteSpecialSchedule({
                variables: {
                    ssId: id
                }
            });

            if (data.deleteSpecialSchedule) {
                toast.info('특별 일정을 삭제했습니다.');
                await refetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    useEffect(() => {
        if (!offDayLoading && offDayData) {
            const filteredData = offDayData?.seeByMonthSchedule?.[0]?.byDateInfo?.filter(list => list.offInfo === 'off');
            const offDateList = filteredData.map(list => new Date(`${dayjs(date).get('year')}-${dayjs(date).get('month') + 1}-${list.date}`));
            setOffDateList(offDateList);
        }
    }, [offDayLoading, offDayData, date]);

    return (
        <SpecialScheduleSection>
            <RegularText>휴무 일정</RegularText>
            <SpecialScheduleTable>
                {loading
                    ? <Loader/>
                    : data?.seeSpecialSchedule?.map(list => (
                        <SpecialScheduleList
                            key={`${list.ss_id}-special-list`}
                            list={list}
                            refetch={refetch}
                            setScheduleModal={setScheduleModal}
                            setSelectedId={setSelectedId}
                            setScheduleDetailModal={setScheduleDetailModal}
                            handleDeleteSchedule={handleDeleteSchedule}
                        />
                    ))
                }
            </SpecialScheduleTable>

            <ScheduleModal // 특별 일정 추가 모달
                title='진료실 일정 변경'
                scheduleModal={scheduleModal}
                setScheduleModal={setScheduleModal}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                refetch={refetch}
                offDateList={offDateList}
            />

            <ScheduleDetailModal
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                scheduleDetailModal={scheduleDetailModal}
                setScheduleDetailModal={setScheduleDetailModal}
            />
        </SpecialScheduleSection>
    )
}

export default SpecialSchedule;
