import { gql } from '@apollo/client';

export const CREATE_SPECIAL_SCHEDULE = gql`
    mutation CreateSpecialSchedule(
        $drId: Int!
        $type: specialScheduleType
        $startDate: String
        $endDate: String
        $subDoctorUsed: Boolean
        $startTime: String
        $endTime: String
        $memo: String
        $attached: [Upload!]
    ) {
        createSpecialSchedule(
            dr_id: $drId
            type: $type
            startDate: $startDate
            endDate: $endDate
            subDoctorUsed: $subDoctorUsed
            startTime: $startTime
            endTime: $endTime
            memo: $memo
            attached: $attached
        )
    }
`;

export const UPDATE_SPECIAL_SCHEDULE = gql`
    mutation UpdateSpecialSchedule(
        $ssId: Int!
        $drId: Int
        $type: specialScheduleType
        $startDate: String
        $endDate: String
        $startTime: String
        $subDoctorUsed: Boolean
        $endTime: String
        $memo: String
        $attached: [Upload!]
        $deleteAttachedIds: [Int]
    ) {
        updateSpecialSchedule(
            ss_id: $ssId
            dr_id: $drId
            type: $type
            startDate: $startDate
            endDate: $endDate
            startTime: $startTime
            subDoctorUsed: $subDoctorUsed
            endTime: $endTime
            memo: $memo
            attached: $attached
            deleteAttachedIds: $deleteAttachedIds
        )
    }
`;

export const DELETE_SPECIAL_SCHEDULE = gql`
    mutation DeleteSpecialSchedule($ssId: Int!) {
        deleteSpecialSchedule(ss_id: $ssId)
    }
`;

export const UPDATE_SPECIAL_SCHEDULE_STATUS = gql`
    mutation UpdateSpecialScheduleStatus($ssId: Int!, $status: scheduleConfirm) {
        updateSpecialScheduleStatus(ss_id: $ssId, status: $status)
    }
`;

export const CREATE_SPECIAL_SCHEDULE_COMMENT = gql`
    mutation CreateSpecialScheduleComment($ssId: Int!, $sshText: String) {
        createSpecialScheduleComment(ss_id: $ssId, ssh_text: $sshText)
    }
`;

export const DELETE_SPECIAL_SCHEDULE_COMMENT = gql`
    mutation DeleteSpecialScheduleComment($sshId: Int!) {
        deleteSpecialScheduleComment(ssh_id: $sshId)
    }
`;
