import React from 'react';
import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {DefaultText, EmphasisText} from "../Manager/manager.style";
import dayjs from "dayjs";

const HistoryTableRow = ({ data }) => {
    return (
        <TableRow>
            <TableCell
                $minWidth={170}
                $width={10}>
                <DefaultText>
                    {data?.createdAt ? dayjs(data?.createdAt).format('YYYY-MM-DD HH:mm') : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={100}
                $width={10}>
                <EmphasisText>
                    {data?.userName || '-'}
                </EmphasisText>
                <DefaultText>
                    {data?.useRank || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={200}
                $width={12}>
                <DefaultText>
                    {data?.email || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={120}
                $width={10}>
                <DefaultText>
                    {data?.ip || '172.168.0.12'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={120}
                $width={8}>
                <DefaultText>
                    {data?.os || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={120}
                $width={8}>
                <DefaultText>
                    {data?.browser || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={100}
                $width={42}>
                <DefaultText>
                    {data?.status ? '로그인' : '로그아웃'}
                </DefaultText>
            </TableCell>
        </TableRow>
    );
}

export default HistoryTableRow;

// 사용자 목록 테이블 리스트
export const TableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const TableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 30}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
