import styled from "styled-components";
import {RegularText} from "../../components/styled/StyledText";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  height: calc(100% - 160px);
  padding: 20px 40px;
`;
export const Title = styled(RegularText)`
  color: ${theme.colors.grayBorder};
`;
export const ProfileBox = styled.div`
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  margin: 20px 0 30px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;

export const UserInfoBox = styled.div`
  margin-left: 32px;
`;
export const BlackText = styled(RegularText)`
  width: ${({ $width }) => $width ? $width : 128}px;
  margin: ${({$margin}) => $margin};
`;
export const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
`;
export const InfoTitleHead = styled.div`
  width: 36%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
