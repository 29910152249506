import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";

// 일대일 문의하기 모달
export const BlueText = styled(RegularText)`
  color: ${theme.colors.activeBlue};
  margin: 12px 0 30px;
`;
export const FileList = styled.div`
  min-height: 88px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
`;

// 고객센터 (자주 묻는 질문)
export const FaqTableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
  
  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const FaqTableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const GrayText = styled.div`
  color: ${theme.colors.fontLightGrayColor};
  margin: ${({$margin}) => $margin ? $margin : '0'};
`;
export const CountBox = styled.div`
  min-width: 66px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// 문의하기
export const QnaHeader = styled.div`
  padding: 30px 40px 20px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const QnaTableList = styled.div`
  min-height: calc(100% - 446px);
  padding: 0 40px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const QnaTableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const QnaTableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const ImageBox = styled.div`
  width: 42px;
  height: 42px;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 0}px;
  border-radius: 4px;
  border: ${({ $border }) => $border ? $border : `1px solid ${theme.colors.ultraLightGrayBorder}`};
  overflow: hidden;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  color: ${({ $fontColor }) => $fontColor ? $fontColor : theme.colors.blackColor};
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;

// FAQ, QNA 상세보기 모달
export const QnaContentsBox = styled.div`
  height: calc(100% - 100px);
`;
export const QnaSection = styled.section`
  height: 50%;
  max-height: calc(50% - 30px);
  flex: 1;
  padding: 0 40px 40px;
  overflow-y: scroll;
`;
export const DetailContents = styled.div`
  padding: 0 40px 40px;
`;
export const QuestionBox = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const AnswerBox = styled.div`
  margin-top: 30px;
`;
export const QuestionInfo = styled.div`
  display: flex;
`;
export const UserImageBox = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 16px 0 0;
  border-radius: 8px;
  border: ${({ $border }) => $border ? $border : `1px solid ${theme.colors.ultraLightGrayBorder}`};
  overflow: hidden;
`;
export const UserInfo = styled.div`
  display: flex;
`;
export const QuestionTitle = styled.div`
  font-family: 'AppleSDGothicNeoBold';
  line-height: 1.2;
  white-space: pre-wrap;
  word-break: break-all;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const QuestionText = styled.div`
  line-height: 1.2;
  white-space: pre-wrap;
  word-break: break-all;
  margin-top: 8px;
`;
export const InlineEmphasisText = styled.span`
  font-family: 'AppleSDGothicNeoBold';
`;
export const QuestionContents = styled.div`
  width: calc(100% - 48px);
`;
export const PreviewList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
`;

// 공지사항
export const NoticeTableRow = styled.div`
  padding: 20px 40px;
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const NoticeInfoBox = styled.div`
  width: calc(100% - 60px);
`;
export const NoticeImageBox = styled.div`
  max-width: 400px;
  display: flex;
  margin-top: 30px;
`;
export const NoticeImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  
  & + & {
    margin-left: 20px;
  }
`;
export const Line = styled.div`
  width: calc(100% - 80px);
  height: 1px;
  margin: 30px auto;
  background-color: ${theme.colors.ultraLightGrayBorder};
`;
