import React, {useCallback, useContext, useMemo} from "react";
import {getCalendarColumns, useCalendar} from "../../../hooks/useCalendar";
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import dayjs from "dayjs";
import {IoAddOutline} from 'react-icons/io5';
import theme from "../../../styles/theme";
import Loader from "../../share/Loader";
import {
    CalendarTable,
    CalendarTableRow,
    CalendarTableData,
    CalendarDataHead,
    CalendarDataBody,
    DateText,
    CalendarWrapper,
    CalendarTableHeader,
    AddIcon,
    ScheduleBox,
    PlatformIcon,
    NumberBox,
    CountText,
    BrieflyInfoText,
    TimeText
} from "./appointment.style";
import {getPlatformImg, getStatusBgColor} from "../../../pages/Appointment/appointment.lib";

const AppointmentCalendarMonth = ({
                                      monthLoading,
                                      monthData,
                                      setCalendarSelectedDate,
                                      setCalendarDetailModal
                                  }) => {
    const {date} = useContext(AppointmentContext);
    const selectedDate = useMemo(() => dayjs(date), [date]);
    const {getDay} = useCalendar(selectedDate);
    const columns = getCalendarColumns(selectedDate);

    const handleClickDetail = useCallback(date => {
        setCalendarSelectedDate(date);
        setCalendarDetailModal(true);
    }, []);

    if (monthLoading) return <Loader/>;

    return (
        <CalendarWrapper>
            <CalendarTable>
                <thead>
                <tr>
                    {getDay.map((day, index) => (
                        <CalendarTableHeader key={`${index}-${day}`}>{day}</CalendarTableHeader>
                    ))}
                </tr>
                </thead>
                <tbody>
                {columns?.map((column, rowIndex) => (
                    <CalendarTableRow key={rowIndex}>
                        {column.map((date, columnIndex) => {
                            const schedule = monthData.find(item => { // 데이터랑 달력의 날짜가 같은 스케줄 찾기
                                if (selectedDate.get('month') === date.get('month')) { // 이번 달 스케줄
                                    return item.date === date.get('date');
                                }
                            }); // 각 날짜 일정
                            const fontColor = date.get('month') + 1 === selectedDate.get('month') + 1
                                ? theme.colors.blackColor
                                : theme.colors.fontGrayColor;
                            const bgColor = (getDay[columnIndex] === '일' || getDay[columnIndex] === '토')
                                ? theme.colors.lightWhiteBgColor
                                : theme.colors.whiteColor;

                            return (
                                <CalendarTableData
                                    key={columnIndex}
                                    $bgColor={bgColor}
                                    onClick={() => handleClickDetail(date)}>
                                    <CalendarDataHead>
                                        <AddIcon>
                                            <IoAddOutline/>
                                        </AddIcon>
                                        <NumberBox>
                                            {schedule?.totalResCount > 0 &&
                                            <CountText>총 {schedule?.totalResCount} 명</CountText>}
                                            <DateText
                                                $fontColor={fontColor}
                                                $isToday={date.isToday()}>
                                                {dayjs(date).get('date')}
                                            </DateText>
                                        </NumberBox>
                                    </CalendarDataHead>
                                    <CalendarDataBody>
                                        {schedule?.byDateReservationInfo?.map(list => {
                                            return (
                                                <ScheduleBox
                                                    key={list.re_id}
                                                    $border={list.re_status === 'complete' ? `1px solid ${theme.colors.ultraLightGrayBorder}` : 'none'}
                                                    $bgColor={getStatusBgColor(list.re_status)}>
                                                    <PlatformIcon $bgImg={getPlatformImg(list.re_platform)}/>
                                                    <BrieflyInfoText>
                                                        <TimeText>
                                                            {list.re_time || ''}
                                                        </TimeText>
                                                        {list.re_patientName}&nbsp;-&nbsp;
                                                        {list.re_LCategory}
                                                    </BrieflyInfoText>
                                                </ScheduleBox>
                                            )
                                        })}
                                    </CalendarDataBody>
                                </CalendarTableData>
                            );
                        })}
                    </CalendarTableRow>
                ))}
                </tbody>
            </CalendarTable>
        </CalendarWrapper>
    );
}

export default AppointmentCalendarMonth;
