import React, {useEffect, useState} from "react";
import { PieChart, Pie, Cell } from 'recharts';
import styled from "styled-components";
import theme from "../../../styles/theme";
import dayjs from "dayjs";
import {RegularText} from "../../styled/StyledText";

const Wrapper = styled.div`
  width: 30%;
  height: 100%;
  flex: 1;
`;
const Contents = styled.div`
  display: flex;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const TitleBox = styled.div`
  font-family: 'AppleSDGothicNeoRegular';
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;
const SquareBox = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 12px;
  border-radius: 2px;
  background-color: ${({ $bgColor }) => $bgColor ? $bgColor : theme.colors.activeBlue};
`;

const COLORS = [theme.colors.activeBlue, theme.colors.activeOrange];

const CircleChart = ({ data }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (data) {
            const tmpData = [];
            tmpData.push(
                { name: '여', value: data?.womanCount },
                { name: '남', value: data?.manCount },
            );

            setChartData(tmpData);
        }
    }, [data]);

    return (
        <Wrapper>
            <RegularText>
                {data?.year || ''}년도 예약자 성비
            </RegularText>
            <Contents>
                <PieChart width={300} height={400}>
                    <Pie
                        data={chartData}
                        cx={110}
                        cy={180}
                        innerRadius={90}
                        outerRadius={110}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
                <InfoBox>
                    <TitleBox>
                        <SquareBox $bgColor={theme.colors.activeOrange} />
                        남 {data?.manCount || 0}%
                    </TitleBox>
                    <TitleBox>
                        <SquareBox />
                        여 {data?.womanCount || 0}%
                    </TitleBox>
                </InfoBox>
            </Contents>
        </Wrapper>
    )
}

export default CircleChart;
