import React, {useCallback, useContext, useRef, useState, memo} from 'react';
import RightSlideModal from "../../../layout/RightSlideModal";
import {SettingContext} from "../../../../pages/Setting/setting";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_RANK} from "../../../../graphql/Common/query";
import {CREATE_RANK, DELETE_RANK} from "../../../../graphql/Manager/mutation";
import {toast} from "react-toastify";
import {errorMessage} from "../../../../utils/commons";
import {
    CategoryList,
    JobCategoryRow,
    RankTitle,
    DeleteIcon,
    EmphasisText,
    MultiCheckBox,
    Wrapper, RankTitleBox, PermissionText
} from "./setting.modal.style";
import StyledInput from "../../../styled/StyledInput";
import Loader from "../../../share/Loader";
import {IoCloseOutline} from "react-icons/io5";
import StyledCheckbox from "../../../styled/StyledCheckbox";
import {permissionText} from "../../../../pages/Setting/setting.lib";
import JobPermissionModal from "./JobPermissionModal";

const JobList = memo(({ rank, handleDeleteRank, setSelectedRank, setJobPermissionModal }) => {
    const deleteIconRef = useRef();

    const handleUpdatePermission = useCallback((e, rank) => {
        if (!deleteIconRef?.current?.contains(e.target)) {
            setSelectedRank(rank);
            setJobPermissionModal(true);
        }
    }, [deleteIconRef]);

    return (
        <JobCategoryRow onClick={e => handleUpdatePermission(e, rank)}>
            <RankTitleBox>
                <RankTitle>{rank.rank_name || ''}</RankTitle>
                <DeleteIcon ref={deleteIconRef}>
                    <IoCloseOutline
                        fontSize={20}
                        onClick={() => handleDeleteRank(rank?.rank_id)}
                    />
                </DeleteIcon>
            </RankTitleBox>
            <PermissionText>
                {permissionText(rank?.rank_permission)}
            </PermissionText>
        </JobCategoryRow>
    );
});

const JobSettingModal = () => {
    const {siteMap, handleCloseSiteMap} = useContext(SettingContext);
    const [input, setInput] = useState('');
    const [jobPermissionModal, setJobPermissionModal] = useState(false);
    const [selectedRank, setSelectedRank] = useState(null);
    const [permission, setPermission] = useState({
        home: true,
        appointment: false,
        schedule: false,
        patient: false,
        did: false,
        insurance: false,
        service: false,
        setting: false
    });

    const {data, loading, refetch} = useQuery(SEE_RANK, {
        fetchPolicy: 'network-only'
    });
    const [createRank] = useMutation(CREATE_RANK);
    const [deleteRank] = useMutation(DELETE_RANK);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
    }, []);

    const onChange = useCallback(e => {
        const {name, checked} = e.target;

        setPermission({
            ...permission,
            [name]: checked
        });
    }, [permission]);

    const handleCreateRank = useCallback(async () => {
        if (input === '') {
            toast.info('카테고리를 입력해주세요.');
            return;
        }

        try {
            const {data} = await createRank({
                variables: {
                    rankName: input,
                    home: permission.home,
                    reservation: permission.appointment,
                    schedule: permission.schedule,
                    patient: permission.patient,
                    did: permission.did,
                    insurance: permission.insurance,
                    cs: permission.service,
                    setting: permission.setting,
                }
            });

            if (data.createRank) {
                toast.info('직책을 추가했습니다.');
                await refetch();
                setInput('');
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('직책 등록에 실패했습니다.');
                    break;
                case 'err_01':
                    toast.error('이미 등록된 직책입니다.');
                    break;
            }
        }
    }, [input, permission]);

    const handleDeleteRank = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deleteRank({
                variables: {
                    rankId: id
                }
            });

            if (data.deleteRank) {
                toast.info('직책을 삭제했습니다.');
                await refetch();
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    return (
        <>
            <JobPermissionModal
                jobPermissionModal={jobPermissionModal}
                setJobPermissionModal={setJobPermissionModal}
                selectedRank={selectedRank}
                refetch={refetch}
            />
            <RightSlideModal
                title='직책 설정'
                visible={siteMap === '직책 설정'}
                onClose={handleClose}>
                <Wrapper>
                    <EmphasisText $margin='0 0 22px'>직책 설정 정보</EmphasisText>

                    <StyledInput
                        ROW
                        CATEGORY
                        title='카테고리'
                        height={38}
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder='직책을 입력하세요.'
                        onClick={handleCreateRank}
                        onKeyPress={e => e.key === 'Enter' && handleCreateRank()}
                    />
                    <MultiCheckBox>
                        <StyledCheckbox
                            label='홈'
                            id='permission_home'
                            name='home'
                            checked={permission.home}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='예약'
                            id='permission_appointment'
                            name='appointment'
                            checked={permission.appointment}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='일정'
                            id='permission_schedule'
                            name='schedule'
                            checked={permission.schedule}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='환자'
                            id='permission_patient'
                            name='patient'
                            checked={permission.patient}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='DID'
                            id='permission_did'
                            name='did'
                            checked={permission.did}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='보험'
                            id='permission_insurance'
                            name='insurance'
                            checked={permission.insurance}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='고객센터'
                            id='permission_service'
                            name='service'
                            checked={permission.service}
                            onChange={onChange}
                        />
                        <StyledCheckbox
                            label='환경설정'
                            id='permission_setting'
                            name='setting'
                            checked={permission.setting}
                            onChange={onChange}
                        />
                    </MultiCheckBox>

                    <CategoryList>
                        {loading
                            ? <Loader/>
                            : data?.seeRank?.rankList?.map((rank, index) => (
                                <JobList
                                    key={`job-${index}`}
                                    rank={rank}
                                    handleDeleteRank={handleDeleteRank}
                                    setSelectedRank={setSelectedRank}
                                    setJobPermissionModal={setJobPermissionModal}
                                />
                            ))
                        }
                    </CategoryList>
                </Wrapper>
            </RightSlideModal>
        </>
    );
}

export default JobSettingModal;
