import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import GuideLine from "../../components/layout/PageHeader";
import StyledFlexBox from "../../components/styled/StyledFlexBox";
import {FilterBox, GrayText, TableBody, SelectBox} from "./did.styles";
import DIDTableRow from "../../components/feature/DID/DIDTableRow";
import PageNation from "../../components/layout/PageNation";
import MonitorModal from "../../components/feature/DID/MonitorModal";
import StyledIconButton from "../../components/styled/StyledIconButton";
import HelpModal from "../../components/feature/DID/HelpModal";
import MonitorSetupModal from "../../components/feature/DID/SetupModal/MonitorSetupModal";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {SEE_DID_MONITORS} from "../../graphql/DID/query";
import {filterChange} from "./did.lib";
import Loader from "../../components/share/Loader";
import {toast} from "react-toastify";
import {DELETE_DID_MONITOR, UPDATE_TOTAL_DID_SAVE_MODE} from "../../graphql/DID/mutation";
import StyledButton from "../../components/styled/StyledButton";
import theme from "../../styles/theme";
import SearchModal from "../../components/share/SearchModal";
import {useNavigate} from "react-router-dom";
import {userVar} from "../../store";

const DID = () => {
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchModal, setSearchModal] = useState(false);
    const [filter, setFilter] = useState('전체');
    const [monitorModal, setMonitorModal] = useState(false);
    const [helpModal, setHelpModal] = useState(false);
    const [setupModal, setSetupModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);

    const { data, loading, refetch } = useQuery(SEE_DID_MONITORS, {
        variables: {
            searchTerm: searchTerm,
            filter: filterChange(filter),
            take: take,
            cursor: cursor
        },
        fetchPolicy: 'network-only'
    });
    const [deleteDIDMonitor] = useMutation(DELETE_DID_MONITOR);
    const [updateTotalDIDSaveMode] = useMutation(UPDATE_TOTAL_DID_SAVE_MODE);

    const handleCreateMonitor = useCallback(() => setMonitorModal(true), []); // 모니터 생성
    const handleDeleteMonitor = useCallback(async id => { // 모니터 삭제
        if (window.confirm('정말 삭제하시겠습니까?')) {
            try {
                const { data } = await deleteDIDMonitor({
                    variables: {
                        didId: id
                    }
                });

                if (data.deleteDidMonitor) {
                    toast.info('모니터를 삭제했습니다.');
                    await refetch();
                }
            } catch (e) {
                toast.error(e.message)
            }
        }
    }, []);

    const handleSetupMonitor = useCallback(item => { // 모니터 설정 변경
        setSelectedData(item);
        setSetupModal(true);
    }, []);

    const handleAllPowerSaving = useCallback(async isAllPowerSaving => { // 모니터 모두 절전
        try {
            const { data } = await updateTotalDIDSaveMode({
                variables: {
                    saveMode: isAllPowerSaving
                }
            });

            if (data.updateTotalDidSaveMode) {
                toast.info(`모니터${isAllPowerSaving ? '를 절전모드로 변경했습니다.' : ' 절전모드를 해제했습니다.'}`);
                await refetch();
            }
        } catch (e) {
            toast.error(e.message);
        }
    }, []);

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setCursor(0);
            setPage(1);
        }
    }, [searchTerm]);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.did) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);

    return (
        <>
            <GuideLine
                DID
                title='DID 리스트'
                guideLine='DID모니터 > 모니터 리스트'
                onClick={handleCreateMonitor}>
                <FilterBox>
                    <StyledFlexBox>
                        <GrayText>정렬 :</GrayText>
                        <SelectBox
                            value={filter}
                            onChange={e => setFilter(e.target.value)}>
                            {['전체', '이미지', '영상'].map((option, index) => (
                                <option key={`${index}-${option}`} value={option}>{option}</option>
                            ))}
                        </SelectBox>
                    </StyledFlexBox>
                    <StyledFlexBox justifyContent='flex-end'>
                        <StyledButton
                            title='전체 절전'
                            width={92}
                            height={34}
                            fontColor={theme.colors.whiteColor}
                            fontFamily='AppleSDGothicNeoRegular'
                            bgColor={theme.colors.activeIndigo}
                            onClick={() => handleAllPowerSaving(true)}
                        />
                        <StyledButton
                            title='절전 해제'
                            width={92}
                            height={34}
                            fontColor={theme.colors.fontDarkGrayColor}
                            fontFamily='AppleSDGothicNeoRegular'
                            bgColor={theme.colors.whiteColor}
                            border={`1px solid ${theme.colors.lightGrayBorder}`}
                            onClick={() => handleAllPowerSaving(false)}
                            margin='0 12px'
                        />
                        <StyledIconButton
                            REFRESH
                            onClick={() => setSearchTerm('')}
                        />
                        <StyledIconButton
                            SEARCH
                            margin='0 12px'
                            onClick={() => setSearchModal(true)}
                        />
                        <StyledIconButton
                            QUESTION
                            onClick={() => setHelpModal(true)}
                        />
                    </StyledFlexBox>
                </FilterBox>
            </GuideLine>

            <TableBody>
                {loading
                    ? <Loader />
                    : data?.seeDidMonitors?.didList?.map((list, index) => (
                            <DIDTableRow
                                key={index}
                                index={index}
                                list={list}
                                handleSetupMonitor={handleSetupMonitor}
                                handleDeleteMonitor={handleDeleteMonitor}
                                refetch={refetch}
                            />
                        )
                    )}
            </TableBody>

            <PageNation
                totalLength={data?.seeDidMonitors?.totalLength}
                take={take}
                setTake={setTake}
                page={page}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <MonitorModal
                monitorModal={monitorModal}
                setMonitorModal={setMonitorModal}
                refetch={refetch}
            />

            <HelpModal
                helpModal={helpModal}
                setHelpModal={setHelpModal}
            />

            <MonitorSetupModal
                setupModal={setupModal}
                setSetupModal={setSetupModal}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                refetch={refetch}
            />

            <SearchModal // 검색하기 모달
                searchModal={searchModal}
                setSearchModal={setSearchModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
        </>
    )
}

export default DID;
