import React, {useCallback, useState} from 'react';
import {Wrapper, HistoryTableList} from "./history.style";
import StyledSelect from "../../components/styled/StyledSelect";
import PageHeader from "../../components/layout/PageHeader";
import HistoryTableRow from "../../components/feature/History/HistoryTableRow";
import PageNation from "../../components/layout/PageNation";
import {useQuery} from "@apollo/client";
import {SEE_USER_LOGIN_HISTORY} from "../../graphql/Setting/query";
import {Loader} from "../Login/login.styles";

const History = () => {
    const [sort, setSort] = useState('내림차순');
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);

    const { data, loading } = useQuery(SEE_USER_LOGIN_HISTORY, {
        variables: {
            orderBy: sort === '내림차순' ? 'desc' : 'asc',
            take,
            cursor
        }
    });

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    return (
        <Wrapper>
            <PageHeader
                GO_BACK
                title='히스토리 기록'
                guideLine='설정 > 플랫폼 관리 기록'>
                <StyledSelect
                    NO_BORDER
                    title='정렬 : '
                    value={sort}
                    onChange={e => setSort(e.target.value)}
                    options={['내림차순', '오름차순']}
                />
            </PageHeader>
            <HistoryTableList>
                {loading
                    ? <Loader />
                    : data?.seeUserLoginHistory?.userLoginHistories?.map(data => {
                    return (
                        <HistoryTableRow
                            key={`${data?.ulh_id}-setting-history`}
                            data={data}
                        />
                    );
                })}
            </HistoryTableList>
            <PageNation
                totalLength={data?.seeUserLoginHistory?.totalLength}
                take={take}
                page={page}
                setTake={setTake}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />
        </Wrapper>
    );
}

export default History;
