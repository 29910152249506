import styled, {css} from "styled-components";
import theme from "../../styles/theme";
import {BoldText, RegularText} from "../../components/styled/StyledText";

export const Wrapper = styled.div`
  height: calc(100% - 160px);
`;
export const FilterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const IconBox = styled.div`
  display: flex;
`;
export const SpecialScheduleSection = styled.section`
  padding: 20px 40px;
`;
export const SpecialScheduleTable = styled.div`
  margin: 10px 0 0;
`;
export const SpecialScheduleTableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const SpecialScheduleTableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const StatusIcon = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
