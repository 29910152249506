import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {io} from 'socket.io-client';
import {IoNotificationsOutline, IoPersonCircleOutline, IoSettingsOutline, IoLogOutOutline} from 'react-icons/io5';
import theme from "../../styles/theme";
import {BoldText, RegularText} from "../styled/StyledText";
import noUser from '../../assets/images/noUser.png';
import {AuthContext} from "../../context/AuthContext";
import MySettingModal from "../feature/Header/MySettingModal";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {userVar} from "../../store";
import {SEE_MY_NOTI_HISTORY} from "../../graphql/Common/query";
import {toast} from "react-toastify";
import {CHECK_NOTI} from "../../graphql/Common/mutation";

const Wrapper = styled.header`
  width: 100%;
  height: 90px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  background-color: ${theme.colors.whiteColor};
`;
const TextBox = styled.div`
  flex: 1;
`;
const WelcomeText = styled(BoldText)`
  font-size: 16px;
`;
const HospitalName = styled(RegularText)`
  margin-top: 6px;
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
`;
const AlarmIcon = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
  transition: .25s;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
`;
const Badge = styled.div`
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-family: 'AppleSDGothicNeoBold';
  color: ${theme.colors.whiteColor};
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 8px;
  background-color: ${theme.colors.activeBlue};
`;
const ProfileIcon = styled.div`
  width: 38px;
  height: 38px;
  position: relative;
  margin-left: 12px;
  border-radius: 8px;
  border: 2px solid ${theme.colors.activeBlue};
  cursor: pointer;
`;
const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ModalBackground = styled.div`
  width: calc(100vw - 10px);
  height: 100vh;
  z-index: 10;
  top: -27px;
  right: -42px;
  position: absolute;
`;
const ProfileHoverBox = styled.div`
  width: 252px;
  min-height: 158px;
  position: absolute;
  z-index: 10;
  right: 40px;
  top: 70px;
  border-radius: 12px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
  background-color: ${theme.colors.whiteColor};
  overflow: hidden;
`;
const TabButton = styled.div`
  min-height: 42px;
  padding: 12px 16px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
`;
const FlexBox = styled.div`
  padding: ${({$padding}) => $padding ? $padding : '2px 0'};
  display: flex;
  align-items: center;
`;
const EmailText = styled(RegularText)`
  font-size: 14px;
  color: ${theme.colors.fontLightGrayColor};
  margin-top: 6px;
`;
const NotificationBox = styled.div`
  min-width: 250px;
  max-width: 350px;
  max-height: 600px;
  position: absolute;
  z-index: 10;
  right: 40px;
  top: 70px;
  border-radius: 12px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
  background-color: ${theme.colors.whiteColor};
  overflow-y: scroll;
`;
const NotificationText = styled.div`
  white-space: pre-wrap;
  line-height: 1.2;
  word-break: break-all;
  padding: 20px 16px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;

const PlatformHeader = () => {
    const navigate = useNavigate();
    const {userLogout} = useContext(AuthContext);
    const user = useReactiveVar(userVar);
    const [profileActive, setProfileActive] = useState(false);
    const [notificationActive, setNotificationActive] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const [mySettingModal, setMySettingModal] = useState(false);
    const [socket, setSocket] = useState(null);

    const { data, refetch } = useQuery(SEE_MY_NOTI_HISTORY, {
        fetchPolicy: 'network-only'
    });
    const [checkNoti] = useMutation(CHECK_NOTI);

    const handleLogout = useCallback(() => userLogout(), []);

    const handleNotification = useCallback(async () => {
        if (data?.seeMyNotiHistory.length === 0) {
            toast.info('새로운 알림이 없습니다.');
            return;
        }

        try {
            const { data } = await checkNoti();

            if (data?.checkNoti) {
                setIsRead(true);
                setNotificationActive(!notificationActive);
            }
        } catch (e) {
            console.error(e.message);
        }
    }, [data, notificationActive]);

    useEffect(() => {
        (async () => {
            if (isRead && !notificationActive) {
                await refetch();
            }
        })();
    }, [isRead, notificationActive]);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            path: '/mediSocket',
            query: {
                hospitalEmail: user.hsp_email,
            }
        });
        setSocket(newSocket);
    }, [user]);

    useEffect(() => {
        if (!socket) return;

        socket.on('notiAlim', async response => {
            if (response !== 'null' && response) {
                const data = JSON.parse(response);

                if (data.SendStatus === "alim") {
                    await refetch();
                }
            }
        });
    }, [socket]);

    return (
        <Wrapper>
            <TextBox>
                <WelcomeText>
                    반갑습니다, {user?.user_name || ''} {user?.user_org || ''}님 👋
                </WelcomeText>
                <HospitalName>
                    {user?.user_hospitalName || ''}
                </HospitalName>
            </TextBox>
            <IconBox>
                <AlarmIcon onClick={handleNotification}>
                    <IoNotificationsOutline fontSize={24}/>
                    {data?.seeMyNotiHistory?.length > 0 && (
                        <Badge>
                            {data?.seeMyNotiHistory?.length}
                        </Badge>
                    )}

                    {notificationActive && (
                        <ModalBackground>
                            <NotificationBox>
                                {data?.seeMyNotiHistory.map(noti => {
                                    return (
                                        <NotificationText key={noti.ng_id}>
                                            {noti.text}
                                        </NotificationText>
                                    )
                                })}
                            </NotificationBox>
                        </ModalBackground>
                    )}
                </AlarmIcon>
                <ProfileIcon onClick={() => setProfileActive(!profileActive)}>
                    <ImageBox>
                        <Image src={user.user_img ?? noUser}/>
                    </ImageBox>
                    {profileActive && (
                        <ModalBackground>
                            <ProfileHoverBox>
                                <TabButton onClick={() => navigate('/profile')}>
                                    <FlexBox $padding='4px 0 0'>
                                        <IoPersonCircleOutline fontSize={20}/>
                                        <RegularText $margin='0 0 0 12px'>내 프로필</RegularText>
                                    </FlexBox>
                                    <EmailText>{user.user_email}</EmailText>
                                </TabButton>
                                <TabButton onClick={() => setMySettingModal(true)}>
                                    <FlexBox>
                                        <IoSettingsOutline/>
                                        <RegularText $margin='0 0 0 12px'>내 설정</RegularText>
                                    </FlexBox>
                                </TabButton>
                                <TabButton onClick={handleLogout}>
                                    <FlexBox $padding='0 0 4px'>
                                        <IoLogOutOutline color={theme.colors.activeRed}/>
                                        <RegularText
                                            $fontColor={theme.colors.activeRed}
                                            $margin='0 0 0 12px'>
                                            로그아웃
                                        </RegularText>
                                    </FlexBox>
                                </TabButton>
                            </ProfileHoverBox>
                        </ModalBackground>
                    )}
                </ProfileIcon>
            </IconBox>

            <MySettingModal
                mySettingModal={mySettingModal}
                setMySettingModal={setMySettingModal}
            />
        </Wrapper>
    )
}

export default React.memo(PlatformHeader);
