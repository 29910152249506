import React, {useContext, useEffect, useRef} from "react";
import OperationDaySchedule from "../../../components/share/OperationDaySchedule";
import OperationMonthSchedule from "../../../components/share/OperationMonthSchedule";
import {OperationScheduleSection} from "../../Home/Main/main.styles";
import {ScheduleContext} from "../schedule";
import ScheduleModal from "../../../components/feature/Schedule/ScheduleModal";
import {useQuery} from "@apollo/client";
import {SEE_BY_DAY_SCHEDULE, SEE_BY_MONTH_SCHEDULE} from "../../../graphql/Home/query";
import dayjs from "dayjs";

const OperationSchedule = () => {
    const { date, sortType, scheduleFilter, scheduleModal, setScheduleModal, searchTerm } = useContext(ScheduleContext);

    const { refetch } = useQuery(SEE_BY_MONTH_SCHEDULE, {
        variables: {
            year: dayjs(date).get('year'),
            month: dayjs(date).get('month') + 1,
            searchTerm: '',
            orderBy: sortType
        },
        fetchPolicy: 'network-only'
    });
    const {refetch: dayRefetch} = useQuery(SEE_BY_DAY_SCHEDULE, {
        variables: {
            searchDate: new Date(date),
            searchTerm: searchTerm,
            orderBy: sortType
        },
        fetchPolicy: 'network-only'
    });

    return (
        <>
            <OperationScheduleSection>
                {scheduleFilter === '일' && <OperationDaySchedule searchTerm={searchTerm} date={date} sortType={sortType} />}
                {scheduleFilter === '월' && <OperationMonthSchedule searchTerm={searchTerm} date={date} sortType={sortType} />}
            </OperationScheduleSection>

            <ScheduleModal // 특별 일정 추가 모달
                title='진료실 일정 변경'
                scheduleModal={scheduleModal}
                setScheduleModal={setScheduleModal}
                refetch={refetch}
                dayRefetch={dayRefetch}
            />
        </>
    )
}

export default OperationSchedule;
