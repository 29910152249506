import React, {useCallback} from 'react';
import styled, {css} from 'styled-components';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import {darken} from 'polished';
import {IoChevronBack, IoChevronForward} from 'react-icons/io5';
import theme from '../../styles/theme';
import {RegularText} from "./StyledText";
import selectBottomArrow from '../../assets/icons/selectBottomArrow.svg';
import {range} from "d3-array";
import {getYear, getMonth} from "date-fns";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import {birthFormatter} from "../../utils/commons";
import {toast} from "react-toastify";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({$margin}) => ($margin ? $margin : 0)};

  ${({$BORDER}) => $BORDER && css`
    height: 38px;
  `};

  ${({$COLUMN}) => $COLUMN && css`
    height: 74px;
    flex-direction: column;
    align-items: flex-start;

    & > div {
      border-radius: 4px;
    }

    & input {
      width: 100% !important;
    }
  `};

  .react-datepicker-wrapper {
    width: 100%;
    
    & * {
      background-color: inherit;
    }
  }
  
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${theme.colors.activeBlue};
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 20px;
  }

  .react-datepicker__header {
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__day--selected {
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: ${theme.colors.whiteColor};
  }

  input {
    width: ${({$inputWidth}) => $inputWidth}px;
    padding: 0;
    font-family: 'AppleSDGothicNeoRegular';
    cursor: pointer;
  }
`;
const DateBox = styled.div`
  height: 100%;
  padding: 0 12px;
  flex: 1;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 8px;
  background-image: url(${selectBottomArrow});
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 12px top 52%;
  
  ${({ $COLUMN }) => $COLUMN && css`
    width: 100%;
  `};
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const Title = styled(RegularText)`
  color: ${({$fontColor}) => $fontColor};
  margin-right: 10px;
`;
const ArrowBox = styled.div`
  display: flex;
  margin-left: 10px;
  cursor: pointer;
`;
const Arrow = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 4px;

  & + & {
    margin-left: 6px;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${({theme}) => darken(0.1, theme.colors.whiteHoverColor)};
  }
`;
const Header = styled.div`
  width: 100%;
  height: 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SelectBox = styled.select`
  width: 33%;
  padding: 2px 6px;
  text-align: left;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  background-image: url(${selectBottomArrow});
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: right 6px top 52%;
  //background-color: inherit;
`;


export const CustomHeader = ({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                      }) => {
    const years = range(1910, getYear(new Date()) + 1, 1);
    const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

    return (
        <Header>
            <RiArrowLeftSLine
                fontSize={22}
                onClick={() => changeYear(getYear(date) - 1)}
                disabled={prevMonthButtonDisabled}
            />

            <SelectBox
                value={getYear(date)}
                onChange={e => changeYear(e.target.value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </SelectBox>

            <SelectBox
                value={months[getMonth(date)]}
                onChange={e => changeMonth(months.indexOf(e.target.value))}
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </SelectBox>

            <RiArrowRightSLine
                fontSize={22}
                // color={theme.colors.whiteColor}
                onClick={() => changeYear(getYear(date) + 1)}
                disabled={nextMonthButtonDisabled}
            />
        </Header>
    );
}

const StyledCalendar = ({
                            SELECT,
                            COLUMN,
                            BORDER,
                            title = '기간 :',
                            titleWidth = 136,
                            inputWidth = 66,
                            fontColor = theme.colors.fontDarkGrayColor,
                            margin,
                            dateFormat = 'yyyy-MM-dd',
                            value = new Date(),
                            onChange,
                            handlePrevMonth,
                            handleNextMonth,
                            excludeDates = []
                        }) => {
    const onChangeRaw = useCallback((e) => {
        const { value } = e.target;

        if (!value) return;
        if (value?.length > 8) return;
        if (value?.length === 8) {
            const birthday = new Date(birthFormatter(value, 'YYYY-MM-DD'))

            if (isNaN(birthday.getTime()) === false) {
                onChange(birthday);
            } else {
                toast.error('입력하신 날짜는 존재하지 않습니다.');
            }
        }
    }, []);

    if (COLUMN) {
        return (
            <Wrapper
                $COLUMN={COLUMN}
                $BORDER={BORDER}
                $margin={margin}
                $inputWidth={inputWidth}>
                <ColumnTitle $fontColor={fontColor}>{title}</ColumnTitle>
                <DateBox $COLUMN={COLUMN}>
                    <DatePicker
                        dateFormat={dateFormat}
                        locale={ko}
                        selected={value}
                        onChange={onChange}
                        onChangeRow={onChangeRaw}
                        excludeDates={excludeDates}
                        renderCustomHeader={({
                                                 date,
                                                 changeYear,
                                                 changeMonth,
                                                 decreaseMonth,
                                                 increaseMonth,
                                                 prevMonthButtonDisabled,
                                                 nextMonthButtonDisabled,
                                             }) => {
                            if (SELECT) {
                                return <CustomHeader
                                    date={date}
                                    changeYear={changeYear}
                                    changeMonth={changeMonth}
                                    decreaseMonth={decreaseMonth}
                                    increaseMonth={increaseMonth}
                                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                                />
                            }
                        }}
                    />
                </DateBox>
            </Wrapper>
        );
    }


    if (BORDER) {
        return (
            <Wrapper
                $BORDER={BORDER}
                $margin={margin}
                $inputWidth={inputWidth}>
                <RowTitle $titleWidth={titleWidth} $fontColor={fontColor}>{title}</RowTitle>
                <DateBox>
                    <DatePicker
                        dateFormat={dateFormat}
                        locale={ko}
                        selected={value}
                        onChange={onChange}
                        onChangeRow={onChangeRaw}
                        excludeDates={excludeDates}
                    />
                </DateBox>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            $margin={margin}
            $inputWidth={inputWidth}>
            <Title $fontColor={fontColor}>{title}</Title>
            <DatePicker
                dateFormat={dateFormat}
                locale={ko}
                selected={value}
                onChange={onChange}
                onChangeRow={onChangeRaw}
                excludeDates={excludeDates}
            />
            <ArrowBox>
                <Arrow onClick={handlePrevMonth}>
                    <IoChevronBack color={theme.colors.fontLightGrayColor} fontSize={18}/>
                </Arrow>
                <Arrow onClick={handleNextMonth}>
                    <IoChevronForward color={theme.colors.fontLightGrayColor} fontSize={18}/>
                </Arrow>
            </ArrowBox>
        </Wrapper>
    );
};

export default React.memo(StyledCalendar);
