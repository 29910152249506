import React, {useEffect} from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import styled, {css} from "styled-components";
import theme from "../../styles/theme";
import {RegularText} from "./StyledText";
import selectBottomArrow from '../../assets/icons/selectBottomArrow.svg';
import {IoArrowForwardOutline} from 'react-icons/io5';
import {RiArrowLeftSLine, RiArrowRightSLine, RiArrowLeftDoubleFill, RiArrowRightDoubleFill} from 'react-icons/ri';
import dayjs from "dayjs";
import {getYear} from "date-fns";
import {range} from "d3-array";

const Wrapper = styled.div`
  width: ${({$width}) => $width ? `${$width}px` : 'auto'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
  
  ${({ $COLUMN }) => $COLUMN && css`
    flex-direction: column;
    align-items: flex-start;
  `};
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const DateBox = styled.div`
  flex: 1;
  height: ${({$height}) => $height}px;
  color: ${({$fontColor}) => $fontColor};
  font-size: 16px;
  font-family: 'AppleSDGothicNeoRegular';
  padding: 0 12px;
  position: relative;
  border: ${({$border}) => $border};
  border-radius: ${({$borderRadius}) => $borderRadius}px;

  ${({ $COLUMN }) => $COLUMN && css`
    width: 100%;
    flex: auto;
  `};
  
  input {
    width: 100%;
    cursor: pointer;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }

  .react-datepicker {
    width: 570px;
    padding: 20px;
    transform: translateX(10px);
    border: none;
    background-color: ${theme.colors.whiteColor};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  }

  .react-datepicker__current-month {
    margin-bottom: 10px;
  }

  .react-datepicker__navigation--previous { // 이전 달 버튼
    top: 16px;
    left: 36px;
  }

  .react-datepicker__navigation--next { // 다음 달 버튼
    top: 16px;
    right: 36px;
  }

  .react-datepicker__month-container { // 하나의 달력
    width: 50%;
  }

  // 말풍선 삼각형
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    display: none;
  }

  .react-datepicker__header { // 달력 Header
    border-bottom: none;
    background-color: ${theme.colors.whiteColor};
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__day--in-range {
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__day--selected { // 선택한 날짜
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: ${theme.colors.fontDarkGrayColor};
  }
`;
const DefaultText = styled.label`
  width: 100%;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: ${({$borderRadius}) => $borderRadius}px;
  background-color: ${theme.colors.whiteColor};
  background-image: url(${selectBottomArrow});
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 12px top 52%;
  cursor: pointer;
`;
const GrayText = styled(RegularText)`
  flex: 1;
  color: ${({$fontColor}) => $fontColor};
`;
const Header = styled.div`
  width: 100%;
  height: 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.span`
  font-size: 16px;
  font-family: 'AppleSDGothicNeoBold';
`;
const ArrowBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CustomHeader = ({
                          date,
                          changeYear,
                          decreaseMonth,
                          increaseMonth,
                          customHeaderCount,
                          monthDate
                      }) => {
    // const years = range(2020, getYear(new Date()) + 3, 1);

    return (
        <Header>
            <ButtonGroup>
                <ArrowBox>
                    {customHeaderCount === 0 &&
                    <RiArrowLeftDoubleFill fontSize={22} onClick={() => changeYear(getYear(date) - 1)}/>}
                </ArrowBox>
                <ArrowBox>
                    {customHeaderCount === 0 && <RiArrowLeftSLine fontSize={22} onClick={decreaseMonth}/>}
                </ArrowBox>
            </ButtonGroup>

            <Text>
                {monthDate.toLocaleString('ko-KR', {
                    month: 'long',
                    year: 'numeric',
                })}
            </Text>
            <ButtonGroup>
                <ArrowBox>
                    {customHeaderCount === 1 && <RiArrowRightSLine fontSize={22} onClick={increaseMonth}/>}
                </ArrowBox>
                <ArrowBox>
                    {customHeaderCount === 1 &&
                    <RiArrowRightDoubleFill fontSize={22} onClick={() => changeYear(getYear(date) + 1)}/>}
                </ArrowBox>
            </ButtonGroup>
        </Header>
    );
}

const StyledRangeCalendar = ({
                                 COLUMN,
                                 DEFAULT,
                                 width = 440,
                                 titleWidth = 136,
                                 height = 38,
                                 margin = 0,
                                 border = `1px solid ${theme.colors.lightGrayBorder}`,
                                 borderRadius = 8,
                                 title = '조정일',
                                 id,
                                 inputId,
                                 startDate,
                                 endDate,
                                 onChange,
                                 excludeDates
                             }) => {
    const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

    useEffect(() => {
        const input = document.querySelector(`#${id} .react-datepicker__input-container input`);
        input.setAttribute('id', inputId);
    }, [inputId]);

    if (COLUMN) {
        return (
            <Wrapper $COLUMN={COLUMN} id={id} $width={width} $margin={margin}>
                <ColumnTitle $titleWidth={titleWidth}>
                    {title}
                </ColumnTitle>
                <DateBox
                    $COLUMN={COLUMN}
                    $height={height}
                    $border={border}
                    $borderRadius={borderRadius}>
                    <DefaultText htmlFor={inputId} $borderRadius={borderRadius}>
                        <GrayText $fontColor={startDate ? theme.colors.blackColor : theme.colors.fontLightGrayColor}>
                            {startDate ? dayjs(startDate).format('YYYY-MM-DD') : '시작일'}
                        </GrayText>
                        <IoArrowForwardOutline style={{marginRight: 12}}/>
                        <GrayText $fontColor={endDate ? theme.colors.blackColor : theme.colors.fontLightGrayColor}>
                            {endDate ? dayjs(endDate).format('YYYY-MM-DD') : '종료일'}
                        </GrayText>
                    </DefaultText>

                    <DatePicker
                        locale={ko}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChange}
                        monthsShown={2}
                        excludeDates={excludeDates}
                        selectsRange
                        // selectsDisabledDaysInRange
                    />
                </DateBox>
            </Wrapper>
        );
    }

    if (DEFAULT) {
        return (
            <Wrapper id={id} $width={width} $margin={margin}>
                <RowTitle $titleWidth={titleWidth}>
                    {title}
                </RowTitle>
                <DateBox
                    $height={height}
                    $border={border}
                    $borderRadius={borderRadius}>

                    <DatePicker
                        renderCustomHeader={({
                                                 date,
                                                 changeYear,
                                                 changeMonth,
                                                 decreaseMonth,
                                                 increaseMonth,
                                                 customHeaderCount,
                                                 monthDate,
                                             }) => (
                            <CustomHeader
                                date={date}
                                months={months}
                                changeYear={changeYear}
                                changeMonth={changeMonth}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                                customHeaderCount={customHeaderCount}
                                monthDate={monthDate}
                            />
                        )}
                        dateFormat='yyyy-MM-dd'
                        locale={ko}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChange}
                        monthsShown={2}
                        selectsRange
                    />
                </DateBox>
            </Wrapper>
        );
    }

    return (
        <Wrapper id={id} $width={width} $margin={margin}>
            <RowTitle $titleWidth={titleWidth}>
                {title}
            </RowTitle>
            <DateBox
                $height={height}
                $border={border}
                $borderRadius={borderRadius}>
                <DefaultText htmlFor={inputId} $borderRadius={borderRadius}>
                    <GrayText $fontColor={startDate ? theme.colors.blackColor : theme.colors.fontLightGrayColor}>
                        {startDate ? dayjs(startDate).format('YYYY-MM-DD') : '시작일'}
                    </GrayText>
                    <IoArrowForwardOutline style={{marginRight: 12}}/>
                    <GrayText $fontColor={endDate ? theme.colors.blackColor : theme.colors.fontLightGrayColor}>
                        {endDate ? dayjs(endDate).format('YYYY-MM-DD') : '종료일'}
                    </GrayText>
                </DefaultText>

                <DatePicker
                    locale={ko}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                    monthsShown={2}
                    excludeDates={excludeDates}
                    selectsRange
                    // selectsDisabledDaysInRange
                />
            </DateBox>
        </Wrapper>
    );
}

export default StyledRangeCalendar;
