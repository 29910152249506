import { gql } from '@apollo/client';

export const CREATE_PATIENT_MEMO = gql`
    mutation CreatePatientMemo($patiId: Int!, $text: String) {
        createPatientMemo(pati_id: $patiId, text: $text)
    }
`;

export const UPDATE_PATIENT_MEMO = gql`
    mutation UpdatePatientMemo($prmId: Int!, $text: String) {
        updatePatientMemo(prm_id: $prmId, text: $text)
    }
`;

export const DELETE_PATIENT_MEMO = gql`
    mutation DeletePatientMemo($prmId: Int!) {
        deletePatientMemo(prm_id: $prmId)
    }
`;
