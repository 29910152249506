import { gql } from '@apollo/client';

export const SEE_INSURANCE_REQUEST_HISTORY = gql`
    query SeeInsuranceRequestHistory(
        $searchTerm: String
        $company: String
        $orderBy: String
        $take: Int
        $cursor: Int
    ) {
        seeInsuranceRequestHistory(
            searchTerm: $searchTerm
            company: $company
            orderBy: $orderBy
            take: $take
            cursor: $cursor
        ) {
            totalLength
            insuranceList {
                ih_id
                createdAt
                companyName
                reqNumber
                tobePatno
                tobeClaimDate
                status
                recentlyHistory
            }
        }
    }
`;

export const SEE_INSURANCE_HISTORY_DETAIL = gql`
    query SeeInsuranceHistoryDetail($ihId: Int!) {
        seeInsuranceHistoryDetail(ih_id: $ihId) {
            ih_createdAt
            status
            ihTextList {
                iht_id
                iht_createdAt
                iht_text
            }
        }
    }
`;
