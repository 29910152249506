import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";

// 환자 정보 목록
export const TableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const TableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const InlineEmphasisText = styled.span`
  font-family: 'AppleSDGothicNeoBold';
`;

// 환자 상세 정보 모달
export const Contents = styled.div`
  display: flex;
  height: 100%;
`;
export const Section = styled.div`
  width: 50%;
  height: 648px;
  //border: 1px solid blue;
  
  &:first-child {
    position: relative;
    
    &:after {
      content: '';
      width: 1px;
      height: calc(100% + 135px);
      position: absolute;
      top: -94px;
      right: -40px;
      background-color: ${theme.colors.ultraLightGrayBorder};
    }  
  }
  
  &:nth-child(2) {
    width: calc(50% + 80px);
    transform: translateX(40px);
    position: relative;
  }
`;
export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin: ${({$margin}) => $margin ? $margin : '0 0 30px'};
`;
export const InfoTitle = styled(RegularText)`
  width: 130px;
`;
export const InlineBoldText = styled.span`
  font-family: 'AppleSDGothicNeoBold';
`;
export const HistoryTableHeader = styled.div`
  display: flex;
  margin: 30px 0 20px;
`;
export const HistoryTableBody = styled.div`
  height: 362px;
  overflow-y: scroll;
`;
export const HistoryTableRow = styled.div`
  padding: 16px 0;
`;
export const HistoryTableCell = styled.div`
  flex: ${({ $flex }) => $flex ? $flex : 2};
`;
export const HistoryInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const HistoryMessage = styled.div`
  min-height: 0;
  height: 0;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.4;
  padding: 0 20px;
  transition: .25s;
  border-radius: 4px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  background-color: ${theme.colors.lightWhiteBgColor};
  overflow: hidden;
  opacity: 0;

  ${({ $active }) => $active && css`
    min-height: 40px;
    height: auto;
    padding: 20px;
    margin-top: 20px;
    opacity: 1;
  `};
`;
export const MoreViewIcon = styled.div`
  transition: .25s;
  text-align: center;
  cursor: pointer;
  
  ${({ $active }) => $active && css`
    transform: rotate(-180deg);
  `};
`;
