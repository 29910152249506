import React from "react";
import styled, {css} from "styled-components";
import selectBottomArrow from '../../assets/icons/selectBottomArrow.svg';
import {RegularText} from "./StyledText";
import theme from "../../styles/theme";

const Wrapper = styled.div`
  width: ${({$width}) => $width}px;
  margin: ${({ $margin }) => $margin};
  
  ${({$ROW}) => $ROW && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;
const ColumnTitle = styled(RegularText)`
  min-height: 20px;
  margin-bottom: 8px;
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
`;
const SelectBox = styled.select`
  flex: 1;
  width: 100%;
  height: ${({$height}) => $height}px;
  font-size: 16px;
  font-family: 'AppleSDGothicNeoRegular';
  color: ${({ $fontColor }) => $fontColor};
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${({ $padding }) => $padding ? $padding : 0};
  border: ${({ $border }) => $border};
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  background-image: url(${selectBottomArrow});
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 12px top 52%;
  
  ${({ disabled }) => disabled && css`
    background-color: ${theme.colors.ultraLightGrayBorder};
  `};
`;
const Asterisk = styled.span`
  color: ${theme.colors.activeRed};
  margin-left: 4px;
`;
const InlineTitle = styled(RegularText)`
  color: ${theme.colors.fontDarkGrayColor};
  display: inline-block;
  margin-right: 10px;
`;

const StyledSelect = ({
                          NO_BORDER,
                          ROW,
                          ASTERISK,
                          TAIL,
                          width = 210,
                          height = 50,
                          fontColor = theme.colors.blackColor,
                          padding = '0 25px 1px 12px',
                          titleWidth = 136,
                          margin = 0,
                          border = `1px solid ${theme.colors.lightGrayBorder}`,
                          borderRadius = 0,
                          title,
                          name,
                          value,
                          onChange,
                          options = [],
                          disabled,

                      }) => {
    if (NO_BORDER) {
        return (
            <Wrapper $ROW $width={width} $margin={margin}>
                <InlineTitle>
                    {title}
                </InlineTitle>
                <SelectBox
                    $fontColor={fontColor}
                    $padding={padding}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}>
                    {options.map((option, index) => (
                        <option value={option} key={`${index}-${option}`}>
                            {option}
                        </option>
                    ))}
                </SelectBox>
            </Wrapper>
        );
    }

    if (ROW) {
        return (
            <Wrapper $ROW={ROW} $width={width} $margin={margin}>
                <RowTitle $titleWidth={titleWidth}>
                    {title}
                </RowTitle>
                <SelectBox
                    $height={height}
                    $fontColor={fontColor}
                    $padding={padding}
                    $border={border}
                    $borderRadius={borderRadius}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}>
                    {options.map((option, index) => (
                        <option value={option} key={`${index}-${option}`}>
                            {option}
                        </option>
                    ))}
                </SelectBox>
            </Wrapper>
        );
    }

    return (
        <Wrapper $width={width} $margin={margin}>
            <ColumnTitle>
                {title}
                {ASTERISK && <Asterisk>*</Asterisk>}
            </ColumnTitle>
            <SelectBox
                $width={width}
                $height={height}
                $fontColor={fontColor}
                $padding={padding}
                $border={border}
                $borderRadius={borderRadius}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}>
                {options.map((option, index) => (
                    <option value={option} key={`${index}-${option}`}>
                        {option}
                    </option>
                ))}
            </SelectBox>
        </Wrapper>
    );
}

export default React.memo(StyledSelect);
