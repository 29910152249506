import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_HOSPITAL_NOTICE} from "../../../../graphql/Home/query";
import {NoticeContext} from "../notice";
import PageNation from '../../../../components/layout/PageNation';
import Loader from '../../../../components/share/Loader';
import NoticeList from "../../../../components/feature/Home/NoticeList";
import HospitalNoticeDetailModal from "../../../../components/feature/Home/Notice/HospitalNoticeDetailModal";
import NoticeModal from "../../../../components/feature/Home/NoticeModal";
import ConfirmModal from "../../../../components/share/ConfirmModal";
import {toast} from "react-toastify";
import {errorMessage, handleBodyScroll} from "../../../../utils/commons";
import {DELETE_HOSPITAL_NOTICE} from "../../../../graphql/Home/mutation";
import {TableBody} from '../notice.styles';

const HospitalNotice = () => {
    const {year, noticeModal, setNoticeModal, confirmModal, setConfirmModal} = useContext(NoticeContext);
    const confirmModalRef = useRef(null);

    const [commentModal, setCommentModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [take, setTake] = useState(10);
    const [page, setPage] = useState(1);
    const [cursor, setCursor] = useState(0);

    const {data, loading, refetch} = useQuery(SEE_HOSPITAL_NOTICE, {
        variables: {
            year: parseInt(year, 10),
            filter: 'desc',
            take: take,
            cursor: cursor
        },
        fetchPolicy: 'network-only'
    });
    const [deleteHospitalNotice] = useMutation(DELETE_HOSPITAL_NOTICE);

    const handleOpenConfirm = useCallback(id => {
        setConfirmModal(true);
        setSelectedId(id);
    }, []);

    const onClickPage = useCallback(page => {
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    const handleDeleteNotice = useCallback(async () => {
        try {
            const {data} = await deleteHospitalNotice({
                variables: {
                    hnId: selectedId
                }
            });

            if (data.deleteHospitalNotice) {
                toast.info('공지사항을 삭제했습니다.');
                await refetch();
                setSelectedId(null);
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [selectedId]);

    const handleOpenComment = useCallback(id => {
        setSelectedId(id);
        setCommentModal(true);
    }, [data]);

    useEffect(() => {
        if (commentModal || confirmModal || noticeModal) {
            handleBodyScroll('hidden');
        } else {
            handleBodyScroll('initial');
        }
    }, [commentModal, confirmModal, noticeModal]);

    return (
        <>
            <TableBody>
                {loading
                    ? <Loader/>
                    : data?.seeHospitalNotice?.hospitalNoticeList.map(notice => {
                        const recentlyCommentDate = notice?.hnComment[notice?.hnComment?.length - 1]?.hnc_createdAt; // 최근 댓글 생성일

                        return (
                            <NoticeList
                                DETAIL
                                HOVER
                                key={`${notice?.hn_id}-notice-list`}
                                id={notice?.hn_id}
                                title={notice?.hn_title}
                                creator={notice?.hn_creatorName}
                                creatorId={notice?.hn_creatorId}
                                creatorRank={notice?.hn_creatorRank}
                                creatorImage={notice?.hn_creatorImg}
                                createDate={notice?.hn_createdAt}
                                content={notice?.hn_text}
                                commentLength={notice?.hnComment?.length}
                                recentlyCommentDate={recentlyCommentDate}
                                handleOpenComment={handleOpenComment}
                                handleOpenConfirm={handleOpenConfirm}
                                setNoticeModal={setNoticeModal}
                                setSelectedId={setSelectedId}
                            />
                        );
                    })}
            </TableBody>
            <PageNation
                totalLength={data?.seeHospitalNotice?.totalLength}
                take={take}
                page={page}
                setTake={setTake}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <HospitalNoticeDetailModal
                commentModal={commentModal}
                setCommentModal={setCommentModal}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                noticeListRefetch={refetch}
            />

            <ConfirmModal
                title='공지사항 삭제'
                subTitle={`공지사항을 삭제할까요? 삭제된 공지사항은 댓글과\n함께 모두 기록에서 삭제되며, 복구할 수 없습니다.`}
                confirmTitle='삭제하기'
                ref={confirmModalRef}
                visible={confirmModal}
                handleConfirm={handleDeleteNotice}
                handleCancel={() => setConfirmModal(false)}
            />

            <NoticeModal
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                noticeModal={noticeModal}
                setNoticeModal={setNoticeModal}
                noticeListRefetch={refetch}
            />
        </>
    )
}

export default HospitalNotice;
