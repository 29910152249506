import { gql } from '@apollo/client';

export const SEE_PATIENTS = gql`
    query SeePatients(
        $searchTerm: String
        $searchStartDate: String
        $searchEndDate: String
        $orderBy: String
        $take: Int
        $cursor: Int
    ) {
        seePatients(
            searchTerm: $searchTerm
            searchStartDate: $searchStartDate
            searchEndDate: $searchEndDate
            orderBy: $orderBy
            take: $take
            cursor: $cursor
        ) {
            totalLength
            patientList {
                pati_id
                visitCount
                pati_name
                pati_rrn
                pati_cellphone
                recentlyVisitDate
                pati_chartNumber
                recentlyVisitMemo
            }
        }
    }
`;

export const SEE_PATIENT_DETAIL = gql`
    query SeePatientDetail($patiId: Int!) {
        seePatientDetail(pati_id: $patiId) {
            pati_name
            pati_rrn
            pati_cellphone
            pati_chartNumber
            resList {
                re_desireDate
                re_desireTime
                re_status
                re_oneLineMem
            }
        }
    }
`;

export const SEE_PATIENT_MEMO = gql`
    query SeePatientMemo($patiId: Int!) {
        seePatientMemo(pati_id: $patiId) {
            prm_id
            prm_text
            prm_createdAt
            prm_creatorId
            prm_creatorName
            prm_creatorRank
            prm_creatorImg
        }
    }
`;
