import React, {useCallback, useContext, useState} from "react";
import dayjs from "dayjs";
import {IoCheckmarkOutline, IoCloseOutline, IoChevronDownOutline} from 'react-icons/io5';
import {autoHyphen} from "../../../utils/commons";
import {filterChange} from "../../../pages/Appointment/appointment.lib";
import theme from "../../../styles/theme";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledButton from "../../styled/StyledButton";
import {
    DetailInfoSection,
    InfoRow,
    InfoTitle,
    SectionTitle,
    InfoText,
    DetailStatusBox,
    HistoryTableHeader,
    HistoryTableCell,
    HistoryTableBody,
    HistoryTableRow,
    HistoryInfoBox,
    MoreViewArrow,
    HistoryMemoBox, ButtonBox, InfoWrapper
} from "./appointment.style";
import {AppointmentContext} from "../../../pages/Appointment/appointment";

const InfoBox = ({ data, historyList = [], handleClose }) => {
    const { setSelectedId, setAppointmentModal, setDetailModal } = useContext(AppointmentContext);
    const [moreView, setMoreView] = useState(null);

    const handleMoreView = useCallback(index => { // 더보기 클릭
        if (moreView === index) {
            setMoreView(null);
            return;
        }
        setMoreView(index);
    }, [moreView]);

    const handleUpdate = useCallback(() => { // 예약 정보 변경
        setSelectedId(data?.re_id);
        setDetailModal(false);
        setAppointmentModal(true);
    }, [data]);

    if (!data) return;

    return (
        <InfoWrapper>
            <DetailInfoSection>
                <SectionTitle>예약 정보</SectionTitle>
                <InfoRow>
                    <InfoTitle>예약일시</InfoTitle>
                    <InfoText>
                        {(data?.resDate && data?.desireTime)
                            ? `${dayjs(data?.resDate).format('YYYY-MM-DD')} ${data?.time}`
                            : '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>희망일</InfoTitle>
                    <InfoText>
                        {data?.desireDate
                            ? new Date(data.desireDate).toISOString().split('T')[0]
                            : '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>희망시간</InfoTitle>
                    <InfoText>
                        {data?.desireTime || '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>이름</InfoTitle>
                    <InfoText>
                        {data?.patientName || '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>연락처</InfoTitle>
                    <InfoText>
                        {data?.patientCellphone
                            ? autoHyphen(data?.patientCellphone)
                            : '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>예약 매체</InfoTitle>
                    <InfoText>
                        {data?.platform
                            ? filterChange(data?.platform)
                            : '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>예약 상태</InfoTitle>
                    <DetailStatusBox
                        $fontColor={data?.status !== 'cancel'
                            ? theme.colors.activeGreen
                            : theme.colors.activePink}
                        $bgColor={data?.status !== 'cancel'
                            ? theme.colors.activeLightGreen
                            : theme.colors.activeLightPink}
                    >
                        {data?.status !== 'cancel'
                            ? <IoCheckmarkOutline fontSize={18} style={{marginRight: 6}}/>
                            : <IoCloseOutline fontSize={18} style={{marginRight: 6}}/>
                        }
                        {data?.status
                            ? filterChange(data?.status)
                            : '-'}
                    </DetailStatusBox>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>승인자</InfoTitle>
                    <InfoText>
                        {data?.approvalName || '-'}
                    </InfoText>
                </InfoRow>
                <InfoRow>
                    <InfoTitle>승인일시</InfoTitle>
                    <InfoText>
                        {data?.approvalDate
                            ? dayjs(data?.approvalDate).format('YYYY-MM-DD HH:mm')
                            : '-'}
                    </InfoText>
                </InfoRow>
            </DetailInfoSection>
            <DetailInfoSection>
                <StyledFlexBox justifyContent='space-between'>
                    <SectionTitle>예약 기록</SectionTitle>
                    <SectionTitle>
                        {historyList.length || 0} 건
                    </SectionTitle>
                </StyledFlexBox>
                <HistoryTableHeader>
                    <HistoryTableCell>예약 일자</HistoryTableCell>
                    <HistoryTableCell>예약 시간</HistoryTableCell>
                    <HistoryTableCell>최종상태</HistoryTableCell>
                    <HistoryTableCell $flex={1}>더보기</HistoryTableCell>
                </HistoryTableHeader>
                <HistoryTableBody>
                    {historyList.map((history, index) => (
                        <HistoryTableRow key={`${index}-history`} onClick={() => handleMoreView(index)}>
                            <HistoryInfoBox>
                                <HistoryTableCell>
                                    {history?.resDate
                                        ? dayjs(history?.resDate).format('YYYY-MM-DD')
                                        : '-'}
                                </HistoryTableCell>
                                <HistoryTableCell>
                                    {history?.resTime || '-'}
                                </HistoryTableCell>
                                <HistoryTableCell>
                                    {history?.resStatus
                                        ? filterChange(history?.resStatus)
                                        : '-'}
                                </HistoryTableCell>
                                <HistoryTableCell $flex={1}>
                                    <MoreViewArrow $active={index === moreView}>
                                        <IoChevronDownOutline />
                                    </MoreViewArrow>
                                </HistoryTableCell>
                            </HistoryInfoBox>
                            <HistoryMemoBox $active={index === moreView}>
                                {history?.oneLineMemo || '-'}
                            </HistoryMemoBox>
                        </HistoryTableRow>
                    ))}
                </HistoryTableBody>
            </DetailInfoSection>
            <ButtonBox>
                <StyledButton
                    PENCIL
                    width={214}
                    title='예약 정보 변경'
                    fontColor={theme.colors.activeOrange}
                    border={`1px solid ${theme.colors.activeOrange}`}
                    bgColor={theme.colors.whiteColor}
                    onClick={handleUpdate}
                />
                <StyledButton
                    CLOSE
                    title='닫기'
                    width={214}
                    fontColor={theme.colors.blackColor}
                    border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                    bgColor={theme.colors.whiteColor}
                    onClick={handleClose}
                />
            </ButtonBox>
        </InfoWrapper>
    )
}

export default InfoBox;
