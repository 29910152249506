import React, {useCallback, useContext, useState} from "react";
import styled from "styled-components";
import {IoCloseOutline} from 'react-icons/io5';
import {toast} from "react-toastify";
import {MonitorSetupContext} from "./MonitorSetupModal";
import {RegularText} from "../../../styled/StyledText";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledRadio from "../../../styled/StyledRadio";
import StyledInput from "../../../styled/StyledInput";
import StyledButton from "../../../styled/StyledButton";
import theme from "../../../../styles/theme";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_DID_LOW_MSG} from "../../../../graphql/DID/query";
import Loader from "../../../share/Loader";
import {CREATE_DID_LOW_MSG, DELETE_DID_LOW_MSG} from "../../../../graphql/DID/mutation";
import {screenState} from "../../../../pages/DID/did.lib";

const Wrapper = styled.div`

`;
const BlueText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.activeBlue};
  margin: 20px 0;
`;
const MessageList = styled.ul`
  height: 440px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;
const MessageItem = styled.li`
  min-height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
const Text = styled(RegularText)`
  width: 90%;
  white-space: pre-wrap;
  line-height: 1.2;
  word-break: break-all;
`;
const CloseButton = styled.div`
  color: ${theme.colors.activeOrange};
  opacity: 0;
  transition: .25s;

  ${MessageItem}:hover & {
    opacity: 1;
  }
`;

const BottomMessageSetup = () => {
    const { options: { screenState }, onChange, selectedData } = useContext(MonitorSetupContext);
    const [message, setMessage] = useState('');

    const {data: msgData, loading: msgLoading, refetch: msgRefetch} = useQuery(SEE_DID_LOW_MSG, {
        variables: {
            didId: selectedData?.did_id
        }
    });
    const [createDIDLowMsg] = useMutation(CREATE_DID_LOW_MSG);
    const [deleteDIDLowMsg] = useMutation(DELETE_DID_LOW_MSG);

    const handleAddMessage = useCallback(async () => {
        if (message === '') {
            toast.error('메시지를 입력해주세요.');
            return;
        }
        try {
            const {data} = await createDIDLowMsg({
                variables: {
                    didId: selectedData.did_id,
                    dlmNumber: msgData?.seeDidLowMsg?.didLowMsgList.length,
                    dlmText: message
                }
            });

            if (data.createDidLowMsg) {
                toast.info('메시지를 추가했습니다.');
                await msgRefetch();
                setMessage('');
            }
        } catch (e) {
            toast.error(e.message);
        }
    }, [selectedData, msgData, message]);

    const handleDeleteMessage = useCallback(async id => {
        if (window.confirm('정말 삭제하시겠습니까?')) {
            try {
                const {data} = await deleteDIDLowMsg({
                    variables: {
                        dlmId: id
                    }
                });

                if (data.deleteDidLowMsg) {
                    toast.info('메시지를 삭제했습니다.');
                    await msgRefetch();
                }
            } catch (e) {
                toast.error(e.message);
            }
        }
    }, []);

    return (
        <Wrapper>
            <RegularText>하단 메시지 설정</RegularText>
            <StyledFlexBox margin='22px 0 20px'>
                <StyledRadio
                    label='사용'
                    name='usingMessage'
                    value='true'
                    checked={screenState.usingMessage}
                    onChange={e => onChange(e, 'screenState')}
                />
                <StyledRadio
                    margin='0 0 0 20px'
                    label='사용하지 않음'
                    name='usingMessage'
                    value='false'
                    checked={screenState.usingMessage}
                    onChange={e => onChange(e, 'screenState')}
                />
            </StyledFlexBox>
            <StyledFlexBox alignItems='center'>
                <StyledInput
                    width={300}
                    name='message'
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    disabled={screenState?.usingMessage === 'false'}
                    onKeyPress={e => e.key === 'Enter' && handleAddMessage()}
                    placeholder='메시지를 입력하세요.'
                />
                <StyledButton
                    title='추가하기'
                    width={120}
                    borderRadius={4}
                    margin='0 0 0 20px'
                    onClick={handleAddMessage}
                />
            </StyledFlexBox>
            <BlueText>순서대로 재생됩니다.</BlueText>
            <MessageList>
                {msgLoading
                    ? <Loader/>
                    : msgData?.seeDidLowMsg?.didLowMsgList?.map(message => (
                        <MessageItem
                            key={`${message.dlm_id}-message`}
                        >
                            <Text>{message.dlm_text}</Text>
                            <CloseButton onClick={() => handleDeleteMessage(message.dlm_id)}>
                                <IoCloseOutline fontSize={20}/>
                            </CloseButton>
                        </MessageItem>
                    ))}
            </MessageList>
        </Wrapper>
    )
}

export default React.memo(BottomMessageSetup);
