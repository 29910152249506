import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { MonitorSetupContext } from './MonitorSetupModal';
import BottomMessageSetup from './BottomMessageSetup';
import theme from '../../../../styles/theme';
import StyledFlexBox from '../../../styled/StyledFlexBox';
import StyledSelect from '../../../styled/StyledSelect';
import { patientInfoOrderOptions, ratioOptions } from '../../../../pages/DID/did.lib';
import StyledButton from '../../../styled/StyledButton';
import { RegularText } from '../../../styled/StyledText';
import StyledCheckbox from '../../../styled/StyledCheckbox';

const Wrapper = styled.div`
  display: flex;
`;
const Section = styled.div`
  flex: 1;
`;
const Line = styled.div`
  position: relative;
  margin: 0 40px;

  &:after {
    content: '';
    width: 1px;
    height: calc(100% + 190px);
    top: -148px;
    position: absolute;
    background-color: ${theme.colors.ultraLightGrayBorder};
  }
`;

const ScreenSetupForm = ({ handleSave }) => {
    const { options, options: { screenState }, setOptions, onChange } = useContext(MonitorSetupContext);

    const onChangeCheckbox = useCallback(e => {
        const { name, checked } = e.target;

        setOptions({
            ...options,
            screenState: {
                ...screenState,
                [name]: checked
            }
        });
    }, [options, screenState]);

    return (
        <Wrapper>
            <Section>
                <StyledFlexBox justifyContent='space-between'>
                    <StyledSelect
                        title='모니터 종류'
                        options={['가로모니터', '세로모니터']}
                        name='monitorType'
                        value={screenState.monitorType}
                        onChange={e => onChange(e, 'screenState')}
                    />
                    <StyledSelect
                        title='정보 분류'
                        options={['영상', '이미지']}
                        name='mediaType'
                        value={screenState.mediaType}
                        onChange={e => onChange(e, 'screenState')}
                        disabled
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='예약 정보 사용 여/부'
                        options={['사용', '미사용']}
                        name='usingAppointmentInfo'
                        value={screenState.usingAppointmentInfo}
                        onChange={e => onChange(e, 'screenState')}
                    />
                    <StyledSelect
                        title='예약 정보 송출 방식'
                        options={['상시 노출', '일시 노출']}
                        name='transmissionMethod'
                        value={screenState.transmissionMethod}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.usingAppointmentInfo === '미사용'}
                    />
                </StyledFlexBox>

                {(screenState.usingAppointmentInfo === '사용' && screenState.transmissionMethod === '일시 노출') && (
                    <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                        <StyledSelect
                            title='예약 정보 노출 시간'
                            options={['10 초', '20 초', '30 초']}
                            name='appointmentInfoDisplayTime'
                            value={screenState.appointmentInfoDisplayTime}
                            onChange={e => onChange(e, 'screenState')}
                            disabled={screenState.usingAppointmentInfo === '미사용'}
                        />
                        <StyledSelect
                            title='예약 정보 노출 주기'
                            options={['10 초', '20 초', '30 초']}
                            name='appointmentInfoDisplayCycle'
                            value={screenState.appointmentInfoDisplayCycle}
                            onChange={e => onChange(e, 'screenState')}
                            disabled={screenState.usingAppointmentInfo === '미사용'}
                        />
                    </StyledFlexBox>
                )}

                <StyledFlexBox justifyContent='space-between'>
                    <StyledSelect
                        title='예약 정보 위치'
                        options={['위', '아래', '좌', '우']}
                        name='appointmentInfoLocation'
                        value={screenState.appointmentInfoLocation}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.usingAppointmentInfo === '미사용' || screenState.screenRatio === '1 : 0'}
                    />
                    <StyledSelect
                        title='화면 분할 (위:아래 / 좌:우)'
                        options={['1 : 0', '1 : 1', '1 : 2', '1 : 3', '2 : 1', '3 : 1']}
                        name='screenRatio'
                        value={screenState.screenRatio}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.usingAppointmentInfo === '미사용'}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='환자정보 표시 순서 - 1번'
                        options={patientInfoOrderOptions}
                        name='patientInfo_1st'
                        value={screenState.patientInfo_1st}
                        onChange={e => onChange(e, 'screenState')}
                    />
                    <StyledSelect
                        title='1번 비율'
                        options={ratioOptions}
                        name='ratio_1st'
                        value={screenState.ratio_1st}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.patientInfo_1st === '사용안함'}
                    />
                </StyledFlexBox>
                <StyledFlexBox justifyContent='space-between'>
                    <StyledSelect
                        title='환자정보 표시 순서 - 2번'
                        options={patientInfoOrderOptions}
                        name='patientInfo_2nd'
                        value={screenState.patientInfo_2nd}
                        onChange={e => onChange(e, 'screenState')}
                    />
                    <StyledSelect
                        title='2번 비율'
                        options={ratioOptions}
                        name='ratio_2nd'
                        value={screenState.ratio_2nd}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.patientInfo_2nd === '사용안함'}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0' justifyContent='space-between'>
                    <StyledSelect
                        title='환자정보 표시 순서 - 3번'
                        options={patientInfoOrderOptions}
                        name='patientInfo_3rd'
                        value={screenState.patientInfo_3rd}
                        onChange={e => onChange(e, 'screenState')}
                    />
                    <StyledSelect
                        title='3번 비율'
                        options={ratioOptions}
                        name='ratio_3rd'
                        value={screenState.ratio_3rd}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.patientInfo_3rd === '사용안함'}
                    />
                </StyledFlexBox>
                <StyledFlexBox justifyContent='space-between'>
                    <StyledSelect
                        title='환자정보 표시 순서 - 4번'
                        options={patientInfoOrderOptions}
                        name='patientInfo_4th'
                        value={screenState.patientInfo_4th}
                        onChange={e => onChange(e, 'screenState')}
                    />
                    <StyledSelect
                        title='4번 비율'
                        options={ratioOptions}
                        name='ratio_4th'
                        value={screenState.ratio_4th}
                        onChange={e => onChange(e, 'screenState')}
                        disabled={screenState.patientInfo_4th === '사용안함'}
                    />
                </StyledFlexBox>
                <StyledButton
                    width={440}
                    title='저장하기'
                    margin='60px 0 0'
                    onClick={handleSave}
                />
            </Section>
            <Line />
            <Section>
                <StyledFlexBox margin='0 0 30px'>
                    <StyledCheckbox
                        id='isNameMaking'
                        label='환자명 마스킹 처리'
                        name='isNameMaking'
                        checked={screenState.isNameMaking}
                        onChange={onChangeCheckbox}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='0 0 30px'>
                    <StyledCheckbox
                        id='isDoctorsHorizontal'
                        label='진료실 가로 보기'
                        name='isDoctorsHorizontal'
                        checked={screenState.isDoctorsHorizontal}
                        onChange={onChangeCheckbox}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='0 0 30px'>
                    <StyledCheckbox
                        id='isDoctorsDivide'
                        label='진료실 분할 보기'
                        name='isDoctorsDivide'
                        checked={screenState.isDoctorsDivide}
                        onChange={onChangeCheckbox}
                    />
                </StyledFlexBox>                
                <BottomMessageSetup />
            </Section>
        </Wrapper>
    )
}

export default React.memo(ScreenSetupForm);
