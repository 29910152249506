import React, {createContext, useCallback, useLayoutEffect, useState} from 'react';
import PageHeader from "../../components/layout/PageHeader";
import {SiteMapBox, SiteMapTitle} from "./setting.style";
import SiteMap from "../../components/feature/Setting/SiteMap";
import GuideBox from "../../components/feature/Setting/GuideBox";
import JobSettingModal from "../../components/feature/Setting/Modal/JobSettingModal";
import HospitalSettingModal from "../../components/feature/Setting/Modal/HospitalSettingModal";
import HospitalOperationScheduleModal from "../../components/feature/Setting/Modal/HospitalOperationScheduleModal";
import {useNavigate} from "react-router-dom";
import {handleBodyScroll} from "../../utils/commons";
import DoctorsOperationTimeModal from "../../components/feature/Setting/Modal/DoctorsOperationTimeModal";
import PlatformInterWorkingModal from "../../components/feature/Setting/Modal/PlatformInterWorkingModal";
import OrgSettingModal from "../../components/feature/Setting/Modal/OrgSettingModal";
import MedicalSubjectSettingModal from "../../components/feature/Setting/Modal/MedicalSubjectSettingModal";
import MedicalSubjectDetailSettingModal from "../../components/feature/Setting/Modal/MedicalSubjectDetailSettingModal";
import NotificationSettingModal from "../../components/feature/Setting/Modal/NotificationSettingModal";
import {toast} from "react-toastify";
import NotificationDefaultSettingModal from "../../components/feature/Setting/Modal/NotificationDefaultSettingModal";
import { useMutation, useReactiveVar } from '@apollo/client';
import {userVar} from "../../store";
import { LINKED_CHAT_BOT_ADMIN_PAGE } from '../../graphql/Setting/mutation';

export const SettingContext = createContext(null);

const Setting = () => {
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const [siteMap, setSiteMap] = useState(null);

    const [linkedChatbotAdminPage] = useMutation(LINKED_CHAT_BOT_ADMIN_PAGE);

    const handleClickSiteMap = useCallback(site => {
        if (site === '사용자 관리') {
            navigate('/setting/manager');
        } else if (site === '쉬는날') {
            navigate('/setting/day-off');
        } else if (site === '이벤트 전체 기록') {
            navigate('/setting/history');
        } else if (
            // site === 'EMR 연동 설정' ||
            // site === '카카오 예약 연동 설정' ||
            // site === '네이버 예약 연동 설정' ||
            site === 'DID 모니터 결제 기록' // navigate('/setting/payment');
        ) {
            toast.error('현재 준비중입니다.');
        } else {
            setSiteMap(site);
            handleBodyScroll('hidden');
            window.scrollTo({top: 0});
        }
    }, []);

    const handleCloseSiteMap = useCallback(() => {
        setSiteMap(null);
        handleBodyScroll('initial');
    }, []);

    const handleBindingPlatform = useCallback(async (site) => {
        switch (site) {
            case '카카오 예약 연동 설정':
                try{
                    const { data: kakaoData } = await linkedChatbotAdminPage();
                    if (kakaoData?.linkedChatbotAdminPage) {
                        window.open(kakaoData?.linkedChatbotAdminPage, '_blank');
                    }
                }catch(e){
                    toast.error(e.message);
                }
                
                break;
            case '네이버 예약 연동 설정':
            case 'EMR 연동 설정':
                toast.error('현재 준비중입니다.');
                break;
        }
    }, []);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.setting) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);

    return (
        <SettingContext.Provider value={{
            siteMap,
            handleCloseSiteMap
        }}>
            <PageHeader
                title='설정'
            />
            <SiteMapBox>
                <SiteMap
                    imageText='🏥‍'
                    title='병원 기본 설정'
                    siteMaps={['병원 정보', '운영일 설정', '쉬는날', '진료실 운영시간 설정']}
                    handleClickSiteMap={handleClickSiteMap}
                />
                <SiteMap
                    imageText='💁🏻‍'
                    title='인사 정보 설정'
                    siteMaps={['사용자 관리', '조직 설정', '직책 설정']}
                    handleClickSiteMap={handleClickSiteMap}
                />
                <SiteMap
                    imageText='💬'
                    title='예약 설정'
                    siteMaps={['예약시 알림 템플릿 설정', '예약 진료 대분류 설정', '예약 진료 소분류 설정', '예약 알림 기본 설정']}
                    handleClickSiteMap={handleClickSiteMap}
                />
                <SiteMap
                    imageText='🗄️'
                    title='연동 설정'
                    siteMaps={['EMR 연동 설정', '카카오 예약 연동 설정', '네이버 예약 연동 설정']}
                    handleClickSiteMap={handleBindingPlatform}
                />
            </SiteMapBox>

            <SiteMapTitle>기록</SiteMapTitle>
            <SiteMapBox>
                <SiteMap
                    imageText='📝'
                    title='플랫폼 관리 기록'
                    siteMaps={['이벤트 전체 기록']}
                    handleClickSiteMap={handleClickSiteMap}
                />
                <SiteMap
                    imageText='💵‍'
                    title='결제 기록'
                    siteMaps={['DID 모니터 결제 기록']}
                    handleClickSiteMap={handleClickSiteMap}
                />
            </SiteMapBox>

            <SiteMapTitle>소식·가이드</SiteMapTitle>
            <SiteMapBox>
                <GuideBox
                    imageText='📄‍'
                    title='플랫폼 공지'
                    subTitle={`닥터히포는 지속적인 업데이트를\n시행하고 있습니다.`}
                    onClickShortcut={() => navigate('/home/notice/platform')}
                />
                <GuideBox
                    imageText='📙‍'
                    title='닥터히포 사용가이드'
                    subTitle={`아직 닥터히포가 익숙하지 않나요?\n사용 가이드를 참고해보세요.`}
                    onClickShortcut={() => toast.info('곧 준비 중 입니다.')}
                />
                <GuideBox
                    imageText='🌐‍'
                    title='메디칼소프트 홈페이지'
                    subTitle={`닥터히포는 메디칼소프트에서 운영하고\n관리합니다.`}
                    onClickShortcut={() => window.open('https://msh.platcube.com/', '_blank')}
                />
            </SiteMapBox>


            {/* 병원 정보 설정 모달 */}
            <HospitalSettingModal />

            {/* 병원 운영일 설정 */}
            <HospitalOperationScheduleModal />

            {/* 진료실 운영 시간 설정 */}
            <DoctorsOperationTimeModal />

            {/* 조직 설정 모달 */}
            <OrgSettingModal />

            {/* 직책 설정 모달 */}
            <JobSettingModal />

            {/* 예약시 알림 템플릿 설정 */}
            <NotificationSettingModal />

            {/* 대분류 설정 모달 */}
            <MedicalSubjectSettingModal />

            {/* 소분류 설정 모달 */}
            <MedicalSubjectDetailSettingModal />

            {/* 알림 기본 설정 */}
            <NotificationDefaultSettingModal />

            {/* EMR 연동 설정 */}
            <PlatformInterWorkingModal />


        </SettingContext.Provider>
    );
}

export default Setting;
