import React, {useCallback, useLayoutEffect, useState} from "react";
import {
    GrayText,
    Wrapper,
    ProfileBox,
    UserInfoBox, BlackText, Title, InfoTitleHead
} from "./profile.style";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {userRefetchVar, userVar} from "../../store";
import {BoldText} from "../../components/styled/StyledText";
import StyledFlexBox from "../../components/styled/StyledFlexBox";
import StyledIconButton from "../../components/styled/StyledIconButton";
import {autoHyphen, birthFormatter, errorMessage} from "../../utils/commons";
import ProfileImageUpload from "../../components/share/ProfileImageUpload";
import PersonalInfoModal from "../../components/feature/Profile/PersonalInfoModal";
import HRInfoModal from "../../components/feature/Profile/hrInfoModal";
import {UPDATE_USER_IMG} from "../../graphql/Profile/mutation";
import {toast} from "react-toastify";
import {SEE_USER_INFO_DETAIL} from "../../graphql/Common/query";

const Profile = () => {
    const user = useReactiveVar(userVar);
    const userRefetch = useReactiveVar(userRefetchVar);
    const [previewUrl, setPreviewUrl] = useState(user.user_img);
    const [personalInfoModal, setPersonalInfoModal] = useState(false);
    const [hrInfoModal, setHrInfoModal] = useState(false);

    const { refetch } = useQuery(SEE_USER_INFO_DETAIL);
    const [updateUserImg] = useMutation(UPDATE_USER_IMG);

    const handleCopyClipBoard = useCallback(async value => {
        try {
            await navigator.clipboard.writeText(value);
            toast.info('클립보드에 링크가 복사되었습니다.');
        } catch (e) {
            toast.error('복사에 실패하였습니다');
            ;
        }
    }, []);

    const handleEditProfileImage = useCallback(async e => {
        const file = e.target.files[0];

        try {
            const { data } = await updateUserImg({
                variables: {
                    userImg: file
                }
            });

            if (data.updateUserImg) {
                await userRefetch();
                setPreviewUrl(URL.createObjectURL(file));
                toast.info('이미지를 업로드했습니다.');
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    useLayoutEffect(() => {
        if (user.user_img) {
            setPreviewUrl(user.user_img);
        }
    }, [user]);

    return (
        <Wrapper>
            <Title>내 프로필</Title>
            <ProfileBox>
                <ProfileImageUpload
                    userName={user.user_name}
                    onChange={handleEditProfileImage}
                    previewUrl={previewUrl}
                />
                <UserInfoBox>
                    <BoldText>{user.user_name}</BoldText>
                    <StyledFlexBox margin='12px 0 0'>
                        <BlackText $width={46}>조직</BlackText>
                        <GrayText>
                            {user.user_org || '정보 미입력'}
                        </GrayText>
                    </StyledFlexBox>
                    <StyledFlexBox margin='8px 0'>
                        <BlackText $width={46}>직무</BlackText>
                        <GrayText>
                            {user.user_job || '정보 미입력'}
                        </GrayText>
                    </StyledFlexBox>
                    <StyledFlexBox margin='16px 0 0 -4px'>
                        <StyledIconButton
                            PHONE
                            onClick={() => handleCopyClipBoard(user.user_cellphone)}
                        />
                        <StyledIconButton
                            MAIL
                            margin='0 12px'
                            onClick={() => handleCopyClipBoard(user.user_email)}
                        />
                        {/*<StyledIconButton MENU />*/}
                    </StyledFlexBox>
                </UserInfoBox>
            </ProfileBox>
            <InfoTitleHead>
                <BoldText>개인 정보</BoldText>
                <StyledIconButton
                    UPDATE
                    onClick={() => setPersonalInfoModal(true)}
                />
            </InfoTitleHead>
            <StyledFlexBox>
                <BlackText>이메일</BlackText>
                <BlackText>
                    {user.user_email || '정보 미입력'}
                </BlackText>
            </StyledFlexBox>
            <StyledFlexBox margin='20px 0'>
                <BlackText>이름</BlackText>
                <BlackText>
                    {user.user_name || '정보 미입력'}
                </BlackText>
            </StyledFlexBox>
            <StyledFlexBox>
                <BlackText>생년월일</BlackText>
                <BlackText>
                    {user.user_birthday ? birthFormatter(user.user_birthday.replaceAll('-', '')) : '정보 미입력'}
                </BlackText>
            </StyledFlexBox>
            <StyledFlexBox margin='20px 0'>
                <BlackText>휴대전화번호</BlackText>
                <BlackText>
                    {user.user_cellphone ? autoHyphen(user.user_cellphone) : '정보 미입력'}
                </BlackText>
            </StyledFlexBox>
            <StyledFlexBox margin='0 0 40px'>
                <BlackText>집주소</BlackText>
                <BlackText>
                    {user.user_address || '정보 미입력'}
                </BlackText>
            </StyledFlexBox>
            <InfoTitleHead>
                <BoldText>인사 정보</BoldText>
                <StyledIconButton
                    UPDATE
                    onClick={() => setHrInfoModal(true)}
                />
            </InfoTitleHead>
            <StyledFlexBox>
                <BlackText>조직</BlackText>
                <BlackText>
                    {user.user_org || '정보 미입력'}
                </BlackText>
            </StyledFlexBox>
            <StyledFlexBox margin='20px 0'>
                <BlackText>직무</BlackText>
                <BlackText>
                    {user.user_job || '정보 미입력'}
                </BlackText>
            </StyledFlexBox>

            <PersonalInfoModal
                personalInfoModal={personalInfoModal}
                setPersonalInfoModal={setPersonalInfoModal}
                refetch={refetch}
            />

            <HRInfoModal
                hrInfoModal={hrInfoModal}
                setHrInfoModal={setHrInfoModal}
                refetch={refetch}
            />

        </Wrapper>
    );
}

export default Profile;
