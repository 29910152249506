import {makeVar} from '@apollo/client';

export const userState = {
    hsp_email: '',
    userType: '',
    user_address: '',
    user_birthday: '',
    user_cellphone: '',
    user_createdAt: '',
    user_creatorId: 0,
    user_creatorName: '',
    user_creatorRank: '',
    user_deleteDate: null,
    user_detailAddress: '',
    user_editorId: 0,
    user_editorName: '',
    user_editorRank: '',
    user_email: '',
    user_hnAlim: true,
    user_id: 0,
    user_img: '',
    user_isDelete: false,
    user_job: '',
    user_name: '',
    user_org: '',
    user_passwordInit: true,
    user_rankPermission: {
        home: true,
        reservation: true,
        schedule: true,
        patient: true,
        did: true,
        insurance: true,
        cs: true,
        setting: true
    },
    user_pnAlim: true,
    user_pwUpdateDate: '',
    user_rank: '',
    user_resAlim: true,
    user_specialAlim: true,
    user_updatedAt: '',
    user_hospitalName: ''
};
export const doctorsState = [];

export const userVar = makeVar(userState);
export const userRefetchVar = makeVar(null);
export const doctorsVar = makeVar(doctorsState);

