import { gql } from '@apollo/client';

export const RESEND_INSURANCE_REQUEST = gql`
    mutation ResendInsuranceRequest($ihId: Int!) {
        resendInsuranceRequest(ih_id: $ihId)
    }
`;

export const DELETE_INSURANCE_REQUEST = gql`
    mutation DeleteInsuranceRequest($ihId: Int!) {
        deleteInsuranceRequest(ih_id: $ihId)
    }
`;
