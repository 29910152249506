import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';
import { BoldText, MediumText, RegularText } from '../../components/styled/StyledText';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const AutoplaySlider = withAutoplay(AwesomeSlider);
export const CustomSlider = styled(AutoplaySlider)`
    .awssld__timer {
        opacity: 0;
        pointer-events: none; /* prevent events (mouse clicking etc.) */
    }

/*     .awssld__content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* 부모 요소의 전체 높이 사용 */
        position: relative;
    } */
`;

export const StyledImage = styled.img`
    position: absolute;
    //width: 100%;
    transform: translate(-50%, 0%);
    //width: auto;
    //height: 87vh;
    width: 80%;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    
`;

export const Component = styled.div`
    align-self: stretch;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
`;

export const Dr = styled.span`
    font-family: 'AppleSDGothicNeoBold';
`;
export const Span = styled.span`
    white-space: pre-wrap;
`;
export const Hippo = styled.span`
    font-family: 'AppleSDGothicNeoBold';
    color: #ff791a;
`;
export const DrHippo = styled.div`
    flex: 1;
    position: relative;
    font-weight: 800;
    transform: rotate(0.1deg);
    z-index: 0;
`;
export const PlusFillIcon = styled.img`
    width: 18px;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    left: 46.83px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
    z-index: 1;
`;
export const DrHippoParent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 10px;
`;
export const Platform = styled.div`
    width: 208.1px;
    position: relative;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
`;
export const PlatformParent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;
    margin-left: -442px;
    text-align: center;
    font-size: 25px;
    color: #000;
`;
export const RectangleParent = styled.div`
    width: 159px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
`;
export const Wrapper = styled.div`
    border-radius: 10px;
    background-color: #002368;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 14px 42px;
    z-index: 0;
`;
export const SearchIdParent = styled.div`
    width: 108px;
    margin: 0 !important;
    position: absolute;
    top: 56px;
    left: 51px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5px;
    z-index: 1;
    text-align: right;
    font-size: 13px;
    color: #989898;
`;
export const FrameGroup = styled.div`
    width: 159px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    gap: 6px;
    text-align: center;
    color: #fff;
`;
export const FrameParent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 15px;
    margin-left: -442px;
    font-size: 18px;
    color: #ababab;
`;
export const FlexibleMenu = styled.div`
    align-self: stretch;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 33px 80px;
    box-sizing: border-box;
`;
export const FrameInner = styled.img`
    width: 30px;
    position: relative;
    height: 60px;
    object-fit: contain;
`;
export const VectorWrapper = styled.div`
    margin: 0 !important;
    position: absolute;
    top: calc(50% - 29.5px);
    left: 30px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
`;
export const VectorIcon = styled.img`
    width: 30px;
    position: relative;
    height: 60px;
`;
export const VectorContainer = styled.div`
    margin: 0 !important;
    position: absolute;
    top: calc(50% - 29.5px);
    right: 30px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
`;
export const EllipseDiv = styled.div`
    width: 18px;
    position: relative;
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.75);
    box-sizing: border-box;
    height: 18px;
`;
export const FrameChild1 = styled.div`
    width: 18px;
    position: relative;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.75);
    height: 18px;
`;
export const EllipseParent = styled.div`
    width: 1920px;
    margin: 0 !important;
    position: absolute;
    bottom: 0px;
    left: calc(50% - 960px);
    height: 138px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: 60px 913px;
    box-sizing: border-box;
    gap: 20px;
    z-index: 2;
`;
export const FrameContainer = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 141px;
`;
export const ComponentInner = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const MsSmallRoot = styled.div`
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 40px;
    color: #0038ff;
    font-family: Inter;
`;

export const Loader = styled.div`
    width: 26px;
    height: 26px;
    border: 3px solid ${theme.colors.whiteColor};
    border-top: 3px solid ${theme.colors.activeBlue};
    border-radius: 50%;
    animation: ${Spin} 1.3s linear infinite;
`;

export const LoginButton = styled.button`
    width: 50px;
    height: 5px;
    font-family: 'AppleSDGothicNeoBold';
    font-size: 20px;
    color: ${theme.colors.whiteColor};
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: ${theme.colors.activeIndigo};
`;
export const LoaderBox = styled.div`
    width: 100%;
    height: ${({ height }) => (height ? height : '100%')};
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const GrayText = styled(MediumText)`
    color: ${theme.colors.fontGrayColor};
    cursor: pointer;
    transition: 0.25s;
    font-size: 15px;
    &:hover {
        color: ${theme.colors.blackColor};
    }
`;
export const Line = styled.div`
    width: 1px;
    height: 14px;
    margin: 0 5px;
    background-color: ${theme.colors.fontGrayColor};
`;
export const MenuText = styled(BoldText)`
    color: ${theme.colors.fontDarkGrayColor};
    cursor: pointer;
    transition: 0.25s;
    font-size: 27px;
    font-family: 'AppleSDGothicNeoBold';
    &:hover {
        color: ${theme.colors.blackColor};
    }
`;
