import React, {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import theme from "../../../styles/theme";
import FullPageModalBackground from "../../layout/FullPageModalBackground";
import {RegularText} from "../../styled/StyledText";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledInput from "../../styled/StyledInput";
import StyledSelect from "../../styled/StyledSelect";
import StyledBorderCalendar from "../../styled/StyledBorderCalendar";
import {
    appointmentValidCheck,
    filterChange,
    statusOption,
    timeOption
} from "../../../pages/Appointment/appointment.lib";
import StyledCheckbox from "../../styled/StyledCheckbox";
import StyledTextarea from "../../styled/StyledTextarea";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {
    SEE_MEDICAL_SUBJECT,
    SEE_MEDICAL_SUBJECT_DETAIL,
    SEE_RES_ALIM_TEMPLATE
} from "../../../graphql/Common/query";
import {autoHyphen, errorMessage} from "../../../utils/commons";
import {CREATE_RESERVATION} from "../../../graphql/Appointment/mutation";
import StyledRadio from "../../styled/StyledRadio";
import { InputSection, BlueTitle, NotificationTypeBox } from "../Appointment/appointment.style";
import {SEE_PATIENT_DETAIL} from "../../../graphql/Patient/query";
import {doctorsVar} from "../../../store";
import LoaderBackground from "../../share/LoaderBackground";
import Loader from "../../share/Loader";

const PatientAppointmentModal = ({
                                     selectedId,
                                     patientAppointmentModal,
                                     setPatientAppointmentModal,
                          }) => {
    const doctorsOption = useReactiveVar(doctorsVar);

    const [inputs, setInputs] = useState({
        name: '',
        phoneNumber: '',
        memo: '',
        time: '시간 선택',
        status: statusOption[0],
        doctors: '진료실 선택',
        largeCategory: '항목 선택',
        smallCategory: '항목 선택',
        smsTemplate: '저장된 문자 템플릿 선택하기',
        content: '',
    });
    const [date, setDate] = useState(new Date());
    const [checkbox, setCheckbox] = useState({
        notification: 'none',
        time1: false,
        time2: false,
        time3: false,
        time4: false
    });
    const [deptCode, setDeptCode] = useState('');
    const [selectedMsId, setSelectedMsId] = useState(null); // 선택한 대분류 id
    const [selectedTemplate, setSelectedTemplate] = useState(''); // textarea template
    const [selectedTemplateId, setSelectedTemplateId] = useState(0); // 선택한 템플릿 id
    const [loading, setLoading] = useState(false);
    // Option
    const [largeCategoryOption, setLargeCategoryOption] = useState([]);
    const [smallCategoryOption, setSmallCategoryOption] = useState([]);
    const [templateOption, setTemplateOption] = useState([]);

    // Query
    const { data: detailData } = useQuery(SEE_PATIENT_DETAIL, {
        variables: {
            patiId: selectedId,
        },
        fetchPolicy: 'network-only'
    });
    const { data: largeCategoryData, loading: largeCategoryLoading } = useQuery(SEE_MEDICAL_SUBJECT);
    const { data: smallCategoryData, loading: smallCategoryLoading } = useQuery(SEE_MEDICAL_SUBJECT_DETAIL, {
        variables: {
            msId: selectedMsId,
        }
    });
    const { data: smsTemplateData, loading: smsTemplateLoading } = useQuery(SEE_RES_ALIM_TEMPLATE);
    // Mutation
    const [createReservation] = useMutation(CREATE_RESERVATION);

    const onChange = useCallback(e => { // input, select onChange
        const { name, value } = e.target;

        if (name === 'doctors') {
            const deptCode = doctorsOption.find(data => data?.dr_roomName === value)?.dr_deptCode;
            setDeptCode(deptCode);
        }

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const onChangeCheckbox = useCallback(e => { // checkbox onChange
        const { name, checked } = e.target;

        setCheckbox({
            ...checkbox,
            [name]: checked
        });
    }, [checkbox]);

    const onChangeRadio = useCallback(e => { // radio onChange
        const { name, value } = e.target;

        setCheckbox({
            ...checkbox,
            [name]: value
        });
        e.target.checked = false;
    }, [checkbox]);

    const handleSave = useCallback(async () => {
        if (appointmentValidCheck(inputs)) {
            return;
        }
        setLoading(true);
        try {
            const { data } = await createReservation({
                variables: {
                    patientName: detailData?.seePatientDetail?.pati_name,
                    patientCellphone: detailData?.seePatientDetail?.pati_cellphone,
                    resDate: date,
                    time: inputs.time,
                    status: filterChange(inputs.status),
                    doctorRoomName: inputs.doctors,
                    drDeptCode: deptCode,
                    patientId: selectedId,
                    oneLineMemo: inputs.memo,
                    largeCategory: inputs.largeCategory === '항목 선택' ? '' : inputs.largeCategory,
                    smallCategory: inputs.smallCategory === '항목 선택' ? '' : inputs.smallCategory,
                    alimType: checkbox.notification,
                    alimTemplateId: selectedTemplateId,
                    alimTime1: checkbox.time1,
                    alimTime2: checkbox.time2,
                    alimTime3: checkbox.time3,
                    alimTime4: checkbox.time4,
                }
            });

            if (data.createReservation) {
                toast.info('예약자를 등록했습니다.');
                handleClose();
            }
        } catch (e) {
            errorMessage(e.message);
        } finally {
            setLoading(false);
        }
    }, [inputs, date, checkbox, selectedId, selectedTemplateId, detailData]);

    const handleClose = useCallback(() => { // 모달 닫기
        setPatientAppointmentModal(false);
        setInputs({
            name: '',
            phoneNumber: '',
            memo: '',
            time: '시간 선택',
            status: statusOption[0],
            doctors: doctorsOption[0]?.dr_roomName,
            largeCategory: '항목 선택',
            smallCategory: '항목 선택',
            smsTemplate: '저장된 문자 템플릿 선택하기',
            content: '',
        });
        setDate(new Date());
        setCheckbox({
            notification: '',
            time1: false,
            time2: false,
            time3: false,
            time4: false
        });
        setDeptCode('');
        setSelectedMsId(null);
        setSelectedTemplate(null);
        setSelectedTemplateId(null);
    }, []);

    useEffect(() => { // 각 상태 초기화
        if (!largeCategoryLoading && largeCategoryData) { // 대분류 데이터
            setLargeCategoryOption(largeCategoryData?.seeMedicalSubject?.medicalSubjectList?.map(item => item.ms_name));
        }

        if (inputs.largeCategory !== '항목 선택') { // 대분류 데이터 선택된 id 찾기
            const tmpId = largeCategoryData?.seeMedicalSubject?.medicalSubjectList?.find(item => item.ms_name === inputs.largeCategory)?.ms_id;
            setSelectedMsId(tmpId);
        }

        if (!smallCategoryLoading && smallCategoryData) { // 소분류 데이터
            setSmallCategoryOption(smallCategoryData?.seeMedicalSubjectDetail?.medicalSubjectDetailList?.map(item => item.msd_name));
        }

        if (!smsTemplateLoading && smsTemplateData) { // 문자 템플릿
            setTemplateOption(smsTemplateData?.seeResAlimTemplate?.templateList.map(item => item.rat_title));
        }

        if (inputs.smsTemplate !== '저장된 문자 템플릿 선택하기') {
            const tmpTemplate =  smsTemplateData?.seeResAlimTemplate?.templateList?.find(item => item.rat_title === inputs.smsTemplate);

            if (tmpTemplate) {
                setSelectedTemplate(tmpTemplate?.rat_text);
                setSelectedTemplateId(tmpTemplate?.rat_id);
            }
        }
    }, [inputs, largeCategoryLoading, largeCategoryData, smallCategoryLoading, smallCategoryData, smsTemplateLoading, smsTemplateData]);

    useEffect(() => {
        if (detailData) { // 선택한 리스트가 있을 때
            let data = detailData?.seePatientDetail;
            if (!data) return;

            setInputs({
                name: `${data?.pati_name} - ${data?.pati_cellphone} - ${data?.pati_rrn} - ${data?.pati_chartNumber}`,
                phoneNumber: autoHyphen(data?.pati_cellphone),
                memo: '',
                time: '시간 선택',
                status: statusOption[0],
                doctors: '진료실 선택',
                largeCategory: '항목 선택',
                smallCategory: '항목 선택',
                smsTemplate: '저장된 문자 템플릿 선택하기',
                content: '',
            });
        }
    }, [detailData]);

    return (
        <FullPageModalBackground
            CONFIRM
            buttonTitle='예약자 등록'
            guideLine='예약 > 예약자현황 > 등록'
            modalTitle='예약자 정보 입력'
            visible={patientAppointmentModal}
            onClose={handleClose}
            onClickButton={handleSave}
            buttonColor={theme.colors.activeBlue}>
            {loading && (
                <LoaderBackground>
                    <Loader/>
                </LoaderBackground>
            )}
            <InputSection>
                <BlueTitle>예약자 정보 입력</BlueTitle>
                <StyledFlexBox margin='20px 0'>
                    <StyledInput
                        ASTERISK
                        title='이름'
                        name='name'
                        value={inputs.name}
                        onChange={onChange}
                        placeholder='예약자의 이름을 입력해주세요.'
                        disabled
                    />
                    <StyledInput
                        ASTERISK
                        margin='0 0 0 30px'
                        title='휴대전화'
                        name='phoneNumber'
                        value={inputs.phoneNumber}
                        onChange={onChange}
                        placeholder='예약자 휴대폰 번호를 입력해주세요.'
                        maxLength={13}
                        disabled
                    />
                </StyledFlexBox>
                <StyledFlexBox>
                    <StyledInput
                        title='한 줄 메모'
                        name='memo'
                        value={inputs.memo}
                        onChange={onChange}
                        placeholder='필요한 경우 한 줄 메모를 입력해주세요.'
                    />
                </StyledFlexBox>
            </InputSection>
            <InputSection>
                <BlueTitle>예약자 상세 정보 입력</BlueTitle>
                <StyledFlexBox>
                    <StyledBorderCalendar
                        ASTERISK
                        width={440}
                        title='예약일'
                        value={date}
                        onChange={value => setDate(value)}
                    />
                    <StyledSelect
                        ASTERISK
                        width={440}
                        fontColor={inputs.time === '시간 선택' && theme.colors.fontGrayColor}
                        margin='0 0 0 30px'
                        title='예약일시'
                        name='time'
                        value={inputs.time}
                        onChange={onChange}
                        options={['시간 선택', ...timeOption()]}
                    />
                </StyledFlexBox>
                <StyledFlexBox margin='20px 0'>
                    <StyledSelect
                        ASTERISK
                        width={440}
                        title='예약상태'
                        name='status'
                        value={inputs.status}
                        onChange={onChange}
                        options={statusOption}
                    />
                    <StyledSelect
                        ASTERISK
                        width={440}
                        margin='0 0 0 30px'
                        title='진료실 정보'
                        name='doctors'
                        value={inputs.doctors}
                        onChange={onChange}
                        options={['진료실 선택', ...doctorsOption.map(room => room.dr_roomName)]}
                    />
                </StyledFlexBox>
                <StyledFlexBox>
                    <StyledSelect
                        width={440}
                        fontColor={inputs.largeCategory === '항목 선택' && theme.colors.fontGrayColor}
                        title='예약 구분(대분류)'
                        name='largeCategory'
                        value={inputs.largeCategory}
                        onChange={onChange}
                        options={['항목 선택', ...largeCategoryOption]}
                    />
                    <StyledSelect
                        width={440}
                        fontColor={inputs.smallCategory === '항목 선택' && theme.colors.fontGrayColor}
                        margin='0 0 0 30px'
                        title='예약 구분(소분류)'
                        name='smallCategory'
                        value={inputs.smallCategory}
                        onChange={onChange}
                        options={['항목 선택', ...smallCategoryOption]}
                    />
                </StyledFlexBox>
            </InputSection>
            <InputSection>
                <BlueTitle>알림 정보 입력</BlueTitle>
                <StyledFlexBox margin='0 0 30px'>
                    <NotificationTypeBox>
                        <RegularText>알림 발송 유형</RegularText>
                        <StyledFlexBox margin='20px 0 0'>
                            <StyledRadio
                                label='문자'
                                name='notification'
                                checked={checkbox.notification}
                                value='sms'
                                onChange={onChangeRadio}
                            />
                            <StyledRadio
                                label='카카오 알림톡'
                                margin='0 0 0 20px'
                                name='notification'
                                value='kakao'
                                checked={checkbox.notification}
                                onChange={onChangeRadio}
                            />
                        </StyledFlexBox>
                    </NotificationTypeBox>
                    <NotificationTypeBox>
                        <RegularText>알림 발송 시간 선택</RegularText>
                        <StyledFlexBox margin='20px 0 0'>
                            <StyledCheckbox
                                label='즉시'
                                id='time1'
                                name='time1'
                                checked={checkbox.time1}
                                onChange={onChangeCheckbox}
                            />
                            <StyledCheckbox
                                label='1일전'
                                margin='0 0 0 20px'
                                id='time2'
                                name='time2'
                                checked={checkbox.time2}
                                onChange={onChangeCheckbox}
                            />
                            <StyledCheckbox
                                label='2일전'
                                margin='0 0 0 20px'
                                id='time3'
                                name='time3'
                                checked={checkbox.time3}
                                onChange={onChangeCheckbox}
                            />
                            <StyledCheckbox
                                label='당일 오전 9시'
                                margin='0 0 0 20px'
                                id='time4'
                                name='time4'
                                checked={checkbox.time4}
                                onChange={onChangeCheckbox}
                            />
                        </StyledFlexBox>
                    </NotificationTypeBox>
                </StyledFlexBox>
                <StyledFlexBox alignItems='flex-start'>
                    <StyledSelect
                        width={440}
                        fontColor={inputs.smsTemplate === '저장된 문자 템플릿 선택하기' && theme.colors.fontGrayColor}
                        title='발송 문자 선택'
                        name='smsTemplate'
                        value={inputs.smsTemplate}
                        onChange={onChange}
                        options={['저장된 문자 템플릿 선택하기', ...templateOption]}
                    />
                    <StyledTextarea
                        height={166}
                        margin='0 0 0 30px'
                        title='발송 문자 미리보기'
                        name='content'
                        value={inputs.content}
                        onChange={onChange}
                        placeholder={selectedTemplate}
                        disabled
                    />
                </StyledFlexBox>
            </InputSection>
        </FullPageModalBackground>
    )
}

export default PatientAppointmentModal;
