import { gql } from '@apollo/client';

export const SEE_ORG = gql`
    query SeeOrg {
        seeOrg {
            totalLength
            orgList {
                org_id
                org_name
            }
        }
    }
`;

export const SEE_HOSPITAL_DETAIL = gql`
    query SeeHospitalDetail {
        seeHospitalDetail {
            hsp_id
            hsp_name
            hsp_chief
            hsp_hospitalNumber
            hsp_businessNumber
            hsp_img
            hsp_email
            hsp_address
            hsp_detailAddress
            hsp_medicalDepartment
            hsp_kakaoChannelId
            hsp_kakaoChannelUrl
            hsp_messageTrId
            hsp_messageSendNum
        }
    }
`;

export const SEE_HOSPITAL_OFF_DAY = gql`
    query SeeHospitalOffday(
        $year: Int!
        $month: Int!
        $offType: hospitalOffdayType!
        $searchTerm: String
    ) {
        seeHospitalOffday(
            year: $year
            month: $month
            offType: $offType
            searchTerm: $searchTerm
        ) {
            totalLength
            offdayList {
                ho_id
                offType
                startDate
                startDay
                endDate
                endDay
                reType
                startTime
                endTime
                memo
                createdAt
                creatorName
            }
        }
    }
`;

export const SEE_DOCTOR_ROOM_SCHEDULE = gql`
    query SeeDoctorRoomSchedule($drId: Int!) {
        seeDoctorRoomSchedule(dr_id: $drId) {
            drs_id
            drs_day
            drs_startHour
            drs_startMin
            drs_endHour
            drs_endMin
            drs_lunchBreak
            drs_lbStartHour
            drs_lbStartMin
            drs_lbEndHour
            drs_lbEndMin
        }
    }
`;

export const SEE_BY_MONTH_HOSPITAL_OFF_DAY = gql`
    query SeeByMonthHospitalOffday(
        $year: Int!
        $month: Int!
        $searchTerm: String
        $offType: hospitalOffdayType
    ) {
        seeByMonthHospitalOffday(
            year: $year
            month: $month
            searchTerm: $searchTerm
            offType: $offType
        ) {
            month
            date
            workType
            workStartHour
            workStartMin
            workEndHour
            workEndMin
            scheduleCheck
            offType
            reType
        }
    }
`;

export const SEE_USER_LOGIN_HISTORY = gql`
    query SeeUserLoginHistory($orderBy: String, $take: Int, $cursor: Int) {
        seeUserLoginHistory(orderBy: $orderBy, take: $take, cursor: $cursor) {
            totalLength
            userLoginHistories {
                ulh_id
                createdAt
                ip
                os
                browser
                userName
                userRank
                email
                status
            }
        }
    }
`;
