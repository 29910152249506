import styled, {css} from "styled-components";
import theme from "../../../../styles/theme";
import {BoldText, RegularText} from "../../../styled/StyledText";

export const NoticeContents = styled.div`
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
`;
export const NoticeSection = styled.section`
  max-height: calc(50% - 31px);
  flex: 1;
  padding: 0 40px 40px;
`;
export const NoticeHeader = styled.div`
  display: flex;
  margin-bottom: 30px;
`;
export const ImageBox = styled.div`
  width: 42px;
  height: 42px;
  margin: 0 16px 0 0;
  border-radius: 8px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  overflow: hidden;

  ${({$detail}) => $detail && css`
    margin: 40px 16px 0 0;
  `};
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const InfoBox = styled.div`
  width: calc(100% - 60px);
`;
export const Title = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  overflow: hidden;
`;
export const InfoText = styled(RegularText)`
  color: ${theme.colors.fontDarkGrayColor};
`;
export const ContentBox = styled.div`
  line-height: 1.2;
  white-space: pre-wrap;
  word-break: break-all;
  max-height: calc(100% - 126px);
  margin-bottom: 30px;
  overflow-y: scroll;
`;
export const FileSection = styled.section``;
export const FileTitle = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.fontDarkGrayColor};
  margin-bottom: 12px;
`;
export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
`;
export const Line = styled.div`
  width: calc(100% - 80px);
  height: 1px;
  margin: 30px auto;
  background-color: ${theme.colors.ultraLightGrayBorder};
`;
export const Section = styled.section`
  min-height: ${({ $minHeight }) => $minHeight ? $minHeight : 'auto'};
  height: 100%;
  max-height: calc(50% - 30px);
  flex: 1;
  padding-bottom: 20px;
`;
export const Count = styled(RegularText)`
  padding: 10px 40px;
  background-color: ${theme.colors.whiteColor};
`;
export const List = styled.div`
  height: ${({ $height }) => $height};
  padding: 20px 0 40px;
  overflow-y: scroll;
  margin-bottom: 20px;
`;
export const MemoEmptyText = styled.div`
  margin: 0 40px;
  color: ${theme.colors.fontDarkGrayColor};
`;
