import { gql } from '@apollo/client';

export const CREATE_DID_MONITOR = gql`
    mutation CreateDidMonitor(
        $didTitle: String!
        $didMonitorType: monitorType
        $didMediaType: mediaType
        $didResUsed: Boolean
        $didTransmitType: transmitType
        $didResInfoLocation: resLocation
        $didMonitorRatio: String
        $didAttached: [Upload!]
    ) {
        createDidMonitor(
            did_title: $didTitle
            did_monitorType: $didMonitorType
            did_mediaType: $didMediaType
            did_resUsed: $didResUsed
            did_transmitType: $didTransmitType
            did_resInfoLocation: $didResInfoLocation
            did_monitorRatio: $didMonitorRatio
            did_attached: $didAttached
        )
    }
`;

export const UPDATE_DID_MONITOR = gql`
    mutation UpdateDidMonitor(
        $didId: Int!
        $didTitle: String!
        $didDoctorRoomExpression: String
        $didStandbyPersonExpression: String
        $didErColorUsed: Boolean
        $didHoldingColorUsed: Boolean
        $didErColor: String
        $didHoldingColor: String
        $didStandbyPersonFontsize: String
        $didCalledPersonFontsize: String
        $didCalledTextUsed: Boolean
        $didCalledVoiceUsed: Boolean
        $didMonitorType: monitorType
        $didMediaType: mediaType
        $didResUsed: Boolean
        $didTransmitType: transmitType
        $didResInfoLocation: resLocation
        $didMonitorRatio: String
        $didPatExpress1: patExpression
        $didPatExpRatio1: Int
        $didPatExpress2: patExpression
        $didPatExpRatio2: Int
        $didPatExpress3: patExpression
        $didPatExpRatio3: Int
        $didPatExpress4: patExpression
        $didPatExpRatio4: Int
        $didLowMsgUsed: Boolean
        $didNameMasking: Boolean
        $didDoctorRoomIsHorizontal: Boolean
        $didDoctorRoomIsDivide: Boolean
        $didResInfoTime: Int
        $didResInfoCycle: Int
        $didDoctorRoomMerge: Boolean
        $didDoctorRoomInfoUpdate: [doctorRoomInfo]
        $didAttached: [updateAttachedInfo]
        $didDeleteAttachedId: [Int]
    ) {
        updateDidMonitor(
            did_id: $didId
            did_title: $didTitle
            did_doctorRoomExpression: $didDoctorRoomExpression
            did_standbyPersonExpression: $didStandbyPersonExpression
            did_erColorUsed: $didErColorUsed
            did_holdingColorUsed: $didHoldingColorUsed
            did_erColor: $didErColor
            did_holdingColor: $didHoldingColor
            did_standbyPersonFontsize: $didStandbyPersonFontsize
            did_calledPersonFontsize: $didCalledPersonFontsize
            did_calledTextUsed: $didCalledTextUsed
            did_calledVoiceUsed: $didCalledVoiceUsed
            did_monitorType: $didMonitorType
            did_mediaType: $didMediaType
            did_resUsed: $didResUsed
            did_transmitType: $didTransmitType
            did_resInfoLocation: $didResInfoLocation
            did_monitorRatio: $didMonitorRatio
            did_patExpress1: $didPatExpress1
            did_patExpRatio1: $didPatExpRatio1
            did_patExpress2: $didPatExpress2
            did_patExpRatio2: $didPatExpRatio2
            did_patExpress3: $didPatExpress3
            did_patExpRatio3: $didPatExpRatio3
            did_patExpress4: $didPatExpress4
            did_patExpRatio4: $didPatExpRatio4
            did_lowMsgUsed: $didLowMsgUsed
            did_nameMasking: $didNameMasking
            did_doctorRoomIsHorizontal: $didDoctorRoomIsHorizontal
            did_doctorRoomIsDivide: $didDoctorRoomIsDivide
            did_resInfoTime: $didResInfoTime
            did_resInfoCycle: $didResInfoCycle
            did_doctorRoomMerge: $didDoctorRoomMerge
            did_doctorRoomInfoUpdate: $didDoctorRoomInfoUpdate
            did_attached: $didAttached
            did_deleteAttachedId: $didDeleteAttachedId
        )
    }
`;

export const DELETE_DID_MONITOR = gql`
    mutation DeleteDidMonitor($didId: Int!) {
        deleteDidMonitor(did_id: $didId)
    }
`;

export const CREATE_DID_LOW_MSG = gql`
    mutation CreateDidLowMsg($didId: Int!, $dlmNumber: Int, $dlmText: String) {
        createDidLowMsg(did_id: $didId, dlm_number: $dlmNumber, dlm_text: $dlmText)
    }
`;

export const DELETE_DID_LOW_MSG = gql`
    mutation DeleteDidLowMsg($dlmId: Int!) {
        deleteDidLowMsg(dlm_id: $dlmId)
    }
`;

export const UPDATE_TOTAL_DID_SAVE_MODE = gql`
    mutation UpdateTotalDidSaveMode($saveMode: Boolean) {
        updateTotalDidSaveMode(saveMode: $saveMode)
    }
`;

export const UPDATE_DID_SAVE_MODE = gql`
    mutation UpdateDidSaveMode($didId: Int!, $saveMode: Boolean) {
        updateDidSaveMode(did_id: $didId, saveMode: $saveMode)
    }
`;
