import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import theme from "../../styles/theme";

const Wrapper = styled.div`
	width: ${({ $width }) => $width ? `${$width}px` : 'auto'};
	display: flex;
	align-items: center;
	margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;
const Label = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Marker = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1px solid ${theme.colors.lightGrayBorder};
	background-color: ${theme.colors.lightGrayBgColor};

	${({ checked }) => checked && css`
		border: 1px solid ${theme.colors.activeBlue};
	`};
`;
const Checked = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	opacity: 0;
	transition: opacity 0.3s ease;

	${({ checked }) => checked && css`
		opacity: 1;
		background-color: ${theme.colors.activeBlue};
	`};
`;
const Input = styled.input`
	display: none;
`;
const Text = styled.div`
	color: ${({ $fontColor }) => $fontColor};
	padding-bottom: 2px;
	margin-left: 8px;
`;

const StyledRadio = ({
	width,
	fontColor = theme.colors.fontDarkGrayColor,
	margin,
	label,
	name,
	value,
	checked,
	onChange,
	dataValue = ''
}) => {
	return (
		<Wrapper $width={width} $margin={margin}>
			<Label>
				<Marker checked={value === checked}>
					<Checked checked={value === checked} />
				</Marker>
				<Input
					type="radio"
					name={name}
					value={value}
					onChange={onChange}
					data-value={dataValue}
				/>
				{label && <Text $fontColor={fontColor}>{label}</Text>}
			</Label>
		</Wrapper>
	);
};

export default memo(StyledRadio);
