import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${({ $flexDirection }) => $flexDirection};
    align-items: ${({ $alignItems }) => $alignItems};
    justify-content: ${({ $justifyContent }) => $justifyContent};
    margin: ${({ $margin }) => $margin};
    padding: ${({ $padding }) => $padding};
`;

const StyledFlexBox = ({
                           flexDirection = 'row',
                           alignItems = 'center',
                           justifyContent = 'flex-start',
                           margin = 0,
                           padding = 0,
                           children,
                       }) => {
    return (
        <Wrapper
            $flexDirection={flexDirection}
            $alignItems={alignItems}
            $justifyContent={justifyContent}
            $margin={margin}
            $padding={padding}
        >
            {children}
        </Wrapper>
    )
}

export default React.memo(StyledFlexBox);
