import React, {useCallback, useEffect, useRef, useState} from "react";
import {TableRow, TableCell, ImageBox, Image, DefaultText, EmphasisText} from "./insurance.style";
import tobeconImg from '../../../assets/images/tobecon.png';
import hiwebnetImg from '../../../assets/images/hiwebnet.png';
import dayjs from "dayjs";
import {insuranceChange} from "../../../pages/Insurance/insurance.lib";
import StyledIconButton from "../../styled/StyledIconButton";
import {useMutation} from "@apollo/client";
import {RESEND_INSURANCE_REQUEST} from "../../../graphql/Insurance/mutation";
import {errorMessage, handleBodyScroll} from "../../../utils/commons";
import {toast} from "react-toastify";
import StyledFlexBox from "../../styled/StyledFlexBox";

const InsuranceTableRow = ({ list, refetch, setSelectedId, setInsuranceModal, handleDeleteInsurance }) => {
    const buttonRef = useRef();
    const [insuranceImage, setInsuranceImage] = useState(tobeconImg);

    const [resendInsuranceRequest] = useMutation(RESEND_INSURANCE_REQUEST);

    // 처리내용 재요청
    const handleReRequestInsurance = useCallback(async () => {
        try {
            const { data } = await resendInsuranceRequest({
               variables: {
                   ihId: list.ih_id
               }
            });

            if (data.resendInsuranceRequest) {
                toast.info('해당 데이터를 다시 요청 했습니다.');
                await refetch();
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const handleOpenDetail = useCallback(e => {
        if (!buttonRef?.current?.contains(e.target)) {
            setInsuranceModal(true);
            setSelectedId(list.ih_id);
            handleBodyScroll('hidden');
        }
    }, []);

    useEffect(() => {
        switch (list.companyName) {
            case 'tobecon':
                setInsuranceImage(tobeconImg);
                break;
            case 'hiwebnet':
                setInsuranceImage(hiwebnetImg);
                break;
        }
    }, [list]);

    return (
        <TableRow onClick={handleOpenDetail}>
            <TableCell
                $minWidth={42}
                $width={3}
                $marginRight={30}>
                <ImageBox>
                    <Image src={insuranceImage} onError={e => e.target.src = ''} />
                </ImageBox>
            </TableCell>
            <TableCell
                $minWidth={136}
                $width={14}>
                <DefaultText>
                    {list?.createdAt ? dayjs(list?.createdAt).format('YYYY-MM-DD HH:mm') : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={90}
                $width={10}>
                <EmphasisText>
                    {list?.companyName ? insuranceChange(list?.companyName) : '-'}
                </EmphasisText>
            </TableCell>
            <TableCell
                $minWidth={180}
                $width={17}>
                <DefaultText>
                    요청번호 {list?.reqNumber || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={340}
                $width={38}>
                <DefaultText>
                    #{list?.tobePatno || '-'}
                </DefaultText>
                <DefaultText>
                    {list?.status ? insuranceChange(list?.status) : '-'}&nbsp;:&nbsp;
                    {list?.recentlyHistory ? list?.recentlyHistory : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={120}
                $width={12}
                $marginRight={60}>
                <DefaultText>
                    {list?.tobeClaimDate ? dayjs(list?.tobeClaimDate).format('YYYY-MM-DD HH:mm') : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                ref={buttonRef}
                $minWidth={34}
                $width={4}
                $marginRight='0'>
                <StyledFlexBox justifyContent='flex-end'>
                    {list.status === 'fail' && (
                        <StyledIconButton
                            REFRESH
                            topRightRadius={0}
                            bottomRightRadius={0}
                            onClick={handleReRequestInsurance}
                        />
                    )}
                    <StyledIconButton
                        DELETE
                        topLeftRadius={list.status === 'fail' ? 0 : 6}
                        bottomLeftRadius={list.status === 'fail' ? 0 : 6}
                        margin='0 0 0 -1px'
                        onClick={() => handleDeleteInsurance(list?.ih_id)}
                    />
                </StyledFlexBox>
            </TableCell>
        </TableRow>
    )
}

export default InsuranceTableRow;
