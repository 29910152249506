import styled from "styled-components";
import theme from "../../styles/theme";

export const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SortBox = styled.div`
  display: flex;
`;
export const PatientList = styled.div`
  min-height: calc(100vh - 360px);
  padding: 0 40px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
