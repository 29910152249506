import React, {useCallback, useEffect, useRef} from "react";
import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";
import StyledButton from "../../styled/StyledButton";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: -100;
  background: rgba(256, 256, 256, 0.6);
  transition: 0.25s;
  opacity: 0;

  ${({$visible}) => $visible && css`
    opacity: 1;
    z-index: 101;
  `};
`;
const ModalContents = styled.div`
  width: 400px;
  height: 274px;
  padding: 40px;
  border-radius: 20px;
  position: relative;
  background-color: ${theme.colors.whiteColor};
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
`;
const Title = styled(BoldText)`
  text-align: center;
  margin-bottom: 30px;
`;
const Text = styled(RegularText)`
    line-height: 1.6;
`;

const HelpModal = ({helpModal, setHelpModal}) => {
    const bgRef = useRef(null);
    const modalRef = useRef(null);

    // 모달 밖 클릭 시 닫기
    const handleClickOutside = useCallback(e => {
        if (
            helpModal &&
            bgRef?.current?.contains(e.target) &&
            !modalRef?.current?.contains(e.target)
        ) {
            setHelpModal(false);
        }
    }, [helpModal, bgRef, modalRef]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    return (
        <Wrapper ref={bgRef} $visible={helpModal}>
            <ModalContents ref={modalRef}>
                <Title>도움말</Title>
                <Text>
                    DID모니터 솔루션에서 모니터 1대는 기본 제공이며 <br/>
                    추가 1대 이용시 월 5,000원의 요금이 부과됩니다.
                </Text>
                <StyledButton
                    title='확인'
                    width={320}
                    margin='40px 0 0'
                    onClick={() => setHelpModal(false)}
                />
            </ModalContents>
        </Wrapper>
    )
}

export default React.memo(HelpModal);
