import React from 'react';
import styled from 'styled-components';
import theme from "../../styles/theme";
import {IoEllipsisHorizontalSharp, IoTrashOutline} from "react-icons/io5";
import {BiPencil} from "react-icons/bi";
import {darken} from "polished";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
const HoverIcon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  border: ${({$border}) => $border ? $border : 'none'};

  &:hover {
    background-color: ${theme.colors.ultraLightGrayBorder};
  }
`;
const ButtonBox = styled.div`
  width: 120px;
  padding: 4px;
  border-radius: 8px;
  position: absolute;
  z-index: -10;
  visibility: hidden;
  opacity: 0;
  bottom: ${({ $bottom }) => $bottom ? $bottom : -88}px;
  right: 0;
  background-color: ${theme.colors.whiteColor};
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  transition: .25s;

  ${Wrapper}:hover & {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
`;
const Icon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconButton = styled.button`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &.delete {
    color: ${theme.colors.activeRed};
  }

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;
const IconButtonText = styled.span`
  margin-left: 6px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background-color: ${theme.colors.ultraLightGrayBorder};
`;

const HoverMenu = ({
                       NO_DELETE,
                       margin = 0,
                       border,
                       handleModifyComment,
                       handleDeleteComment,
                   }) => {

    if (NO_DELETE) {
        return (
            <Wrapper $margin={margin}>
                <HoverIcon $border={border}>
                    <IoEllipsisHorizontalSharp fontSize={18}/>
                </HoverIcon>
                <ButtonBox $bottom={-46}>
                    <IconButton className='delete' onClick={handleDeleteComment}>
                        <Icon>
                            <IoTrashOutline fontSize={18}/>
                        </Icon>
                        <IconButtonText>삭제하기</IconButtonText>
                    </IconButton>
                </ButtonBox>
            </Wrapper>
        );
    }

    return (
        <Wrapper $margin={margin}>
            <HoverIcon $border={border}>
                <IoEllipsisHorizontalSharp fontSize={18}/>
            </HoverIcon>
            <ButtonBox>
                <IconButton onClick={handleModifyComment}>
                    <Icon>
                        <BiPencil fontSize={18}/>
                    </Icon>
                    <IconButtonText>수정하기</IconButtonText>
                </IconButton>
                <Line/>
                <IconButton className='delete' onClick={handleDeleteComment}>
                    <Icon>
                        <IoTrashOutline fontSize={18}/>
                    </Icon>
                    <IconButtonText>삭제하기</IconButtonText>
                </IconButton>
            </ButtonBox>
        </Wrapper>
    );
}

export default HoverMenu;
