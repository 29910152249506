import React from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
    height: 100%;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.whiteColor};
`;
const Header = styled.header`
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const LogoTitle = styled.div`
    font-size: 19px;
    margin-bottom: 8px;
`;
const Contents = styled.section`
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const LargeText = styled.div`
    font-size: 30px;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 30px;
`;
const GrayText = styled.div`
    text-align: center;
    line-height: 1.4;
    margin-bottom: 60px;
`;
const HomeButton = styled.button`
    width: 134px;
    height: 46px;
    font-size: 16px;
    font-family: 'AppleSDGothicNeoBold';
    color: ${({ theme }) => theme.colors.whiteColor};
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.activeBlue};

    &:hover {
        background-color: ${({ theme }) => lighten(0.1, theme.colors.activeBlue)};
    }

    &:active {
        background-color: ${({ theme }) => darken(0.1, theme.colors.activeBlue)};
    }
`;
const PrevPageButton = styled.button`
    width: 134px;
    height: 46px;
    font-size: 16px;
    font-family: 'AppleSDGothicNeoBold';
    color: ${({ theme }) => theme.colors.fontGrayColor};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.whiteColor};

    &:hover {
        background-color: ${({ theme }) => theme.colors.whiteHoverColor};
    }

    &:active {
        background-color: ${({ theme }) => darken(0.1, theme.colors.whiteHoverColor)};
    }
`;

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <Header>
                <LogoTitle>닥터히포</LogoTitle>
            </Header>
            <Contents>
                {/*<Image src={image404} />*/}
                <LargeText>
                    페이지를 찾을 수<br />
                    없습니다
                </LargeText>
                <GrayText>
                    페이지의 주소가 잘못 입력되었거나, <br />
                    변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다. <br />
                    입력하신 페이지 주소를 다시 한번 확인해주세요.
                </GrayText>

                <HomeButton onClick={() => navigate('/home')}>홈</HomeButton>
                <PrevPageButton onClick={() => navigate(-1)}>이전 페이지</PrevPageButton>
            </Contents>
        </Wrapper>
    );
};

export default ErrorPage;
