import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import {
    IoSearchOutline,
    IoAddOutline,
    IoCloseOutline,
    IoSunnyOutline,
    IoMoon,
    IoListSharp,
    IoSwapVerticalOutline,
    IoCalendarClearOutline,
    IoReloadOutline,
    IoCallSharp,
    IoMailOutline,
    IoEllipsisHorizontalSharp
} from 'react-icons/io5';
import {BsPencil, BsQuestionLg,} from 'react-icons/bs';
import {BiCalendarWeek, BiCalendar} from 'react-icons/bi';
import {darken, lighten} from "polished";

const Wrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({$margin}) => $margin};
  border-top-right-radius: ${({$topRightRadius}) => $topRightRadius}px;
  border-top-left-radius: ${({$topLeftRadius}) => $topLeftRadius}px;
  border-bottom-right-radius: ${({$bottomRightRadius}) => $bottomRightRadius}px;
  border-bottom-left-radius: ${({$bottomLeftRadius}) => $bottomLeftRadius}px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
  transition: .25s;
  background-color: ${({$bgColor}) => $bgColor};

  &:hover {
    background-color: ${({$bgColor}) => $bgColor === '#FFFFFF'
            ? theme.colors.whiteHoverColor
            : lighten(0.02, $bgColor)};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;

const StyledIconButton = ({
                              SEARCH,
                              PLUS,
                              QUESTION,
                              UPDATE,
                              DELETE,
                              POWER_SAVING,
                              LIST,
                              CALENDAR,
                              CALENDAR_MONTH,
                              CALENDAR_WEEK,
                              SORT,
                              REFRESH,
                              PHONE,
                              MAIL,
                              MENU,
                              isPoserSaving, // DID 전원
                              margin = 0,
                              topRightRadius = 6,
                              topLeftRadius = 6,
                              bottomRightRadius = 6,
                              bottomLeftRadius = 6,
                              bgColor = theme.colors.whiteColor,

                              className,
                              onClick,
                          }) => {

    return (
        <Wrapper
            className={className}
            $margin={margin}
            $topRightRadius={topRightRadius}
            $topLeftRadius={topLeftRadius}
            $bottomRightRadius={bottomRightRadius}
            $bottomLeftRadius={bottomLeftRadius}
            $bgColor={bgColor}
            onClick={onClick}>
            {PLUS && <IoAddOutline fontSize={20}/>}
            {SEARCH && <IoSearchOutline fontSize={19}/>}
            {QUESTION && <BsQuestionLg fontSize={19}/>}
            {UPDATE && <BsPencil fontSize={12}/>}
            {DELETE && <IoCloseOutline color={theme.colors.activeOrange} fontSize={20}/>}
            {POWER_SAVING && (
                isPoserSaving
                    ? <IoSunnyOutline fontSize={15}/>
                    : <IoMoon fontSize={14}/>
            )}
            {LIST && <IoListSharp fontSize={16}/>}
            {CALENDAR && <IoCalendarClearOutline fontSize={16}/>}
            {CALENDAR_MONTH && <BiCalendar fontSize={16}/>}
            {CALENDAR_WEEK && <BiCalendarWeek fontSize={16}/>}
            {SORT && <IoSwapVerticalOutline fontSize={16}/>}
            {REFRESH && <IoReloadOutline fontSize={16}/>}

            {PHONE && <IoCallSharp fontSize={16}/>}
            {MAIL && <IoMailOutline fontSize={16}/>}
            {MENU && <IoEllipsisHorizontalSharp fontSize={16}/>}
        </Wrapper>
    );
}

export default React.memo(StyledIconButton);
