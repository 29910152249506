import React, {useCallback, useEffect, useState} from 'react';
import { IoAlertCircleOutline } from 'react-icons/io5';
import ModalBackground from "../../layout/ModalBackground";
import StyledInput from "../../styled/StyledInput";
import StyledCalendar from "../../styled/StyledCalendar";
import {BlueText, Line, ManagerModalContents, Section} from "./manager.style";
import StyledSelect from "../../styled/StyledSelect";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_RANK} from "../../../graphql/Common/query";
import StyledButton from "../../styled/StyledButton";
import {CHECK_USER_EMAIL, CREATE_USER, UPDATE_USER} from "../../../graphql/Manager/mutation";
import {managerValidCheck} from "../../../pages/Manager/manager.lib";
import {toast} from "react-toastify";
import theme from "../../../styles/theme";
import LoaderBackground from "../../share/LoaderBackground";
import Loader from "../../share/Loader";
import {autoHyphen, removeHyphen} from "../../../utils/commons";

const ManagerModal = ({ managerModal, setManagerModal, selectedUser, setSelectedUser, orgOption, userRefetch }) => {
    const [rankOption, setRankOption] = useState([]);
    const [inputs, setInputs] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        rank: '',
        org: '',
        job: '',
    });
    const [birthday, setBirthday] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const { data: rankData, loading: rankLoading } = useQuery(SEE_RANK);

    const [createUser] = useMutation(CREATE_USER);
    const [updateUser] = useMutation(UPDATE_USER);
    const [checkUserEmail] = useMutation(CHECK_USER_EMAIL);

    const handleClose = useCallback(() => {
        setManagerModal(false);
        setInputs({
            name: '',
            phoneNumber: '',
            email: '',
            rank: '',
            org: '',
            job: '',
        });
        setBirthday(new Date());
        setTimeout(() => setSelectedUser(null),300);
    }, []);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        if (name === 'phoneNumber') {
            setInputs({
                ...inputs,
                [name]: autoHyphen(value)
            });
        } else {
            setInputs({
                ...inputs,
                [name]: value
            });
        }
    }, [inputs]);

    const handleUpdate = useCallback(async () => {
        if (managerValidCheck(inputs)) return;

        try {
            setLoading(true);
            const { data } = await updateUser({
                variables: {
                    userId: selectedUser?.user_id,
                    name: inputs.name,
                    email: inputs.email,
                    rank: inputs.rank || rankOption[0] || '',
                    birthday: birthday,
                    cellphone: removeHyphen(inputs.phoneNumber),
                    org: inputs.org,
                    job: inputs.job,
                }
            });

            if (data?.updateUser) {
                toast.info('사용자를 수정했습니다.');
                await userRefetch();
                handleClose();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('사용자 등록에 실패했습니다.');
                    break;
                case 'err_01':
                    toast.error('이미 사용중인 이메일입니다.');
                    break;
            }
        } finally {
            setLoading(false);
        }
    }, [inputs, birthday, selectedUser]);

    const handleSave = useCallback(async () => {
        if (managerValidCheck(inputs)) return;

        try {
            const { data: checkEmailResult } = await checkUserEmail({
                variables: {
                    email: inputs.email
                }
            });

            if (checkEmailResult?.checkUserEmail && !window.confirm('이미 가입이 되어있는 이메일 입니다.\n해당 병원으로 다시 가입하시겠습니까?')) {
                return;
            }

            setLoading(true);
            const { data } = await createUser({
                variables: {
                    name: inputs.name,
                    email: inputs.email,
                    rank: inputs.rank,
                    birthday: birthday,
                    cellphone: removeHyphen(inputs.phoneNumber),
                    org: inputs.org,
                    job: inputs.job,
                }
            });

            if (data?.createUser) {
                toast.info('이메일로 임시비밀번호를 발송했습니다.');
                await userRefetch();
                handleClose();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('사용자 등록에 실패했습니다.');
                    break;
                case 'err_01':
                    toast.error('이미 사용중인 이메일입니다.');
                    break;
            }
        } finally {
            setLoading(false);
        }
    }, [inputs, birthday]);

    useEffect(() => {
        if (selectedUser) {
            setInputs({
                name: selectedUser?.user_name,
                phoneNumber: autoHyphen(selectedUser?.user_cellphone),
                email: selectedUser?.user_email,
                rank: selectedUser?.user_rank,
                org: selectedUser?.user_org,
                job: selectedUser?.user_job,
            });
            setBirthday(selectedUser?.user_birthday ? new Date(selectedUser?.user_birthday) : new Date());
        }
    }, [selectedUser]);

    useEffect(() => {
        if (!rankLoading && rankData) {
            const tmpRankOption = rankData?.seeRank?.rankList?.map(rank => rank.rank_name);
            setRankOption(tmpRankOption);
            setInputs({
                ...inputs,
                rank: tmpRankOption[0]
            });
        }
    }, [rankLoading, rankData]);

    return (
        <ModalBackground
            title={`사용자 ${selectedUser ? '수정' : '추가'}하기`}
            width={1040}
            height={722}
            visible={managerModal}
            onClose={handleClose}>
            <ManagerModalContents>
                {loading && (
                    <LoaderBackground>
                        <Loader />
                    </LoaderBackground>
                )}
                <Section>
                    <StyledInput
                        ASTERISK
                        title="이름"
                        name="name"
                        value={inputs.name}
                        onChange={onChange}
                        placeholder="이름을 입력해주세요."
                    />
                    <StyledCalendar
                        SELECT
                        BORDER
                        COLUMN
                        title="생년월일"
                        margin="20px 0"
                        dateFormat="yyyy-MM-dd"
                        value={birthday}
                        onChange={value => setBirthday(value)}
                    />
                    <StyledInput
                        ASTERISK
                        title="휴대폰번호"
                        name="phoneNumber"
                        value={inputs.phoneNumber}
                        onChange={onChange}
                        maxLength={13}
                        placeholder="휴대폰번호를 입력해주세요."
                    />
                    <StyledInput
                        ASTERISK
                        title="메일"
                        margin="20px 0 0"
                        name="email"
                        value={inputs.email}
                        onChange={onChange}
                        placeholder="메일을 입력해주세요."
                        disabled={!!selectedUser}
                    />
                    {!selectedUser && (
                        <BlueText>
                            <IoAlertCircleOutline fontSize={18} />&nbsp;
                            해당 메일로 초대장이 발송됩니다.
                        </BlueText>
                    )}
                    <StyledButton
                        title={selectedUser ? '수정하기' : '작성하기'}
                        width={440}
                        margin='30px 0 0'
                        bgColor={selectedUser ? theme.colors.activeOrange : theme.colors.activeBlue}
                        onClick={selectedUser ? handleUpdate : handleSave}
                    />
                </Section>
                <Line />
                <Section>
                    <StyledSelect
                        title="조직"
                        width={440}
                        borderRadius={4}
                        name="org"
                        value={inputs.org}
                        onChange={onChange}
                        options={orgOption}
                    />
                    <StyledSelect
                        title="직책"
                        width={440}
                        borderRadius={4}
                        margin="20px 0"
                        name="rank"
                        value={inputs.rank}
                        onChange={onChange}
                        options={rankOption}
                    />
                    <StyledInput
                        title="직무"
                        width={440}
                        borderRadius={4}
                        name="job"
                        value={inputs.job}
                        onChange={onChange}
                        placeholder="직무를 입력해주세요."
                    />
                </Section>
            </ManagerModalContents>
        </ModalBackground>
    );
}

export default ManagerModal;
