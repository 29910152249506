import React, {useCallback, useContext, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import SettingModalHeader from "./SettingModalHeader";
import {BoldText, RegularText} from "../../styled/StyledText";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledButton from "../../styled/StyledButton";
import {useMutation} from "@apollo/client";
import {REQUEST_USER_EMAIL_AUTH_CODE, UPDATE_USER_EMAIL} from "../../../graphql/Common/mutation";
import {errorMessage, isEmail} from "../../../utils/commons";
import {toast} from "react-toastify";
import Loader from "../../share/Loader";
import {AuthContext} from "../../../context/AuthContext";
import LoaderBackground from "../../share/LoaderBackground";

const Wrapper = styled.div`
  width: 100%;
  height: 720px;
  padding: 40px;
  position: absolute;
  top: 0;
  right: -100%;
  border-radius: 20px;
  background-color: ${theme.colors.whiteColor};
  transition: .25s;

  ${({$visible}) => $visible && css`
    right: 0;
  `};
`;
const SubTitle = styled(BoldText)`
  font-size: 16px;
  margin: 52px 0 30px;
`;
const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
  margin-bottom: 8px;
`;
const Input = styled.input`
  height: 50px;
  padding: 16px 12px;
  flex: 1;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
  background-color: ${({$bgColor}) => $bgColor};
`;
const AuthButton = styled.button`
  width: 94px;
  height: 50px;
  margin-left: 12px;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
`;

const UpdateEmailModal = ({ updateEmail, setUpdateEmail, onClose }) => {
    const { userLogout } = useContext(AuthContext);
    const [inputs, setInputs] = useState({
        email: '',
        authCode: '',
    });
    const [loading, setLoading] = useState(false);

    const [requestUserEmailAuthCode] = useMutation(REQUEST_USER_EMAIL_AUTH_CODE);
    const [updateUserEmail] = useMutation(UPDATE_USER_EMAIL);

    const handleGoBack = useCallback(() => {
        setUpdateEmail(false);
    }, []);

    const handleClose = useCallback(() => {
        onClose();
        setInputs({
            email: '',
            authCode: '',
        });
    }, [onClose]);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleAuthEmail = useCallback(async () => {
        if (inputs.email === '' || !isEmail(inputs.email)) {
            toast.error('이메일 형식에 맞게 입력해주세요.');
            return;
        }

        setLoading(true);
        try {
            const { data } = await requestUserEmailAuthCode({
                variables: {
                    email: inputs.email
                }
            });

            if (data.requestUserEmailAuthCode) {
                setLoading(false);
                toast.info('인증코드를 전송했습니다.');
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
                    break;
                case 'err_01':
                    toast.error('탈퇴한 사용자입니다.\n플랫폼 관리자에게 문의해주세요.');
                    break;
                case 'err_02':
                    toast.error('이미 사용중인 이메일입니다.\n다른 이메일을 입력해주세요.');
                    break;
            }
        } finally {
            setLoading(false);
        }
    }, [inputs]);

    const handleChangeEmail = useCallback(async () => {
        if (inputs.email === '') {
            toast.error('이메일을 입력해주세요.');
            return;
        }
        if (inputs.authCode === '') {
            toast.error('인증코드를 입력해주세요.');
            return;
        }
        try {
            const { data } = await updateUserEmail({
                variables: {
                    authCode: inputs.authCode,
                    email: inputs.email
                }
            });

            if (data.updateUserEmail) {
                alert('이메일을 변경했습니다.\n다시 로그인을 해주세요.');
                handleClose();
                userLogout();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [inputs]);

    useEffect(() => {
        if (!updateEmail) {
            setInputs({
                email: '',
                authCode: '',
            });
        }
    }, [updateEmail]);

    return (
        <Wrapper $visible={updateEmail}>
            {loading && (
                <LoaderBackground>
                    <Loader />
                </LoaderBackground>
            )}
            <SettingModalHeader
                title='이메일 변경'
                onGoBack={handleGoBack}
                onClose={handleClose}
            />
            <SubTitle>이메일을 변경하고 인증해주세요.</SubTitle>
            <GrayText>변경할 이메일을 입력하세요.</GrayText>
            <StyledFlexBox>
                <Input
                    $bgColor={theme.colors.ultraLightGrayBorder}
                    name='email'
                    value={inputs.email}
                    onChange={onChange}
                    placeholder='이메일을 입력해주세요.'
                />
                <AuthButton onClick={handleAuthEmail}>인증하기</AuthButton>
            </StyledFlexBox>
            <StyledFlexBox margin='20px 0 12px'>
                <Input
                    name='authCode'
                    value={inputs.authCode}
                    onChange={onChange}
                    placeholder='인증코드 입력'
                />
            </StyledFlexBox>
            <GrayText>인증코드는 30분이 지나면 만료됩니다.</GrayText>

            <StyledButton
                title='변경하기'
                width={440}
                margin='272px 0 0'
                onClick={handleChangeEmail}
            />
        </Wrapper>
    )
}

export default UpdateEmailModal;
