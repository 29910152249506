import React, {createContext, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Wrapper} from "./service.style";
import PageHeader from "../../components/layout/PageHeader";
import {useLocation, useNavigate, Outlet} from "react-router-dom";
import QnaModal from "../../components/feature/Service/QNA/QnaModal";
import {useReactiveVar} from "@apollo/client";
import {userVar} from "../../store";
import {toast} from "react-toastify";

export const ServiceContext = createContext(null);

const Service = () => {
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const location = useLocation();

    const [activeTab, setActiveTab] = useState('고객센터');
    const [qnaModal, setQnaModal] = useState(false);
    const qnaRefetch = useRef();

    const handleTabClick = useCallback(type => {
        switch (type) {
            case '고객센터':
                navigate('/service/center');
                break;
            case '문의하기':
                navigate('/service/qna');
                break;
            case '긴급공지':
                navigate('/service/notice');
                break;
        }
        setActiveTab(type);
    }, []);

    useEffect(() => {
        switch (location.pathname) {
            case '/service/center':
                setActiveTab('고객센터');
                break;
            case '/service/qna':
                setActiveTab('문의하기');
                break;
            case '/service/notice':
                setActiveTab('긴급공지');
                break;
        }
    }, [location]);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.cs) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);


    return (
        <ServiceContext.Provider value={{
            qnaModal,
            setQnaModal,
            qnaRefetch
        }}>
            <PageHeader
                SERVICE
                TAB_BUTTON
                guideLine='고객센터'
                tabButton={['고객센터', '문의하기', '긴급공지']}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
                onClick={() => setQnaModal(true)}>
            </PageHeader>

            <Outlet/>

            <QnaModal
                qnaModal={qnaModal}
                setQnaModal={setQnaModal}
                qnaRefetch={qnaRefetch.current}
            />
        </ServiceContext.Provider>
    )
}

export default Service;
