import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import theme from "../../../styles/theme";
import {IoVideocamOutline, IoImageOutline, IoQrCodeOutline} from 'react-icons/io5';
import {BoldText, RegularText} from "../../styled/StyledText";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledIconButton from "../../styled/StyledIconButton";
import {resLocationChange} from "../../../pages/DID/did.lib";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {UPDATE_DID_SAVE_MODE} from "../../../graphql/DID/mutation";

const Wrapper = styled.div`
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
const TableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  margin: ${({$margin}) => $margin ? $margin : '0 40px 0 0'};
  text-overflow: ellipsis;
  overflow: hidden;
`;
const TypeIcon = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
const MonitorText = styled(BoldText)`
  font-size: 16px;
`;
const HoverText = styled(RegularText)`
  cursor: pointer;

  &:hover {
    color: ${theme.colors.activeBlue};
  }
`;

const DIDTableRow = ({
                         list,
                         handleSetupMonitor,
                         handleDeleteMonitor,
                        refetch
                     }) => {
    const [isPowerSaving, setIsPowerSaving] = useState(null);
    const [updateDIDSaveMode] = useMutation(UPDATE_DID_SAVE_MODE);

    const handlePowerSaving = useCallback(async () => {
        try {
            const { data } = await updateDIDSaveMode({
                variables: {
                    didId: list?.did_id,
                    saveMode: !isPowerSaving
                }
            });

            if (data.updateDidSaveMode) {
                toast.info(`모니터${isPowerSaving ? ' 절전모드를 해제했습니다.' : '를 절전모드로 변경했습니다.'}`);
                await refetch();
            }
        } catch (e) {
            toast.error(e.message);
        }
    }, [isPowerSaving, list]);

    useEffect(() => {
        setIsPowerSaving(list?.did_saveMode);
    }, [list]);

    return (
        <Wrapper>
            <TableCell
                $minWidth={42}
                $width={6}
                $margin='0 30px 0 0'>
                <TypeIcon>
                    {list?.did_mediaType === 'video' && (
                        <IoVideocamOutline fontSize={23}/>
                    )}
                    {list?.did_mediaType === 'image' && (
                        <IoImageOutline fontSize={21}/>
                    )}
                </TypeIcon>
            </TableCell>
            <TableCell
                $minWidth={182}
                $width={16}>
                <MonitorText>
                    {list?.did_title || '-'}
                </MonitorText>
            </TableCell>
            <TableCell
                $minWidth={72}
                $width={8}>
                <RegularText>
                    {list?.did_monitorType === 'horizontal'
                        ? '가로모니터'
                        : '세로모니터'
                    }
                </RegularText>
            </TableCell>
            <TableCell
                $minWidth={100}
                $width={12}>
                <StyledFlexBox alignItems='flex-start' flexDirection='column'>
                    <RegularText $margin='0 0 4px'>
                        {list?.did_mediaType === 'video'
                            ? '영상재생용'
                            : '이미지노출용'
                        }
                    </RegularText>
                </StyledFlexBox>
            </TableCell>
            <TableCell
                $minWidth={108}
                $width={12}>
                <StyledFlexBox alignItems='flex-start' flexDirection='column'>
                    <RegularText $margin='0 0 4px'>
                        {list?.did_resUsed
                            ? '예약 정보 사용'
                            : '예약 정보 미사용'
                        }
                    </RegularText>
                    <RegularText>
                        {list?.did_transmitType === 'sometimes'
                            ? '일시노출'
                            : '상시노출'
                        }
                    </RegularText>
                </StyledFlexBox>
            </TableCell>
            <TableCell
                $minWidth={124}
                $width={14}>
                <StyledFlexBox alignItems='flex-start' flexDirection='column'>
                    <RegularText $margin='0 0 4px'>
                        {list?.did_resUsed &&
                            `예약 정보 ${resLocationChange(list?.did_resInfoLocation)} 노출`
                        }
                    </RegularText>
                    <RegularText>
                        비율 {list?.did_monitorRatio}
                    </RegularText>
                </StyledFlexBox>
            </TableCell>
            <TableCell
                $minWidth={250}
                $width={18}>
                <HoverText onClick={() => window.open(`${process.env.REACT_APP_DID_SERVER_URL}/${list?.did_uniqueId}`)}>
                    {process.env.REACT_APP_DID_SERVER_URL}/{list?.did_uniqueId}
                </HoverText>
            </TableCell>
            <TableCell
                $minWidth={42}
                $width={6}>
                <IoQrCodeOutline fontSize={22}/>
            </TableCell>

            <TableCell
                $minWidth={120}
                $width={10}
                $margin={0}>
                <StyledFlexBox justifyContent='flex-end'>
                    <StyledIconButton
                        POWER_SAVING
                        isPoserSaving={!isPowerSaving}
                        topRightRadius={0}
                        bottomRightRadius={0}
                        onClick={handlePowerSaving}
                    />
                    <StyledIconButton
                        UPDATE
                        topRightRadius={0}
                        topLeftRadius={0}
                        bottomRightRadius={0}
                        bottomLeftRadius={0}
                        margin='0 0 0 -1px'
                        onClick={() => handleSetupMonitor(list)}
                    />
                    <StyledIconButton
                        DELETE
                        topLeftRadius={0}
                        bottomLeftRadius={0}
                        margin='0 0 0 -1px'
                        onClick={() => handleDeleteMonitor(list.did_id)}
                    />
                </StyledFlexBox>
            </TableCell>
        </Wrapper>
    )
}

export default React.memo(DIDTableRow);
