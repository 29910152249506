import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import RightSlideModal from "../../../layout/RightSlideModal";
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_FAQ_LIKE} from "../../../../graphql/Service/mutation";
import {errorMessage, handleBodyScroll} from "../../../../utils/commons";
import {SEE_FAQ_DETAIL} from "../../../../graphql/Service/query";
import logo from '../../../../assets/images/logo.png';
import Loader from "../../../share/Loader";
import QuestionDetailContents from "../QuestionDetailContents";

const FaqDetailModal = ({
                            selectedId,
                            setSelectedId,
                            faqDetailModal,
                            setFaqDetailModal,
                            faqRefetch,
                        }) => {
    const [questionData, setQuestionData] = useState({
        questionId: null,
        creatorId: null,
        creatorName: '',
        creatorRank: '',
        creatorImage: '',
        createdAt: new Date(),
        isAnswered: false,
        title: '',
        contents: '',
        isOpen: false
    });
    const [answerData, setAnswerData] = useState({
        answerId: null,
        creatorId: null,
        creatorName: '',
        creatorRank: '',
        creatorImage: '',
        createdAt: new Date(),
        answer: '',
    });

    const [seeFaqDetail, { data, loading, refetch }] = useLazyQuery(SEE_FAQ_DETAIL);

    const [createFaqLike] = useMutation(CREATE_FAQ_LIKE);

    const handleClose = useCallback(() => {
        setFaqDetailModal(false);
        setSelectedId(null);
        setQuestionData({
            questionId: null,
            creatorId: null,
            creatorName: '',
            creatorRank: '',
            creatorImage: '',
            createdAt: new Date(),
            isAnswered: false,
            title: '',
            contents: '',
            isOpen: false
        });
        setAnswerData({
            answerId: null,
            creatorId: null,
            creatorName: '',
            creatorRank: '',
            creatorImage: '',
            createdAt: new Date(),
            answer: ''
        });
        handleBodyScroll('initial');
    }, []);

    const handleFaqLike = useCallback(async () => {
        try {
            const { data } = await createFaqLike({
                variables: {
                    faqId: selectedId
                }
            });

            if (data.createFAQLike) {
                await refetch();
                await faqRefetch();
            }
        } catch (e) {
            errorMessage(e.message);
            ;
        }
    }, [selectedId]);

    useLayoutEffect(() => {
        (async () => {
            if (selectedId) {
                try {
                    await seeFaqDetail({
                        variables: {
                            faqId: selectedId
                        }
                    });
                } catch (e) {
                    errorMessage(e.message);
                }
            }
        })();
    }, [selectedId]);

    useEffect(() => {
        if (!loading && data) {
            const tmpData = data?.seeFAQDetail;

            setQuestionData({
                questionId: tmpData?.faq_id,
                creatorId: tmpData?.faq_creatorId,
                creatorName: tmpData?.faq_creatorName,
                creatorRank: tmpData?.faq_creatorRank,
                creatorImage: logo,
                createdAt: tmpData?.faq_createdAt,
                isAnswered: true,
                title: tmpData?.faq_question,
                contents: '',
                // contents: tmpData?.faq_answer,
                isOpen: true,
            });
            setAnswerData({
                answerId: null,
                creatorId: tmpData?.faq_creatorId,
                creatorName: tmpData?.faq_creatorName,
                creatorRank: tmpData?.faq_creatorRank,
                creatorImage: logo,
                createdAt: tmpData?.faq_createdAt,
                answer: tmpData?.faq_answer
            });
        }
    }, [loading, data, selectedId]);

    return (
        <RightSlideModal
            LIKE
            title='자주 묻는 질문'
            visible={faqDetailModal}
            onClose={handleClose}
            isLiked={data?.seeFAQDetail?.faq_myLikeStatus}
            onClickLike={handleFaqLike}>
            {loading
                ? <Loader/>
                : <QuestionDetailContents
                    questionData={questionData}
                    answerData={answerData}
                />
            }
        </RightSlideModal>
    )
}

export default FaqDetailModal;
