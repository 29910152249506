import React, {useCallback, useContext, useState} from 'react';
import FullPageModalBackground from "../../../layout/FullPageModalBackground";
import theme from "../../../../styles/theme";
import {SettingContext} from "../../../../pages/Setting/setting";
import {BlueText, DefaultText, EmphasisText, Line} from "./setting.modal.style";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledRadio from "../../../styled/StyledRadio";
import {handleBodyScroll} from "../../../../utils/commons";

const PlatformInterWorkingModal = () => {
    const { siteMap, handleCloseSiteMap } = useContext(SettingContext);

    const [type, setType] = useState('hippocrates');

    const onChange = useCallback(e => {
        setType(e.target.value);
    }, [type]);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
        handleBodyScroll('initial');
    }, []);

    const handleSave = useCallback(() => {
        handleClose();
        // setPlatformInterWorkingModal(false);
    }, []);

    return (
        <FullPageModalBackground
            buttonTitle='EMR 연동 설정 완료'
            guideLine='설정 > EMR 연동 설정'
            modalTitle='EMR 연동 설정'
            visible={siteMap === 'EMR 연동 설정'}
            onClose={handleClose}
            onClickButton={handleSave}
            buttonColor={theme.colors.activeBlue}>
            <EmphasisText $margin='30px 0 20px'>설정 분야 선택</EmphasisText>
            <StyledFlexBox>
                <StyledRadio
                    label='히포크라테스 (Hippocrates)'
                    name='emr_type'
                    value='hippocrates'
                    checked={type}
                    onChange={onChange}
                />
                <StyledRadio
                    label='원클릭 (OneClick)'
                    margin='0 0 0 26px'
                    name='emr_type'
                    value='oneClick'
                    checked={type}
                    onChange={onChange}
                />
            </StyledFlexBox>
            <Line />
            <BlueText>EMR 연동 설정하기</BlueText>
            <DefaultText $margin='20px 0'>현재 논의중입니다.</DefaultText>
        </FullPageModalBackground>
    );
}

export default PlatformInterWorkingModal;
