import React, {useCallback, useEffect, useState} from "react";
import {
    CalendarTable,
    CalendarTableHeader,
    CalendarWrapper,
    WeekTableRow,
    WeekTableData,
    PlatformIcon, BrieflyInfoText, TimeText, ScheduleBox
} from "./appointment.style";
import dayjs from "dayjs";
import Loader from "../../share/Loader";
import theme from "../../../styles/theme";
import {getPlatformImg, getStatusBgColor} from "../../../pages/Appointment/appointment.lib";

const AppointmentCalendarWeek = ({
                                     weekLoading,
                                     weekData,
                                     scheduleTime,
                                     setCalendarSelectedDate,
                                     setCalendarDetailModal
                                 }) => {
    const [timeList, setTimeList] = useState([]);

    const handleClickDetail = useCallback(date => {
        setCalendarSelectedDate(date);
        setCalendarDetailModal(true);
    }, [setCalendarSelectedDate, setCalendarDetailModal]);

    useEffect(() => {
        let fastestHour = Infinity;
        let slowestHour = -Infinity;

        scheduleTime.forEach(schedule => {
            const minInArray = Math.min(...schedule.operationTime);
            const maxInArray = Math.max(...schedule.operationTime);

            fastestHour = Math.min(fastestHour, minInArray); // 운영 시간 중 가장 빠른 시간
            slowestHour = Math.max(slowestHour, maxInArray);  // 운영 시간 중 가장 느린 시간
        });

        let tmpTimeList = Array.from({length: slowestHour - fastestHour + 1}, (_, i) => {
            return Array.from({length: 8}, (_, j) => {
                let hour = i + fastestHour;

                if (j === 0) {
                    return {
                        hour: hour,
                        time: `${hour < 10 ? `0${hour}` : hour}:00`,
                    }
                }

                return {
                    hour: hour,
                    time: `${hour < 10 ? `0${hour}` : hour}:00`,
                    date: dayjs(`${weekData[j - 1]?.year}-${weekData[j - 1]?.month}-${weekData[j - 1]?.date}`).format('YYYY-MM-DD')
                }
            })
        });

        setTimeList(tmpTimeList)
    }, [scheduleTime]);

    if (weekLoading) return <Loader/>;

    return (
        <CalendarWrapper>
            <CalendarTable>
                <thead>
                <tr>
                    {['', ...weekData]?.map((list, index) => {
                        const date = dayjs(`${list?.year}-${list?.month}-${list.date}`).format('ddd');
                        return (
                            <CalendarTableHeader key={`${index}-${list.date}`}>
                                {list?.date && (
                                    <>
                                        {list?.date}&nbsp;({date})
                                    </>
                                )}
                            </CalendarTableHeader>
                        )
                    })}
                </tr>
                </thead>
                <tbody>
                {timeList?.map((column, rowIndex) => {
                    return (
                        <WeekTableRow key={`${rowIndex}-hour`}>
                            {column?.map((time, columnIndex) => {
                                if (columnIndex === 0) {
                                    return (
                                        <WeekTableData key={`${columnIndex}-date`}>
                                            {time?.time}<br/>
                                            {time?.date}
                                        </WeekTableData>
                                    );
                                }

                                // 각 열의 년, 월, 일
                                let year = weekData[columnIndex - 1].year;
                                let month = weekData[columnIndex - 1].month;
                                let date = weekData[columnIndex - 1].date;

                                return (
                                    <WeekTableData key={`${columnIndex}-date`} onClick={() => handleClickDetail(dayjs(`${year}-${month}-${date}`))}>
                                        {weekData[columnIndex - 1]?.byDateReservationInfo.map(list => {
                                            const startHour = parseInt(list?.re_time?.split(':')?.[0], 10);

                                            if (time.hour === startHour) {
                                                return (
                                                    <ScheduleBox
                                                        key={list.re_id}
                                                        $border={list.re_status === 'complete' ? `1px solid ${theme.colors.ultraLightGrayBorder}` : 'none'}
                                                        $bgColor={getStatusBgColor(list.re_status)}>
                                                        <PlatformIcon $bgImg={getPlatformImg(list.re_platform)}/>
                                                        <BrieflyInfoText>
                                                            <TimeText>
                                                                {list.re_time || ''}
                                                            </TimeText>
                                                            {list.re_patientName}&nbsp;-&nbsp;
                                                            {list.re_LCategory}
                                                        </BrieflyInfoText>
                                                    </ScheduleBox>
                                                );
                                            }
                                        })}
                                    </WeekTableData>
                                );
                            })}
                        </WeekTableRow>
                    );
                })}
                </tbody>
            </CalendarTable>
        </CalendarWrapper>
    )
}

export default AppointmentCalendarWeek;
