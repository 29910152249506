import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100% - 160px);
`;
export const SortingBox = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: space-between;
`;
export const FilterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const SortBox = styled.div`
  display: flex;
`;
