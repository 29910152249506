import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {FilterBox, InsuranceList, SortBox} from "./insurance.style";
import PageHeader from "../../components/layout/PageHeader";
import StyledSelect from "../../components/styled/StyledSelect";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {SEE_INSURANCE_REQUEST_HISTORY} from "../../graphql/Insurance/query";
import {insuranceChange} from "./insurance.lib";
import StyledIconButton from "../../components/styled/StyledIconButton";
import Loader from "../../components/share/Loader";
import InsuranceTableRow from "../../components/feature/Insurance/InsuranceTableRow";
import SearchModal from "../../components/share/SearchModal";
import PageNation from "../../components/layout/PageNation";
import InsuranceModal from "../../components/feature/Insurance/InsuranceModal";
import {DELETE_INSURANCE_REQUEST} from "../../graphql/Insurance/mutation";
import {errorMessage} from "../../utils/commons";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {userVar} from "../../store";

const Insurance = () => {
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const [insurance, setInsurance] = useState('전체');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortType, setSortType] = useState('desc');
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [searchModal, setSearchModal] = useState(false);
    const [insuranceModal, setInsuranceModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const { data, loading, refetch } = useQuery(SEE_INSURANCE_REQUEST_HISTORY, {
        variables: {
            searchTerm: searchTerm,
            company: insuranceChange(insurance),
            orderBy: sortType,
            take: take,
            cursor: cursor,
        }
    });
    const [deleteInsuranceRequest] = useMutation(DELETE_INSURANCE_REQUEST);

    const onChange = useCallback(e => setInsurance(e.target.value), []);

    const handleSort = useCallback(() => {
        if (sortType === 'desc') {
            setSortType('asc');
        } else {
            setSortType('desc');
        }
    }, [sortType]);

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    const handleDeleteInsurance = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const { data } = await deleteInsuranceRequest({
                variables: {
                    ihId: id
                }
            });

            if (data.deleteInsuranceRequest) {
                toast.info('청구요청기록을 삭제했습니다.');
                await refetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setCursor(0);
            setPage(1);
        }
    }, [searchTerm]);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.insurance) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);

    return (
        <>
            <PageHeader
                title='청구요청기록'
                guideLine='실손보험청구'>
                <FilterBox>
                    <StyledSelect
                        NO_BORDER
                        width={200}
                        title='외부보험사 : '
                        value={insurance}
                        onChange={onChange}
                        options={['전체', '투비콘', '하이웹넷']}
                    />
                    <SortBox>
                        <StyledIconButton // 검색
                            SEARCH
                            margin='0 12px'
                            onClick={() => setSearchModal(true)}
                        />
                        <StyledIconButton // 리스트 보기
                            SORT
                            onClick={handleSort}
                        />
                    </SortBox>
                </FilterBox>
            </PageHeader>
            <InsuranceList>
                {loading
                    ? <Loader />
                    : data?.seeInsuranceRequestHistory?.insuranceList?.map((list, index) => (
                        <InsuranceTableRow
                            key={`${index}-${list?.ih_id}-insurance-list`}
                            list={list}
                            refetch={refetch}
                            setSelectedId={setSelectedId}
                            setInsuranceModal={setInsuranceModal}
                            handleDeleteInsurance={handleDeleteInsurance}
                        />
                    ))
                }
            </InsuranceList>
            <PageNation
                totalLength={data?.seeInsuranceRequestHistory?.totalLength}
                take={take}
                page={page}
                setTake={setTake}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <SearchModal // 검색하기 모달
                searchModal={searchModal}
                setSearchModal={setSearchModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />

            <InsuranceModal
                insuranceModal={insuranceModal}
                setInsuranceModal={setInsuranceModal}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
            />
        </>
    );
}

export default Insurance;
