import React, {useCallback, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import SettingModalHeader from "./SettingModalHeader";
import {BoldText, RegularText} from "../../styled/StyledText";
import StyledPasswordInput from "../../styled/StyledPasswordInput";
import {LoginForm} from "../../../pages/Login/login.styles";
import StyledButton from "../../styled/StyledButton";
import {toast} from "react-toastify";
import {errorMessage, passwordLengthCheck, passwordNumberCheck} from "../../../utils/commons";
import {useMutation} from "@apollo/client";
import {UPDATE_USER_PASSWORD} from "../../../graphql/Common/mutation";

const Wrapper = styled.div`
  width: 100%;
  height: 720px;
  padding: 40px;
  position: absolute;
  top: 0;
  right: -100%;
  border-radius: 20px;
  background-color: ${theme.colors.whiteColor};
  transition: .25s;

  ${({$visible}) => $visible && css`
    right: 0;
  `};
`;
const SubTitle = styled(BoldText)`
  font-size: 16px;
  margin: 52px 0 30px;
`;
const Form = styled.form``;

const UpdatePasswordModal = ({updatePassword, setUpdatePassword, onClose}) => {
    const [inputs, setInputs] = useState({
        currentPassword: '',
        newPassword1: '',
        newPassword2: ''
    });

    const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);

    const handleGoBack = useCallback(() => {
        setUpdatePassword(false);
    }, []);

    const handleClose = useCallback(() => {
        onClose();
        setInputs({
            currentPassword: '',
            newPassword1: '',
            newPassword2: ''
        });
    }, [onClose]);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleChangePassword = useCallback(async (e) => {
        e.preventDefault();

        if (
            inputs.currentPassword === '' ||
            inputs.newPassword1 === '' ||
            inputs.newPassword2 === ''
        ) {
            toast.error('비밀번호를 입력해주세요.');
            return;
        }
        if (inputs.newPassword1 !== inputs.newPassword2) {
            toast.error('비밀번호가 일치하지 않습니다.');
            return;
        }
        if (!passwordNumberCheck(inputs.newPassword2) || !passwordLengthCheck(inputs.newPassword2)) {
            toast.error('비밀번호는 8자리 이상 특수문자를 포함해주세요.');
            return;
        }

        try {
            const {data} = await updateUserPassword({
                variables: {
                    currentPassword: inputs.currentPassword,
                    newPassword: inputs.newPassword2
                }
            });

            if (data.updateUserPassword) {
                toast.info('비밀번호를 변경했습니다.');
                handleClose();
            }
        } catch (e) {
            toast.error(e.message);
            // errorMessage(e.message);
        }
    }, [inputs]);

    useEffect(() => {
        if (!updatePassword) {
            setInputs({
                currentPassword: '',
                newPassword1: '',
                newPassword2: ''
            });
        }
    }, [updatePassword]);

    return (
        <Wrapper $visible={updatePassword}>
            <SettingModalHeader
                title='비밀번호 변경'
                onGoBack={handleGoBack}
                onClose={handleClose}
            />
            <SubTitle>비밀번호는 8자리 이상 특수문자를 포함해주세요.</SubTitle>
            <Form onSubmit={handleChangePassword}>

                <RegularText>현재 비밀번호</RegularText>
                <StyledPasswordInput
                    width={440}
                    margin='12px 0 28px'
                    bgColor={theme.colors.ultraLightGrayBorder}
                    name='currentPassword'
                    value={inputs.currentPassword}
                    onChange={onChange}
                    placeholder='현재 비밀번호 입력'
                />

                <RegularText $margin='30px 0 8px'>새 비밀번호</RegularText>
                <StyledPasswordInput
                    width={440}
                    bgColor={theme.colors.ultraLightGrayBorder}
                    name='newPassword1'
                    value={inputs.newPassword1}
                    onChange={onChange}
                    placeholder='새 비밀번호'
                />
                <StyledPasswordInput
                    width={440}
                    margin='20px 0 196px'
                    bgColor={theme.colors.ultraLightGrayBorder}
                    name='newPassword2'
                    value={inputs.newPassword2}
                    onChange={onChange}
                    placeholder='새 비밀번호 확인'
                />
                <StyledButton
                    title='변경하기'
                    width={440}
                    onClick={handleChangePassword}
                />
            </Form>
        </Wrapper>
    )
}

export default UpdatePasswordModal;
