import React, {useCallback, useContext, useMemo} from 'react';
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import dayjs from "dayjs";
import {getCalendarColumns, useCalendar} from "../../../hooks/useCalendar";
import {
    AddIcon, BrieflyInfoText, CalendarDataBody,
    CalendarDataHead,
    CalendarTable,
    CalendarTableData,
    CalendarTableHeader,
    CalendarTableRow, CalendarWrapper, CountText, DateText, NumberBox, PlatformIcon, TimeText
} from "../Appointment/appointment.style";
import {ScheduleBox} from './dayOff.style';
import theme from "../../../styles/theme";
import {IoAddOutline} from "react-icons/io5";
import Loader from "../../share/Loader";

const DayOffCalendarMonth = ({date, loading, dayOffList = []}) => {
    const selectedDate = useMemo(() => dayjs(date), [date]);
    const {getDay} = useCalendar(selectedDate);
    const columns = getCalendarColumns(selectedDate);

    if (loading) return <Loader/>;

    return (
        <CalendarWrapper>
            <CalendarTable>
                <thead>
                <tr>
                    {getDay.map((day, index) => (
                        <CalendarTableHeader key={`${index}-${day}`}>{day}</CalendarTableHeader>
                    ))}
                </tr>
                </thead>
                <tbody>
                {columns?.map((column, rowIndex) => (
                    <CalendarTableRow key={rowIndex}>
                        {column.map((date, columnIndex) => {
                            const schedule = dayOffList.find(item => (item.date === date.get('date') && item.month === date.get('month') + 1)); // 각 날짜 일정
                            const fontColor = date.get('month') + 1 === selectedDate.get('month') + 1
                                ? theme.colors.blackColor
                                : theme.colors.fontGrayColor;
                            const bgColor = (getDay[columnIndex] === '일' || getDay[columnIndex] === '토')
                                ? theme.colors.lightWhiteBgColor
                                : theme.colors.whiteColor;

                            return (
                                <CalendarTableData
                                    key={columnIndex}
                                    $cursor='default'
                                    $bgColor={bgColor}
                                >
                                    <CalendarDataHead>
                                        <NumberBox>
                                            <DateText
                                                $fontColor={fontColor}
                                                $isToday={date.isToday()}>
                                                {dayjs(date).get('date')}
                                            </DateText>
                                        </NumberBox>
                                    </CalendarDataHead>
                                    <CalendarDataBody>
                                        {schedule?.workType === 'work' && (
                                            <ScheduleBox>
                                                {schedule.workStartHour < 10 ? `0${schedule.workStartHour}` : schedule.workStartHour}:
                                                {schedule.workStartMin < 10 ? `0${schedule.workStartMin}` : schedule.workStartMin}
                                                &nbsp;~&nbsp;
                                                {schedule.workEndHour < 10 ? `0${schedule.workEndHour}` : schedule.workEndHour}:
                                                {schedule.workEndMin < 10 ? `0${schedule.workEndMin}` : schedule.workEndMin} 운영
                                            </ScheduleBox>
                                        )}
                                        {(schedule?.workType === 'offDay') && (
                                            <ScheduleBox $bgColor={theme.colors.ultraLightGrayBorder}>
                                                {schedule?.offType === 'fix' && '고정휴무'}
                                                {schedule?.offType === 'temp' && '임시휴무'}

                                                {schedule?.workType === 'offDay' && (
                                                    <>
                                                        &nbsp;(
                                                        {schedule?.reType === 'month' && '매월반복'}
                                                        {schedule?.reType === 'none' && '반복없음'}
                                                        {schedule?.reType === 'week' && '매주반복'}
                                                        )
                                                    </>
                                                )}
                                            </ScheduleBox>
                                        )}
                                    </CalendarDataBody>
                                </CalendarTableData>
                            );
                        })}
                    </CalendarTableRow>
                ))}
                </tbody>
            </CalendarTable>
        </CalendarWrapper>
    );
}

export default DayOffCalendarMonth;
