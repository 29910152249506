import { gql } from '@apollo/client';

export const CREATE_FAQ_LIKE = gql`
    mutation CreateFAQLike($faqId: Int!) {
        createFAQLike(faq_id: $faqId)
    }
`;

export const CREATE_ONE_ON_ONE = gql`
    mutation CreateOneOnOne(
        $oneqTitle: String
        $oneqText: String
        $oneqPublicPrivate: Boolean
        $attached: [Upload]
    ) {
        createOneOnOne(
            oneq_title: $oneqTitle
            oneq_text: $oneqText
            oneq_publicPrivate: $oneqPublicPrivate
            attached: $attached
        )
    }
`;

export const DELETE_ONE_ON_ONE = gql`
    mutation DeleteOneOnOne($oneqId: Int!) {
        deleteOneOnOne(oneq_id: $oneqId)
    }
`;

export const CREATE_ONE_ON_ONE_COMMENT = gql`
    mutation CreateOneOnOneComment($oneqId: Int!, $text: String) {
        createOneOnOneComment(oneq_id: $oneqId, text: $text)
    }
`;

export const UPDATE_ONE_ON_ONE_COMMENT = gql`
    mutation UpdateOneOnOneComment($oneAnId: Int!, $oneAnText: String) {
        updateOneOnOneComment(oneAn_id: $oneAnId, oneAn_text: $oneAnText)
    }
`;

export const DELETE_ONE_ON_ONE_COMMENT = gql`
    mutation DeleteOneOnOneComment($oneAnId: Int!) {
        deleteOneOnOneComment(oneAn_id: $oneAnId)
    }
`;
