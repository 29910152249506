import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100% - 160px);
`;
export const SortingBox = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: space-between;
`;
export const ManagerTableList = styled.div`
  min-height: calc(100% - 200px);
  padding: 0 40px;
`;
export const SortingIconBox = styled.div`
  display: flex;
  align-content: center;
`;
