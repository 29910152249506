import React, {useCallback, useContext, useState} from 'react';
import RightSlideModal from "../../../layout/RightSlideModal";
import {SettingContext} from "../../../../pages/Setting/setting";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_RES_ALIM_TEMPLATE} from "../../../../graphql/Common/query";
import {
    CategoryList,
    DefaultText,
    DeleteIcon,
    EmphasisText,
    InputForm, TemplateContents, TemplateList, TemplateRow,
    Wrapper
} from "./setting.modal.style";
import StyledInput from "../../../styled/StyledInput";
import {toast} from "react-toastify";
import {errorMessage} from "../../../../utils/commons";
import StyledTextarea from "../../../styled/StyledTextarea";
import theme from "../../../../styles/theme";
import Loader from "../../../share/Loader";
import {IoCloseOutline} from "react-icons/io5";
import {CREATE_RES_ALIM_TEMPLATE, DELETE_RES_ALIM_TEMPLATE} from "../../../../graphql/Manager/mutation";
import StyledFlexBox from "../../../styled/StyledFlexBox";

const NotificationSettingModal = () => {
    const {siteMap, handleCloseSiteMap} = useContext(SettingContext);
    const [input, setInput] = useState('');
    const [content, setContent] = useState('');

    const {data, loading, refetch} = useQuery(SEE_RES_ALIM_TEMPLATE, {
        fetchPolicy: 'network-only'
    });
    const [createResAlimTemplate] = useMutation(CREATE_RES_ALIM_TEMPLATE);
    const [deleteResAlimTemplate] = useMutation(DELETE_RES_ALIM_TEMPLATE);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
        setInput('');
    }, []);

    const handleDelete = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const { data } = await deleteResAlimTemplate({
                variables: {
                    ratId: id
                }
            });

            if (data.deleteResAlimTemplate) {
                toast.info('알림 템플릿을 삭제했습니다.');
                await refetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const handleCreate = useCallback(async () => {
        if (input === '') {
            toast.info('템플릿 제목을 입력해주세요.');
            return;
        }
        if (content === '') {
            toast.info('템플릿 내용을 입력해주세요.');
            return;
        }

        try {
            const { data } = await createResAlimTemplate({
                variables: {
                    title: input,
                    text: content
                }
            });

            if (data.createResAlimTemplate) {
                toast.info('알림 템플릿을 추가했습니다.');
                await refetch();
                setInput('');
                setContent('');
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [input, content]);

    return (
        <RightSlideModal
            title='알림 템플릿 설정'
            visible={siteMap === '예약시 알림 템플릿 설정'}
            onClose={handleClose}>
            <Wrapper>
                <InputForm>
                    <EmphasisText $margin='0 0 22px'>알림 발송 설정</EmphasisText>

                    <StyledInput
                        ROW
                        CATEGORY
                        title='템플릿 제목'
                        height={38}
                        margin='20px 0'
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder='템플릿 제목을 입력하세요.'
                        onClick={handleCreate}
                    />

                    <StyledTextarea
                        ROW
                        title='템플릿 내용'
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        placeholder='템플릿 내용을 입력하세요.'
                    />
                    <TemplateList>
                        {loading
                            ? <Loader/>
                            : data?.seeResAlimTemplate?.templateList?.map((item, index) => (
                                <TemplateRow key={`${index}-${item.rat_id}-list`}>
                                    <StyledFlexBox justifyContent="space-between" margin="0 0 12px">
                                        <EmphasisText>{item.rat_title || ''}</EmphasisText>
                                        <DeleteIcon>
                                            <IoCloseOutline
                                                color={theme.colors.fontLightGrayColor}
                                                fontSize={20}
                                                onClick={() => handleDelete(item.rat_id)}
                                            />
                                        </DeleteIcon>
                                    </StyledFlexBox>
                                    <TemplateContents>{item.rat_text || ''}</TemplateContents>

                                </TemplateRow>
                            ))
                        }
                    </TemplateList>
                </InputForm>

            </Wrapper>

        </RightSlideModal>
    );
}

export default NotificationSettingModal;
