import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ClinicSchedule, PlatformIcon, TimeScheduleBox, TimeText, HoverWrapper} from "./appointment.style";
import theme from "../../../styles/theme";
import {getPlatformImg, getStatusBgColor} from "../../../pages/Appointment/appointment.lib";
import HoverMenu from "../../share/HoverMenu";
import StyledIconButton from "../../styled/StyledIconButton";
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import ConfirmModal from "../../share/ConfirmModal";

const ClinicScheduleList = ({
                                schedule,
                                handleOpenStatusConfirm,
                                handleClose,
                                dayRefetch,
                            }) => {
    const {handleUpdateAppointment, handleDeleteAppointment} = useContext(AppointmentContext);

    const [top, setTop] = useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const confirmModalRef = useRef();
    const hoverRef = useRef();
    const hoverMenuRef = useRef();

    const onMouseOver = useCallback(e => {
        if (e.target === hoverRef.current) {
            const result = Math.floor(e.target.getBoundingClientRect().top - 20);
            setTop(result);
        }
    }, [hoverRef]);

    const statusChangeCallback = useCallback(async () => {
        handleClose();
        await dayRefetch(); // 달력 상세보기
    }, [dayRefetch, handleClose]);

    const handleStatusConfirm = useCallback(e => {
        if (!hoverMenuRef?.current?.contains(e.target)) {
            handleOpenStatusConfirm(schedule);
        }
    }, [schedule, hoverMenuRef]);

    useEffect(() => {
        return () => setTop(null);
    }, []);

    return (
        <ClinicSchedule
            ref={hoverRef}
            key={schedule.re_id}
            $border={schedule.re_status === 'complete' ? `1px solid ${theme.colors.ultraLightGrayBorder}` : 'none'}
            $bgColor={getStatusBgColor(schedule.re_status)}
            onMouseOver={onMouseOver}
            onClick={handleStatusConfirm}>
            <PlatformIcon $bgImg={getPlatformImg(schedule.re_platform)}/>
            <TimeText>
                {schedule.re_time || ''}
            </TimeText>
            {schedule.re_patientName || ''}&nbsp;-&nbsp;
            {schedule.re_doctorRoomName || ''} / {schedule.re_LCategory || ''}

            <HoverWrapper $top={top} ref={hoverMenuRef}>
                <HoverMenu
                    handleModifyComment={() => handleUpdateAppointment(schedule.re_id, handleClose)}
                    handleDeleteComment={() => setDeleteConfirmModal(true)}
                />
                <ConfirmModal
                    title='예약 내역 삭제'
                    subTitle={`예약 내역을 삭제할까요? 삭제된 예약 내역은 모두\n기록에서 삭제되며, 복구할 수 없습니다.`}
                    confirmTitle='삭제하기'
                    ref={confirmModalRef}
                    visible={deleteConfirmModal}
                    handleConfirm={() => handleDeleteAppointment(schedule.re_id, statusChangeCallback)}
                    handleCancel={() => setDeleteConfirmModal(false)}
                />
            </HoverWrapper>
        </ClinicSchedule>
    );
}

const TimeSchedule = ({
                          isSchedule,
                          dayData = [],
                          fullTime,
                          hour,
                          handleClose,
                          dayRefetch,
                          handleOpenStatusConfirm,
                      }) => {
    const {setAppointmentModal, setTimeSelect} = useContext(AppointmentContext);

    const handleCreateAppointment = useCallback(() => { // 시간으로 예약 생성
        setTimeSelect(fullTime);
        setAppointmentModal(true);
        handleClose();
    }, [fullTime]);

    return (
        <TimeScheduleBox
            $isEmpty={!isSchedule}
            $isSchedule={isSchedule}
        >
            {dayData.map(schedule => {
                let startHour = schedule?.re_time?.split(':')?.[0];

                if (startHour === hour) {
                    return (
                        <ClinicScheduleList
                            key={schedule.re_id}
                            schedule={schedule}
                            handleOpenStatusConfirm={handleOpenStatusConfirm}
                            handleClose={handleClose}
                            dayRefetch={dayRefetch}
                        />
                    );
                }
            })}
            <StyledIconButton
                PLUS
                className='plus-schedule'
                onClick={handleCreateAppointment}
            />
            {/*{!isSchedule*/}
            {/*    ? ( // 예약 일정이 없을 경우 추가 버튼*/}
            {/*        <StyledIconButton*/}
            {/*            PLUS*/}
            {/*            className='plus-schedule'*/}
            {/*            onClick={handleCreateAppointment}*/}
            {/*        />*/}
            {/*    ) : (*/}
            {/*        dayData.map(schedule => {*/}
            {/*            let startHour = schedule?.re_time?.split(':')?.[0];*/}

            {/*            if (startHour === hour) {*/}
            {/*                return (*/}
            {/*                    <ClinicScheduleList*/}
            {/*                        key={schedule.re_id}*/}
            {/*                        schedule={schedule}*/}
            {/*                        handleOpenStatusConfirm={handleOpenStatusConfirm}*/}
            {/*                        handleClose={handleClose}*/}
            {/*                        dayRefetch={dayRefetch}*/}
            {/*                    />*/}
            {/*                );*/}
            {/*            }*/}
            {/*        })*/}
            {/*    )*/}
            {/*}*/}
        </TimeScheduleBox>
    );
}

export default TimeSchedule;
