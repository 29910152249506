import React from "react";
import styled from "styled-components";
import {IoArrowBackOutline, IoClose} from 'react-icons/io5';
import {BoldText} from "../../styled/StyledText";
import theme from "../../../styles/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const BackButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled(BoldText)`
  flex: 1;
  margin-left: 20px;
`;
const CloseButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
`;

const SettingModalHeader = ({ title, onGoBack, onClose, }) => {
    return (
        <Wrapper>
            <BackButton onClick={onGoBack}>
                <IoArrowBackOutline fontSize={20} />
            </BackButton>
            <Title>
                {title}
            </Title>
            <CloseButton onClick={onClose}>
                <IoClose fontSize={24} />
            </CloseButton>
        </Wrapper>
    )
}

export default SettingModalHeader;
