import React from 'react';
import {BlueText, SiteList, SiteMapItem, SiteMapWrapper, Title, UnderLine} from "./setting.style";

const SiteMap = ({
                     imageText,
                     title,
                     siteMaps = [],
                     handleClickSiteMap,
                 }) => {

    return (
        <SiteMapWrapper $borderRight={title === '연동 설정' && 'none'}>
            <Title>
                {imageText}&nbsp;&nbsp;&nbsp;{title}
            </Title>
            <SiteList>
                {siteMaps.map((site, index) => (
                    <SiteMapItem key={`${index}-${site}`} onClick={() => handleClickSiteMap(site)}>
                        <BlueText>
                            {site}
                            <UnderLine />
                        </BlueText>
                    </SiteMapItem>
                ))}
            </SiteList>
        </SiteMapWrapper>
    );
}

export default SiteMap;
