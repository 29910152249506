// 특별 일정 추가 모달
import styled from "styled-components";
import {BoldText, RegularText} from "../../styled/StyledText";
import theme from "../../../styles/theme";
import {darken} from "polished";

export const Contents = styled.div`
  height: calc(100% - 102px);
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
`;
export const InputForm = styled.div`
  flex: 1;
`;
export const SubTitle = styled(BoldText)`
  font-size: 16px;
  margin: 30px 0 24px;
`;
export const DoubleInputBox = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
`;
export const DoubleInputTitle = styled(RegularText)`
  width: 134px !important;
`;
export const InputBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Input = styled.input`
  width: 45%;
  height: 38px;
  text-align: center;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;
`;
export const RowFlexBox = styled.div`
  display: flex;
`;
export const EmptyTitle = styled.div`
  width: 136px;
`;
export const OrangeText = styled(RegularText)`
  color: ${theme.colors.activeOrange};
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 0 12px;
`;
export const FileList = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 12px 20px;
`;
export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DetailModalContents = styled.div`
  display: flex;
  //position: relative;
`;
export const Section = styled.div`
  position: relative;
  flex: ${({ $flex }) => $flex ? $flex : 1};
`;
export const Line = styled.div`
  margin: 0 40px;

  &:after {
    content: '';
    width: 1px;
    height: 724px;
    top: -94px;
    position: absolute;
    background-color: ${theme.colors.ultraLightGrayBorder};
  };
`;
export const StatusView = styled.div`
  width: 72px;
  height: 30px;
  font-size: 14px;
  color: ${theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  right: 0;
  border-radius: 18px;
  background-color: ${({ $bgColor }) => $bgColor};
`;
export const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
  margin: ${({ $margin }) => $margin ? $margin : 0};
`;
export const CommentList = styled.div`
  height: 472px;
  margin: 30px 0 20px;
  overflow-y: scroll;
`;
export const HistoryBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;
export const ImageBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
export const CommentSubject = styled.div`
  display: flex;
`;
export const StrongText = styled(BoldText)`
  font-size: 16px;
`;
export const CommentContents = styled.div``;
export const CommentText = styled.div`
  margin-top: 8px;
`;
export const PreviewList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
  margin-top: 30px;
`;
export const HoverMenu = styled.div`
  height: 42px;
  padding: 4px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 2px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: ${theme.colors.whiteColor};
  transition: .25s;

  ${HistoryBox}:hover & {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
`;
export const IconButton = styled.button`
  height: 100%;
  color: ${theme.colors.activeRed};
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  
  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;
export const Icon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconButtonText = styled.span`
  margin-left: 6px;
`;
