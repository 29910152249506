import React from "react";
import styled from "styled-components";
import {MediumText, RegularText} from "./StyledText";
import theme from "../../styles/theme";
import {BsFolderFill} from 'react-icons/bs';
import {IoAddOutline} from 'react-icons/io5';

const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;
const Label = styled.label`
  height: 65px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 4px;
  cursor: pointer;
`;
const TextBox = styled.div`
  flex: 1;
  margin: 0 12px;
`;
const GrayText = styled(MediumText)`
  font-size: 14px;
  color: ${theme.colors.fontLightGrayColor};
  white-space: pre-wrap;
  word-break: break-all;
  margin-top: 6px;
`;
const Input = styled.input`
  display: none;
`;

const StyledFileUpload = ({
                              title = '첨부 파일',
                              accept = '*',
                              onChange,
                              placeholder = '정보 분류에 따라 이미지, 영상 파일을 추가할 수 있어요.',
                          }) => {
    return (
        <Wrapper>
            {title && (
                <RegularText>{title}</RegularText>
            )}
            <Label>
                <BsFolderFill fontSize={22} color={theme.colors.fontLightGrayColor}/>
                <TextBox>
                    <MediumText $fontSize={14}>첨부파일추가</MediumText>
                    <GrayText>{placeholder}</GrayText>
                </TextBox>
                <IoAddOutline fontSize={22} color={theme.colors.fontLightGrayColor}/>
                <Input
                    type='file'
                    multiple
                    accept={accept}
                    onChange={onChange}
                />
            </Label>
        </Wrapper>
    )
}

export default React.memo(StyledFileUpload);
