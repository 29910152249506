import styled from "styled-components";

export const ModalContents = styled.div`
  height: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;
export const InputForm = styled.div`
  flex: 1;
`;
export const Title = styled.div`
  margin-bottom: 30px;
`;
export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
