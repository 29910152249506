import React, {useCallback, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import SettingModalHeader from "./SettingModalHeader";
import ToggleSwitch from "../../share/ToggleSwitch";
import {useMutation, useReactiveVar} from "@apollo/client";
import {UPDATE_USER_ALIM} from "../../../graphql/Common/mutation";
import {BoldText, RegularText} from "../../styled/StyledText";
import StyledFlexBox from "../../styled/StyledFlexBox";
import {errorMessage} from "../../../utils/commons";
import {userVar} from "../../../store";

const Wrapper = styled.div`
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  right: -100%;
  border-radius: 20px;
  background-color: ${theme.colors.whiteColor};
  transition: .25s;

  ${({$visible}) => $visible && css`
    right: 0;
  `};
`;
const Header = styled.div`
  padding: 40px;
`;
const SubContents = styled.div`
  padding: 0 40px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 0 20px;
`;
const NotificationRow = styled.div`
  width: 100%;
  height: 82px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
const InfoBox = styled.div``;
const Title = styled(BoldText)`
  font-size: 16px;
`;
const AllOffButton = styled.button`
  font-size: 14px;
  font-family: 'AppleSDGothicNeoRegular';
  color: ${theme.colors.fontLightGrayColor};
  cursor: pointer;
`;
const GrayText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.fontLightGrayColor};
  margin-top: 6px;
`;

const UpdateNotificationModal = ({updateNotification, setUpdateNotification, onClose}) => {
    const user = useReactiveVar(userVar);
    const [checkbox, setCheckbox] = useState({
        userHnAlim: false,
        userPnAlim: false,
        userResAlim: false,
        userSpecialAlim: false
    });

    const [updateUserAlim] = useMutation(UPDATE_USER_ALIM);

    const handleToggleSwitch = useCallback(async e => {
        const {name} = e.target;

        try {
            const {data} = await updateUserAlim({
                variables: {
                    ...checkbox,
                    [name]: !checkbox[name]
                }
            });

            if (data.updateUserAlim) {
                setCheckbox({
                    ...checkbox,
                    [name]: !checkbox[name],
                });

                // refetch 필요
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [checkbox]);

    const handleAllOff = useCallback(async () => {
        try {
            const {data} = await updateUserAlim({
                variables: {
                    userHnAlim: false,
                    userPnAlim: false,
                    userResAlim: false,
                    userSpecialAlim: false
                }
            });

            if (data.updateUserAlim) {
                setCheckbox({
                    userHnAlim: false,
                    userPnAlim: false,
                    userResAlim: false,
                    userSpecialAlim: false
                });

                // refetch 필요
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const handleGoBack = useCallback(() => {
        setUpdateNotification(false);
    }, []);

    useEffect(() => {
        if (user) {
            setCheckbox({
                userHnAlim: user.user_hnAlim,
                userPnAlim: user.user_pnAlim,
                userResAlim: user.user_resAlim,
                userSpecialAlim: user.user_specialAlim
            });
        }
    }, [user]);

    return (
        <Wrapper $visible={updateNotification}>
            <Header>
                <SettingModalHeader
                    title='알림 설정'
                    onGoBack={handleGoBack}
                    onClose={onClose}
                />
            </Header>
            <SubContents>
                <Title>이메일 알림</Title>
                <AllOffButton onClick={handleAllOff}>모두 끄기</AllOffButton>
            </SubContents>

            <NotificationRow>
                <InfoBox>
                    <Title>사내 공지</Title>
                    <GrayText>사내 공지 알림을 받을 수 있어요.</GrayText>
                </InfoBox>
                <ToggleSwitch
                    name='userHnAlim'
                    value={checkbox.userHnAlim}
                    onChange={handleToggleSwitch}
                />
            </NotificationRow>
            <NotificationRow>
                <InfoBox>
                    <Title>플랫폼 공지</Title>
                    <GrayText>플랫폼 공지 알림을 받을 수 있어요.</GrayText>
                </InfoBox>
                <ToggleSwitch
                    name='userPnAlim'
                    value={checkbox.userPnAlim}
                    onChange={handleToggleSwitch}
                />
            </NotificationRow>
            <NotificationRow>
                <InfoBox>
                    <Title>예약 대기 알림</Title>
                    <GrayText>예약 대기 요청이 들어오는 알림을 받을 수 있어요.</GrayText>
                </InfoBox>
                <ToggleSwitch
                    name='userResAlim'
                    value={checkbox.userResAlim}
                    onChange={handleToggleSwitch}
                />
            </NotificationRow>
            <NotificationRow>
                <InfoBox>
                    <Title>병원 특별일정 알림</Title>
                    <GrayText>정해진 병원 운영 외 새로운 일정이 추가되면 알림을 받을 수 있어요.</GrayText>
                </InfoBox>
                <ToggleSwitch
                    name='userSpecialAlim'
                    value={checkbox.userSpecialAlim}
                    onChange={handleToggleSwitch}
                />
            </NotificationRow>


        </Wrapper>
    )
}

export default UpdateNotificationModal;
