import React, {forwardRef} from "react";
import {Section, Count, List, MemoEmptyText} from "./notice.styles";
import CommentBox from "../../../share/CommentBox";
import CommentInput from "../../../share/CommentInput";

const CommentList = forwardRef((
    {
        NO_COUNT,
        minHeight,
        listHeight = 'calc(100% - 120px)',
        placeholder = '댓글을 입력해주세요.',
        commentInputWidth,
        spareTop,
        input,
        setInput,
        onInputKeyPress,
        handleSaveComment,
        commentList = [],
        handleDeleteComment,
        handleModifyComment,
    },
    {scrollRef, inputRef}
) => {
    return (
        <Section $minHeight={minHeight}>
            {!NO_COUNT && <Count>{commentList.length || 0}개의 댓글</Count>}
            <List $height={listHeight}>
                {commentList.length === 0
                    ? <MemoEmptyText>등록된 내용이 없습니다.</MemoEmptyText>
                    : commentList.map(comment => (
                        <CommentBox
                            key={`${comment.commentId}-comment`}
                            spareTop={spareTop}
                            commentId={comment.commentId}
                            creatorId={comment.creatorId}
                            creator={comment.creator}
                            creatorRank={comment.creatorRank}
                            creatorImage={comment.creatorImage}
                            createDate={comment.createDate}
                            comment={comment.comment}
                            isAdmin={comment.isAdmin}
                            handleDeleteComment={handleDeleteComment}
                            handleModifyComment={handleModifyComment}
                        />))}
                <div ref={scrollRef}/>
            </List>
            <CommentInput
                width={commentInputWidth}
                input={input}
                setInput={setInput}
                onKeyPress={onInputKeyPress}
                handleSaveComment={handleSaveComment}
                placeholder={placeholder}
                ref={inputRef}
            />
        </Section>
    )
});

export default CommentList;
