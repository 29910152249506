import React from 'react';
import {DayBox, DayDataCell, DaySchedule, DefaultText} from "./setting.modal.style";
import {dayKorChange} from "../../../../pages/Setting/setting.lib";
import StyledIconButton from "../../../styled/StyledIconButton";
import StyledRadio from "../../../styled/StyledRadio";

const DayScheduleList = ({
                             id,
                             day,
                             startHour,
                             startMin,
                             endHour,
                             endMin,
                             lunchBreak,
                             lunchStartHour,
                             lunchStartMin,
                             lunchEndHour,
                             lunchEndMin,
                             handleDeleteSchedule,
                             selectedId,
                             onChangeRadio
                         }) => {
    return (
        <DaySchedule>
            <DayDataCell
                $minWidth={40}
                $width={3}
                $marginRight={10}>
                <StyledRadio
                    name='notification'
                    checked={selectedId}
                    value={id}
                    onChange={onChangeRadio}
                />
            </DayDataCell>
            <DayDataCell
                $minWidth={42}
                $width={3}
                $marginRight={30}>
                <DayBox>{dayKorChange(day)}</DayBox>
            </DayDataCell>
            <DayDataCell
                $column
                $minWidth={120}
                $width={27}>
                <DefaultText>운영시간</DefaultText>
                <DefaultText>
                    {startHour < 10 ? `0${startHour}` : startHour}:
                    {startMin < 10 ? `0${startMin}` : startMin}&nbsp;-&nbsp;
                    {endHour < 10 ? `0${endHour}` : endHour}:
                    {endMin < 10 ? `0${endMin}` : endMin}
                </DefaultText>
            </DayDataCell>
            <DayDataCell
                $minWidth={120}
                $width={25}>
                <DefaultText>
                    {lunchBreak ? '점심시간 휴진' : '점심시간 운영'}
                </DefaultText>
            </DayDataCell>
            <DayDataCell
                $column
                $minWidth={110}
                $width={70}>
                <DefaultText>점심시간</DefaultText>
                <DefaultText>
                    {lunchBreak ? (
                        <>
                            {lunchStartHour < 10 ? `0${lunchStartHour}` : lunchStartHour}:
                            {lunchStartMin < 10 ? `0${lunchStartMin}` : lunchStartMin}&nbsp;-&nbsp;
                            {lunchEndHour < 10 ? `0${lunchEndHour}` : lunchEndHour}:
                            {lunchEndMin < 10 ? `0${lunchEndMin}` : lunchEndMin}
                        </>
                    ) : '-'}
                </DefaultText>
            </DayDataCell>
            <DayDataCell
                $minWidth={34}
                $width={2}
                $marginRight='0'>
                <StyledIconButton
                    DELETE
                    onClick={() => handleDeleteSchedule(id)}
                />
            </DayDataCell>
        </DaySchedule>
    );
}

export default DayScheduleList;
