import styled, {css} from 'styled-components';
import theme from '../../../styles/theme';
import {RegularText} from '../../../components/styled/StyledText';
import selectBorderBottomArrow from '../../../assets/icons/selectBorderBottomArrow.svg';
import {darken} from 'polished';

export const HomeWrapper = styled.div`
  height: calc(100% - 160px);
  padding-bottom: 100px;
`;
export const StatsSection = styled.section`
  padding: 20px calc(100% / 5) 20px 40px;
  display: flex;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const InformationSection = styled.section`
  display: flex;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const NoticeBox = styled.div`
  padding: 30px 20px 30px 40px;
  flex: 4;
`;
export const TodayPatientBox = styled.div`
  flex: 1;
  padding: 30px 24px 30px 20px;
`;
export const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
`;
export const DetailViewButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  
  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;
export const PlatformSelectBox = styled.select`
  min-width: 140px;
  font-size: 24px;
  font-family: 'AppleSDGothicNeoBold';
  color: ${theme.colors.activeIndigo};
  position: relative;
  margin-right: 12px;
  background-image: url(${selectBorderBottomArrow});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 12px top 65%;
`;
export const ListTable = styled.div`
  height: 378px;
  overflow-y: scroll;
`;
export const PatientList = styled.div`
  padding: 20px 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PatientInfo = styled.div`
  
`;
export const StateBox = styled.div`
  display: flex;
`;
export const StateColor = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 12px;
  border-radius: 50%;
  background-color: ${({ $bgColor }) => $bgColor ? $bgColor : theme.colors.activeGreen};
`;
export const OperationScheduleSection = styled.section`
  padding: 30px 40px 40px;
`;
export const ScheduleFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const ArrowBox = styled.div`
  width: 20px;
  height: 20px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 4px;
  margin-right: 6px;
  cursor: pointer;
  
  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;
export const DateFilterBox = styled.div`
  height: 34px;
  display: flex;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;
`;
export const DateBox = styled.div`
  width: 34px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
  
  ${({ $active }) => $active && css`
    background-color: ${theme.colors.ultraLightGrayBorder};
  `};
`;
export const ChartSection = styled.section`
  padding: 30px 40px;
  border-top: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const ChartBox = styled.div`
  height: 500px;
  padding:30px 0;
  display: flex;
`;
