import React, {useCallback, useContext, useEffect, useState} from 'react';
import RightSlideModal from "../../../layout/RightSlideModal";
import {SettingContext} from "../../../../pages/Setting/setting";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_HOSPITAL_RES_ALIM_SET} from "../../../../graphql/Common/query";
import {
    ButtonBox,
    CategoryList,
    DefaultText,
    DeleteIcon,
    EmphasisText,
    InputForm, TemplateContents, TemplateList, TemplateRow,
    Wrapper
} from "./setting.modal.style";
import {toast} from "react-toastify";
import {errorMessage} from "../../../../utils/commons";
import theme from "../../../../styles/theme";
import Loader from "../../../share/Loader";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledRadio from "../../../styled/StyledRadio";
import StyledCheckbox from "../../../styled/StyledCheckbox";
import StyledButton from "../../../styled/StyledButton";
import {UPDATE_ALIM_SET} from "../../../../graphql/Setting/mutation";

const TemplateListRow = ({ item, index, selectedId, onChangeTemplate }) => {
    return (
        <TemplateRow key={`${index}-${item.rat_id}-list`}>
            <StyledFlexBox justifyContent="space-between" margin="0 0 14px">
                <StyledRadio
                    fontColor={theme.colors.blackColor}
                    label={item.rat_title}
                    name='templateList'
                    value={item.rat_id}
                    checked={selectedId}
                    onChange={onChangeTemplate}
                />
            </StyledFlexBox>
            <TemplateContents>{item.rat_text || ''}</TemplateContents>
        </TemplateRow>
    )
}

const NotificationDefaultSettingModal = () => {
    const {siteMap, handleCloseSiteMap} = useContext(SettingContext);
    const [type, setType] = useState('');
    const [checkbox, setCheckbox] = useState({
        time1: true,
        time2: false,
        time3: false,
        time4: false
    });
    const [selectedId, setSelectedId] = useState(null);

    const onChangeRadio = useCallback(e => {
        setType(e.target.value);
    }, []);

    const onChangeTemplate = useCallback(e => {
        setSelectedId(Number(e.target.value));
    }, []);

    const onChangeCheckbox = useCallback(e => { // checkbox onChange
        const {name, checked} = e.target;

        setCheckbox({
            ...checkbox,
            [name]: checked
        });
    }, [checkbox]);


    const {data, loading, refetch} = useQuery(SEE_HOSPITAL_RES_ALIM_SET, {
        fetchPolicy: 'network-only'
    });
    const [updateResAlimSet] = useMutation(UPDATE_ALIM_SET);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
    }, []);

    const handleSave = useCallback(async () => {
        if (!selectedId) {
            toast.error('기본 템플릿을 선택해주세요.');
            return;
        }

        try {
            const { data: response } = await updateResAlimSet({
                variables: {
                    asId: data?.seeHospitalResAlimSet?.as_id,
                    asType: type,
                    asTime1: checkbox.time1,
                    asTime2: checkbox.time2,
                    asTime3: checkbox.time3,
                    asTime4: checkbox.time4,
                    asTemplateId: selectedId,
                }
            });

            if (response?.updateResAlimSet) {
                toast.info('예약 알림 기본 설정을 했습니다.');
                await refetch();
                handleClose();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [data, type, checkbox, selectedId]);

    useEffect(() => {
        if (!loading && data) {
            setSelectedId(data?.seeHospitalResAlimSet?.as_templateId);
            setType(data?.seeHospitalResAlimSet?.as_type);
            setCheckbox({
                time1: data?.seeHospitalResAlimSet?.as_time1,
                time2: data?.seeHospitalResAlimSet?.as_time2,
                time3: data?.seeHospitalResAlimSet?.as_time3,
                time4: data?.seeHospitalResAlimSet?.as_time4
            });
        }
    }, [loading, data,]);

    useEffect(() => {
        (async () => {
            if (siteMap === '예약 알림 기본 설정') {
                await refetch();
            }
        })();
    }, [siteMap]);

    return (
        <RightSlideModal
            title='예약 알림 기본 설정'
            visible={siteMap === '예약 알림 기본 설정'}
            onClose={handleClose}>
            <Wrapper>
                <InputForm>
                    <EmphasisText $margin='0 0 22px'>예약 알림 기본 설정하기</EmphasisText>

                    <EmphasisText $margin='30px 0 20px'>알림 발송 유형</EmphasisText>
                    <StyledFlexBox>
                        <StyledRadio
                            label='문자'
                            name='notification_type'
                            value='sms'
                            checked={type}
                            onChange={onChangeRadio}
                        />
                        <StyledRadio
                            label='카카오 알림톡'
                            margin='0 0 0 26px'
                            name='notification_type'
                            value='kakao'
                            checked={type}
                            onChange={onChangeRadio}
                        />
                    </StyledFlexBox>

                    <EmphasisText $margin='30px 0 20px'>발송 시간 선택</EmphasisText>
                    <StyledFlexBox>
                        <StyledCheckbox
                            label='즉시'
                            id='notification_time1'
                            name='time1'
                            checked={checkbox.time1}
                            onChange={onChangeCheckbox}
                        />
                        <StyledCheckbox
                            label='1일전'
                            margin='0 0 0 20px'
                            id='notification_time2'
                            name='time2'
                            checked={checkbox.time2}
                            onChange={onChangeCheckbox}
                        />
                        <StyledCheckbox
                            label='2일전'
                            margin='0 0 0 20px'
                            id='notification_time3'
                            name='time3'
                            checked={checkbox.time3}
                            onChange={onChangeCheckbox}
                        />
                        <StyledCheckbox
                            label='당일 오전 9시'
                            margin='0 0 0 20px'
                            id='notification_time4'
                            name='time4'
                            checked={checkbox.time4}
                            onChange={onChangeCheckbox}
                        />
                    </StyledFlexBox>

                    <TemplateList $height="calc(100vh - 440px)">
                        {loading
                            ? <Loader/>
                            : data?.seeHospitalResAlimSet?.templateList?.map((item, index) => (
                                <TemplateListRow
                                    key={`${index}-${item.rat_id}-list`}
                                    item={item}
                                    selectedId={selectedId}
                                    onChangeTemplate={onChangeTemplate}
                                />
                            ))
                        }
                    </TemplateList>
                </InputForm>
                <ButtonBox>
                    <StyledButton
                        PENCIL
                        width={214}
                        title='저장하기'
                        onClick={handleSave}
                    />
                    <StyledButton
                        CLOSE
                        title='닫기'
                        width={214}
                        fontColor={theme.colors.blackColor}
                        border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        bgColor={theme.colors.whiteColor}
                        onClick={handleClose}
                    />
                </ButtonBox>
            </Wrapper>

        </RightSlideModal>
    );
}

export default NotificationDefaultSettingModal;
