import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import RightSlideModal from "../../layout/RightSlideModal";
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";
import {SEE_DATE_RESERVATION_DETAIL} from "../../../graphql/Appointment/query";
import {appointmentPlatformOption, filterChange} from "../../../pages/Appointment/appointment.lib";
import StyledSelect from "../../styled/StyledSelect";
import Loader from "../../share/Loader";
import TimeSchedule from "./TimeSchedule";
import {
    CalendarDetailWrapper,
    SectionTitle,
    FilterBox,
    ScheduleSection,
    HourList,
    HourText,
} from "./appointment.style";
import ConfirmModal from "../../share/ConfirmModal";
import theme from "../../../styles/theme";
import {toast} from "react-toastify";

const AppointmentCalendarDetailModal = ({ scheduleTime = [], calendarDetailModal }) => {
    const { doctorsOption, setCalendarDetailModal, calendarSelectedDate, handleChangeStatus } = useContext(AppointmentContext);
    const confirmModalRef = useRef(null);
    const [filter, setFilter] = useState({
        doctors: '전체',
        appointmentPlatform: '전체'
    });
    const [confirmModal, setConfirmModal] = useState(false);
    const [scheduleInfo, setScheduleInfo] = useState(null);
    const [statusText, setStatusText] = useState({
        prev: '',
        next: ''
    });
    const [operationTime, setOperationTime] = useState([...Array(25).keys()]);

    const { data: dayData, loading: dayLoading, refetch: dayRefetch } = useQuery(SEE_DATE_RESERVATION_DETAIL, {
        variables: {
            year: calendarSelectedDate?.get('year'),
            month: calendarSelectedDate?.get('month') + 1,
            date: calendarSelectedDate?.get('date'),
            doctorRoom: filter.doctors === '전체' ? 'total' : filter.doctors,
            resPlatform: filterChange(filter.appointmentPlatform),
        },
        fetchPolicy: 'network-only'
    });

    const onChange = useCallback(e => { // 진료실, 예약 매체 onChange
        const { name, value } = e.target;

        setFilter({
            ...filter,
            [name]: value
        });
    }, [filter]);

    const handleClose = useCallback(() => { // 상세보기 모달 닫기
        setCalendarDetailModal(false);
        setFilter({
            doctors: '전체',
            appointmentPlatform: '전체'
        });
    }, []);

    const handleOpenStatusConfirm = useCallback(info => {
        if (info.re_status === 'cancel' || info.re_status === 'confirm') {
            toast.error('내원 확정 상태는 변경할 수 없습니다.');
            return;
        }
        setConfirmModal(true);
        setScheduleInfo(info);
    }, []);

    useEffect(() => {
        if (scheduleInfo) {
            let prev = '예약 대기';
            let next = '예약 완료';

            switch (scheduleInfo.re_status) {
                case 'waiting':
                    prev = '예약 대기';
                    next = '예약 완료';
                    break;
                case 'complete':
                    prev = '예약 완료';
                    next = '내원 확정';
                    break;
            }
            setStatusText({
                prev,
                next
            });
        }
    }, [scheduleInfo]);

    useEffect(() => {
        let tmpOperationTime = [];
        let fastestHour = Infinity;
        let slowestHour = -Infinity;

        if (scheduleTime.length !== 0) {
            scheduleTime.forEach(schedule => {
                const minInArray = Math.min(...schedule.operationTime);
                const maxInArray = Math.max(...schedule.operationTime);

                fastestHour = Math.min(fastestHour, minInArray); // 운영 시간 중 가장 빠른 시간
                slowestHour = Math.max(slowestHour, maxInArray);  // 운영 시간 중 가장 느린 시간
            });

            for (let i = fastestHour; i <= slowestHour; i++) {
                tmpOperationTime.push(i);
            }

            setOperationTime(tmpOperationTime);
        }
    }, [scheduleTime]);

    useEffect(() => {
        (async () => {
            if (calendarDetailModal) {
                await dayRefetch();
            }
        })();
    }, [calendarDetailModal]);

    if (dayLoading) return <Loader />;

    return (
        <RightSlideModal
            title={dayjs(calendarSelectedDate).format('YYYY년 MM월 DD일 (ddd)')}
            visible={calendarDetailModal}
            onClose={handleClose}>
            <CalendarDetailWrapper>
                <SectionTitle>총 {dayData?.seeDateReservationDetail?.totalResCount || 0}명</SectionTitle>
                <FilterBox>
                    <StyledSelect
                        NO_BORDER
                        title='진료실: '
                        width={filter.doctors.length > 5 ? 220 : 180}
                        name='doctors'
                        value={filter.doctors}
                        onChange={onChange}
                        options={['전체', ...doctorsOption.map(room => room.dr_roomName)]}
                    />
                    <StyledSelect
                        NO_BORDER
                        title='예약 매체: '
                        width={180}
                        margin='0 0 0 30px'
                        name='appointmentPlatform'
                        value={filter.appointmentPlatform}
                        onChange={onChange}
                        options={appointmentPlatformOption}
                    />
                </FilterBox>

                <ScheduleSection>
                    {operationTime.map(time => {
                        let hour = `${time < 10 ? `0${time}` : time}`;
                        let fullTime = `${hour}:00`;
                        let isSchedule = dayData?.seeDateReservationDetail?.byDateReservationDetailInfo.find(schedule => {
                            let startHour = schedule?.re_time?.split(':')[0];
                            return startHour === hour;
                        });

                        return (
                            <HourList key={`${time}-time`}>
                                <HourText>
                                    {fullTime}
                                </HourText>
                                <TimeSchedule
                                    isSchedule={isSchedule}
                                    dayData={dayData?.seeDateReservationDetail?.byDateReservationDetailInfo}
                                    hour={hour}
                                    fullTime={fullTime}
                                    handleClose={handleClose}
                                    dayRefetch={dayRefetch}
                                    handleOpenStatusConfirm={handleOpenStatusConfirm}
                                />
                            </HourList>
                        )
                    })}
                </ScheduleSection>
            </CalendarDetailWrapper>
            <ConfirmModal
                bgColor={theme.colors.activeBlue}
                title={`${statusText.next}으로 변경하시겠어요?`}
                subTitle={`"${scheduleInfo?.re_time} ${scheduleInfo?.re_patientName} - ${scheduleInfo?.re_doctorRoomName}"의 ${statusText.prev} 상태를 ${statusText.next} 상태로 변경하시겠어요?`}
                confirmTitle='예약 확정하기'
                ref={confirmModalRef}
                visible={confirmModal}
                handleConfirm={() => handleChangeStatus(scheduleInfo?.re_id, statusText.next, dayRefetch)}
                handleCancel={() => setConfirmModal(false)}
            />
        </RightSlideModal>
    )
}

export default AppointmentCalendarDetailModal;
