import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {IoCheckmarkCircleOutline} from 'react-icons/io5';
import theme from "../../../styles/theme";
import {autoHyphen} from "../../../utils/commons";
import dayjs from "dayjs";
import {filterChange} from "../../../pages/Appointment/appointment.lib";
import StyledIconButton from "../../styled/StyledIconButton";
import StyledIconSelect from "../../styled/StyledIconSelect";
import ConfirmModal from "../../share/ConfirmModal";
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import { TableRow, IconBox, TableCell, EmphasisText, DefaultText, InlineEmphasisText,  } from "./appointment.style";

const AppointmentTableRow = ({ list }) => {
    const { setDetailModal, setSelectedId, handleChangeStatus, handleUpdateAppointment, handleDeleteAppointment } = useContext(AppointmentContext);
    const confirmModalRef = useRef(null);
    const selectCellRef = useRef(null);
    const buttonCellRef = useRef(null);

    const [confirmModal, setConfirmModal] = useState(false);
    const [statusOption, setStatusOption] = useState([]);

    const handleOpenDetail = useCallback(e => { // 예약 상세보기
        if (
            !selectCellRef?.current?.contains(e.target) &&
            !buttonCellRef?.current?.contains(e.target) &&
            !confirmModal
        ) {
            setDetailModal(true);
            setSelectedId(list?.re_id);
        }
    }, [list, selectCellRef,  buttonCellRef, confirmModal]);

    useEffect(() => {
        let options = ['삭제하기'];
        let nextStatus = '';

        if (list?.status === 'waiting') {
            nextStatus = '예약 완료';
        } else if (list?.status === 'complete' || list?.status === 'confirm') {
            nextStatus = '내원 확정';
        } else if (list?.status === 'cancel') {
            nextStatus = '예약 취소';
        }
        options.unshift(nextStatus);
        setStatusOption(options);
    }, [list]);

    if (!list) return;

    return (
        <TableRow onClick={handleOpenDetail}>
            <TableCell
                $minWidth={42}
                $width={2}
                $marginRight={16}>
                <IconBox>
                    {list?.status === 'waiting'
                        ? 'new'
                        : <IoCheckmarkCircleOutline fontSize={18} color={theme.colors.activeBlue}/>}
                </IconBox>
            </TableCell>
            <TableCell
                $column
                $minWidth={100}
                $width={10}
                $marginRight={30}>
                <EmphasisText>{list?.time || '-'}</EmphasisText>
                <DefaultText>{list?.doctorRoomName || '-'}</DefaultText>
            </TableCell>
            <TableCell $width={4}>
                총&nbsp;<InlineEmphasisText>{list?.visitCount.toLocaleString() || 0}회</InlineEmphasisText>&nbsp;방문
            </TableCell>
            <TableCell
                $column
                $minWidth={100}
                $width={10}>
                <EmphasisText>{list?.patientName || '-'}</EmphasisText>
                <DefaultText>{list?.patientRrn || '-'}</DefaultText>
            </TableCell>
            <TableCell
                $minWidth={100}
                $width={10}>
                <DefaultText>{autoHyphen(list?.patientCellphone) || '-'}</DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={100}
                $width={10}>
                <DefaultText>최근 내원일</DefaultText>
                <DefaultText>
                    {list?.recentlyVisitDate ? dayjs(list?.recentlyVisitDate)?.format('YYYY-MM-DD') : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={120}
                $width={12}>
                <DefaultText>차트번호</DefaultText>
                <DefaultText>
                    {list?.patientChartNumber || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={180}
                $width={20}
                $marginRight={20}>
                <DefaultText>
                    {list?.oneLineMemo || '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={62}
                $width={4}>
                <DefaultText>
                    {list?.platform ? filterChange(list?.platform) : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                ref={selectCellRef}
                $minWidth={80}
                $width={8}>
                <StyledIconSelect
                    value={filterChange(list.status)}
                    options={statusOption}
                    disabled={list?.status === 'cancel' || list?.status === 'confirm'}
                    handleChangeStatus={(status) => handleChangeStatus(list.re_id, status)}
                    setConfirmModal={setConfirmModal}
                />
            </TableCell>
            <TableCell
                ref={buttonCellRef}
                $minWidth={68}
                $width={4}>
                <StyledIconButton
                    UPDATE
                    topRightRadius={0}
                    bottomRightRadius={0}
                    margin='0 0 0 -1px'
                    onClick={() => handleUpdateAppointment(list?.re_id)}
                />
                <StyledIconButton
                    DELETE
                    topLeftRadius={0}
                    bottomLeftRadius={0}
                    margin='0 0 0 -1px'
                    onClick={() => setConfirmModal(true)}
                />
            </TableCell>

            <ConfirmModal
                title='예약 내역 삭제'
                subTitle={`예약 내역을 삭제할까요? 삭제된 예약 내역은 모두 기록에서 삭제되며, 복구할 수 없습니다.`}
                confirmTitle='삭제하기'
                ref={confirmModalRef}
                visible={confirmModal}
                handleConfirm={() => handleDeleteAppointment(list?.re_id)}
                handleCancel={() => setConfirmModal(false)}
            />
        </TableRow>
    );
}

export default AppointmentTableRow;
