import React, {useCallback, useContext, useLayoutEffect, useState} from 'react';
import RightSlideModal from "../../../layout/RightSlideModal";
import {Wrapper, ButtonBox, InputForm} from "./setting.modal.style";
import ProfileImageUpload from "../../../share/ProfileImageUpload";
import {toast} from "react-toastify";
import {errorMessage} from "../../../../utils/commons";
import StyledInput from "../../../styled/StyledInput";
import StyledPostCode from "../../../styled/StyledPostCode";
import DaumPostModal from "../../../share/DaumPostModal";
import StyledButton from "../../../styled/StyledButton";
import theme from "../../../../styles/theme";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_HOSPITAL_DETAIL} from "../../../../graphql/Setting/query";
import {UPDATE_HOSPITAL} from "../../../../graphql/Setting/mutation";
import {SettingContext} from "../../../../pages/Setting/setting";

const HospitalSettingModal = () => {
    const { siteMap, handleCloseSiteMap } = useContext(SettingContext);

    const [previewUrl, setPreviewUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [inputs, setInputs] = useState({
        hospitalName: '',
        ceoName: '',
        hospitalNumber: '',
        businessNumber: '',
        address: '',
        detailAddress: '',
        medicalSubject: '',
        kakaoId: '',
        kakaoUrl: '',
        smsClientNumber: '',
        smsSenderNumber: ''
    });
    const [postModal, setPostModal] = useState(false);

    const { data: hospitalData, loading, refetch } = useQuery(SEE_HOSPITAL_DETAIL, {
        fetchPolicy: 'network-only'
    });
    const [updateHospital] = useMutation(UPDATE_HOSPITAL);

    const handleEditProfileImage = useCallback(async e => {
        const file = e.target.files[0];
        setPreviewUrl(URL.createObjectURL(file));
        setFile(file);
    }, []);

    const handleComplete = useCallback(data => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setInputs({
            ...inputs,
            address: fullAddress
        });
        setPostModal(false);
    }, [inputs]);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        })
    }, [inputs]);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
        setInputs({
            hospitalName: '',
            ceoName: '',
            hospitalNumber: '',
            businessNumber: '',
            address: '',
            detailAddress: '',
            medicalSubject: '',
            kakaoId: '',
            kakaoUrl: '',
            smsClientNumber: '',
            smsSenderNumber: ''
        });
        setFile(null);
        setPreviewUrl('');
    }, []);

    const handleSave = useCallback(async () => {
        try {
            const { data } = await updateHospital({
                variables: {
                    hspId: hospitalData?.seeHospitalDetail?.hsp_id,
                    hspImg: file,
                    hspName: inputs.hospitalName,
                    hspChief: inputs.ceoName,
                    hspHospitalNumber: inputs.hospitalNumber,
                    hspBusinessNumber: inputs.businessNumber,
                    hspAddress: inputs.address,
                    hspDetailAddress: inputs.detailAddress,
                    hspMedicalDepartment: inputs.medicalSubject,
                    hspKakaoChannelId: inputs.kakaoId,
                    hspKakaoChannelUrl: inputs.kakaoUrl,
                    hspMessageTrId: inputs.smsClientNumber,
                    hspMessageSendNum: inputs.smsSenderNumber,
                }
            });

            if (data.updateHospital) {
                toast.info('병원 정보를 설정했습니다.');
                await refetch();
                handleClose();
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, [hospitalData, file, inputs]);

    useLayoutEffect(() => {
        if (siteMap === '병원 정보' && !loading && hospitalData) {
            const detailData = hospitalData?.seeHospitalDetail;

            setInputs({
              hospitalName: detailData?.hsp_name || '',
              ceoName: detailData?.hsp_chief || '',
              hospitalNumber: detailData?.hsp_hospitalNumber || '',
              businessNumber: detailData?.hsp_businessNumber || '',
              address: detailData?.hsp_address || '',
              detailAddress: detailData?.hsp_detailAddress || '',
              medicalSubject: detailData?.hsp_medicalDepartment || '',
              kakaoId: detailData?.hsp_kakaoChannelId || '',
              kakaoUrl: detailData?.hsp_kakaoChannelUrl || '',
              smsClientNumber: detailData?.hsp_messageTrId || '',
              smsSenderNumber: detailData?.hsp_messageSendNum || ''
            });
            setPreviewUrl(detailData?.hsp_img || '');
        }
    }, [hospitalData, loading, siteMap]);

    return (
        <RightSlideModal
            title='병원 정보 설정'
            visible={siteMap === '병원 정보'}
            onClose={handleClose}>
            <Wrapper>
                <InputForm>
                    <ProfileImageUpload
                        userName='병원'
                        onChange={handleEditProfileImage}
                        previewUrl={previewUrl}
                    />
                    <StyledInput
                        ROW
                        title='병원명'
                        height={38}
                        margin='30px 0 12px'
                        name='hospitalName'
                        value={inputs.hospitalName}
                        onChange={onChange}
                        placeholder='병원명을 입력해주세요.'
                    />
                    <StyledInput
                        ROW
                        title='대표자'
                        height={38}
                        name='ceoName'
                        value={inputs.ceoName}
                        onChange={onChange}
                        placeholder='대표자명을 입력해주세요.'
                    />
                    <StyledInput
                        ROW
                        title='요양기관번호'
                        height={38}
                        margin='12px 0'
                        name='hospitalNumber'
                        value={inputs.hospitalNumber}
                        onChange={onChange}
                        placeholder='요양기관번호를 입력해주세요.'
                    />
                    <StyledInput
                        ROW
                        title='사업자번호'
                        height={38}
                        name='businessNumber'
                        value={inputs.businessNumber}
                        onChange={onChange}
                        placeholder='사업자번호를 입력해주세요.'
                    />
                    <StyledPostCode
                        margin='12px 0'
                        setPostModal={setPostModal}
                        address={inputs.address}
                        value={inputs.detailAddress}
                        onChange={onChange}
                    />
                    <StyledInput
                        ROW
                        title='진료과목'
                        height={38}
                        name='medicalSubject'
                        value={inputs.medicalSubject}
                        onChange={onChange}
                        placeholder='진료과목을 입력해주세요.'
                    />
                    <StyledInput
                        ROW
                        title='카카오톡 ID'
                        height={38}
                        margin='12px 0'
                        name='kakaoId'
                        value={inputs.kakaoId}
                        onChange={onChange}
                        placeholder='채널명(@채널아이디) 형식'
                    />
                    <StyledInput
                        ROW
                        title='카카오톡 채널 URL'
                        height={38}
                        name='kakaoUrl'
                        value={inputs.kakaoUrl}
                        onChange={onChange}
                        placeholder='카카오톡 채널 URL을 입력해주세요.'
                    />
                    <StyledInput
                        ROW
                        title='문자 거래처 번호'
                        height={38}
                        margin='12px 0'
                        name='smsClientNumber'
                        value={inputs.smsClientNumber}
                        onChange={onChange}
                        placeholder='문자 거래처 번호를 입력해주세요.'
                    />
                    <StyledInput
                        ROW
                        title='문자 발신자 번호'
                        height={38}
                        name='smsSenderNumber'
                        value={inputs.smsSenderNumber}
                        onChange={onChange}
                        placeholder='문자 발신자 번호를 입력해주세요.'
                    />
                </InputForm>
                <ButtonBox>
                    <StyledButton
                        PENCIL
                        width={214}
                        title='저장하기'
                        onClick={handleSave}
                    />
                    <StyledButton
                        CLOSE
                        title='닫기'
                        width={214}
                        fontColor={theme.colors.blackColor}
                        border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        bgColor={theme.colors.whiteColor}
                        onClick={handleClose}
                    />
                </ButtonBox>
            </Wrapper>

            {postModal && (
                <DaumPostModal
                    setPostModal={setPostModal}
                    handleComplete={handleComplete}
                />
            )}
        </RightSlideModal>
    );
}

export default HospitalSettingModal;
