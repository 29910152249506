import React, {useCallback, useEffect, useState} from "react";
import ModalBackground from "../../layout/ModalBackground";
import StyledFlexBox from "../../styled/StyledFlexBox";
import {GrayText, HistoryItem, HistoryList, LineText, StatusView} from "./insurance.style";
import theme from "../../../styles/theme";
import {useQuery} from "@apollo/client";
import {SEE_INSURANCE_HISTORY_DETAIL} from "../../../graphql/Insurance/query";
import {insuranceChange} from "../../../pages/Insurance/insurance.lib";
import Loader from "../../share/Loader";
import dayjs from "dayjs";
import { IoInfiniteSharp } from 'react-icons/io5';
import {handleBodyScroll} from "../../../utils/commons";

const InsuranceModal = ({insuranceModal, setInsuranceModal, selectedId, setSelectedId}) => {
    const [bgColor, setBgColor] = useState(theme.colors.activeBlue);

    const { data, loading } = useQuery(SEE_INSURANCE_HISTORY_DETAIL, {
        variables: {
            ihId: selectedId
        },
        fetchPolicy: 'network-only'
    });

    const handleClose = useCallback(() => {
        setInsuranceModal(false);
        setSelectedId(null);
        handleBodyScroll('initial');
    }, []);

    useEffect(() => {
        if (!loading && data) {
            switch (data?.seeInsuranceHistoryDetail?.status) {
                case 'processing':
                    setBgColor(theme.colors.activeBlue);
                    break;
                case 'complete':
                    setBgColor(theme.colors.activeGray);
                    break;
                case 'fail':
                    setBgColor(theme.colors.activeOrange);
                    break;
            }
        }
    }, [loading, data]);

    return (
        <ModalBackground
            title='처리내용'
            width={520}
            height={724}
            visible={insuranceModal}
            onClose={handleClose}>
            {loading
                ? <Loader />
                : (
                    <>
                        <StyledFlexBox justifyContent='space-between'>
                            <GrayText>
                                {data?.seeInsuranceHistoryDetail?.ih_createdAt
                                    ? `${dayjs(new Date(parseInt(data?.seeInsuranceHistoryDetail?.ih_createdAt, 10))).format('YYYY-MM-DD (ddd) A HH:mm')} 요청받음`
                                    : '-'
                                }
                            </GrayText>
                            <StatusView $bgColor={bgColor}>
                                {insuranceChange(data?.seeInsuranceHistoryDetail?.status)}
                            </StatusView>
                        </StyledFlexBox>
                        <HistoryList>
                            {data?.seeInsuranceHistoryDetail?.ihTextList?.map((list, index) => (
                                <HistoryItem key={`${list?.iht_id}-${index}-history-list`}>
                                    <IoInfiniteSharp fontSize={18} style={{ marginTop: 2 }} />
                                    <LineText>
                                        {list?.iht_text}
                                    </LineText>
                                    <GrayText>
                                        {dayjs(new Date(parseInt(list?.iht_createdAt, 10))).format('YYYY-MM-DD HH:mm:ss')}
                                    </GrayText>
                                </HistoryItem>
                            ))}
                        </HistoryList>

                    </>
                )
            }
        </ModalBackground>
    )
}

export default InsuranceModal;
