import styled, {css} from "styled-components";
import {BoldText, RegularText} from "../../../styled/StyledText";
import theme from "../../../../styles/theme";

export const Wrapper = styled.div`
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px;
`;
export const InputForm = styled.div`
  flex: 1;
`;
export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 40px 0 30px;
  background-color: ${theme.colors.ultraLightGrayBorder};
`;
export const BlueText = styled(BoldText)`
  font-size: 16px;
  color: ${theme.colors.activeBlue};
`;

// 기본 영업시간 설정
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  padding-bottom: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const ScheduleList = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const DaySchedule = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DayBox = styled.div`
  width: 42px;
  height: 42px;
  font-family: 'AppleSDGothicNeoBold';
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
export const DayDataCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const Hyphen = styled.div`
  margin: 26px 10px 0;
`;

// 조직 설정
export const CategoryList = styled.div`
  height: ${({ $height }) => $height ? `${$height}px` : `calc(100vh - 300px)`};
  overflow: scroll;
  padding-top: 16px;
  margin-top: 30px;
  border-top: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
`;
export const RankTitleBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;
export const RankTitle = styled(BoldText)`
  font-size: 16px;
  margin-bottom: 16px;
`;
export const PermissionText = styled(RegularText)`
  color: ${theme.colors.fontDarkGrayColor};
`;
export const JobCategoryRow = styled.div`
  padding: 16px 0;
  transition: .25s;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
`;
export const CategoryRow = styled.div`
  padding: 16px 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
`;
export const DeleteIcon = styled.span`
  color: ${theme.colors.fontLightGrayColor};
  cursor: pointer;
`;
export const MultiCheckBox = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;


// 예약 알림 템플릿 설정
export const TemplateList = styled.div`
  height: ${({ $height }) => $height ? $height : `calc(100vh - 560px)`};
  overflow: scroll;
  padding-top: 16px;
  margin-top: 30px;
  border-top: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
`;
export const TemplateRow = styled.div`
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  padding-bottom: 20px;
  & + & {
    margin-top: 20px;
  }
`;
export const TemplateContents = styled.div`
  color: ${theme.colors.fontDarkGrayColor};
  line-height: 1.4;
  white-space: pre-wrap;
  word-break: break-all;
`;
