import styled, { keyframes } from 'styled-components';
import { useCallback, useContext, useState } from 'react';
import {
    Wrapper,
    MsSmallRoot,
    FlexibleMenu,
    DrHippoParent,
    DrHippo,
    Dr,
    Span,
    Hippo,
    PlusFillIcon,
    PlatformParent,
    Platform,
    FrameParent,
    RectangleParent,
    FrameGroup,
    SearchIdParent,
    Component,
    StyledImage,
    LoginButton,
    LoaderBox,
    Loader,
    GrayText,
    Line,
    MenuText,
    CustomSlider
} from './login.styles';
import StyledInput from '../../components/styled/StyledInput';
import StyledPasswordInput from '../../components/styled/StyledPasswordInput';
import logo from '../../assets/images/logo.png';
import platformImage from '../../assets/images/platformImage.png';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import { isEmail } from '../../utils/commons';
import StyledFlexBox from '../../components/styled/StyledFlexBox';
import { useMutation } from '@apollo/client';
import { USER_LOGIN } from '../../graphql/Login/mutation';
import LogoutLogo from '../../components/share/LogoutLogo';
import { useNavigate } from 'react-router-dom';
import PlatformHeader from '../../components/layout/PlatformHeader';
import 'react-awesome-slider/dist/styles.css';
import platform_1 from '../../assets/images/platform_1.svg';
import platform_2 from '../../assets/images/platform_2.svg';
import platform_3 from '../../assets/images/platform_3.svg';
import platform_4 from '../../assets/images/platform_4.svg';
import emr_1 from '../../assets/images/emr_1.svg';
import emr_2 from '../../assets/images/emr_2.svg';
import medicalsoft_1 from '../../assets/images/medicalsoft_1.svg';
import medicalsoft_2 from '../../assets/images/medicalsoft_2.svg';
import imagePlus from '../../assets/images/plus.png';
const Login = () => {
    const navigate = useNavigate();
    const { userLogin } = useContext(AuthContext);
    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const { email, password } = inputs;

    const [adminLogin] = useMutation(USER_LOGIN);

    const onChangeInput = useCallback(
        e => {
            const { name, value } = e.target;

            setInputs({
                ...inputs,
                [name]: value
            });
        },
        [inputs]
    );

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();

            if (email === '') {
                toast.error('이메일을 입력해주세요.');
                return;
            }
            if (password === '') {
                toast.error('비밀번호를 입력해주세요.');
                return;
            }
            if (!isEmail(email)) {
                toast.error('이메일 형식이 아닙니다.');
                return;
            }

            setLoading(true);
            try {
                const { data } = await adminLogin({
                    variables: {
                        accountId: email,
                        password: password
                    }
                });

                if (data.userLogin) {
                    userLogin(data.userLogin);
                    setLoading(false);
                }
            } catch (e) {
                toast.error(e.message);
            } finally {
                setLoading(false);
            }
        },
        [email, password]
    );

    const [images, setImages] = useState([platform_1, platform_2, platform_3, platform_4]);
    const [key, setKey] = useState(0);
    const ChangeImage = titleName => {
        setImages([]);
        let newImages;
        if (titleName === 'Platform') {
            newImages = [platform_1, platform_2, platform_3, platform_4];
        } else if (titleName === 'EMR') {
            newImages = [emr_1, emr_2];
        } else if (titleName === 'MedicalSoft') {
            newImages = [medicalsoft_1, medicalsoft_2];
        }

        setKey(prevKey => prevKey + 1);
        setImages(newImages);
    };

    return (
        <MsSmallRoot>
            <FlexibleMenu>
                <DrHippoParent>
                    <DrHippo>
                        <Dr>Dr</Dr>
                        <Span>{`  `}</Span>
                        <Hippo>Hippo</Hippo>
                    </DrHippo>
                    <PlusFillIcon alt="" src={imagePlus} />
                </DrHippoParent>
                <PlatformParent>
                    <Platform>
                        <MenuText onClick={() => ChangeImage('Platform')}>Platform</MenuText>
                    </Platform>
                    <Platform>
                        <MenuText onClick={() => ChangeImage('EMR')}>EMR</MenuText>
                    </Platform>
                    <Platform>
                        <MenuText onClick={() => ChangeImage('MedicalSoft')}>MedicalSoft</MenuText>
                    </Platform>
                </PlatformParent>
                <FrameParent>
                    <RectangleParent>
                        <StyledInput
                            width={190}
                            height={35}
                            name="email"
                            value={email}
                            onChange={onChangeInput}
                            placeholder="이메일"
                        />
                        <StyledPasswordInput
                            width={190}
                            height={35}
                            name="password"
                            value={password}
                            onChange={onChangeInput}
                            placeholder="비밀번호"
                        />
                    </RectangleParent>
                    <FrameGroup>
                        <Wrapper>
                            <LoginButton onClick={handleSubmit}>
                                {loading ? (
                                    <LoaderBox>
                                        <Loader />
                                    </LoaderBox>
                                ) : (
                                    '로그인'
                                )}
                            </LoginButton>
                        </Wrapper>
                        <SearchIdParent>
                            <StyledFlexBox alignItems="center" justifyContent="flex-end">
                                <GrayText onClick={() => navigate('/find/email')}>
                                    Search ID
                                </GrayText>
                                <Line> </Line>
                                <GrayText onClick={() => navigate('/find/password')}>PW</GrayText>
                            </StyledFlexBox>
                        </SearchIdParent>
                    </FrameGroup>
                </FrameParent>
            </FlexibleMenu>
            <Component>
                <CustomSlider key={key} play={true} interval={15000} bullets={false}>
                    {images.map((image, index) => (
                        <div key={index} style={{ backgroundColor: 'white' }}>
                            <StyledImage src={image} />
                        </div>
                    ))}
                </CustomSlider>
            </Component>
        </MsSmallRoot>
    );
};

export default Login;
