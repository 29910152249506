import { gql } from '@apollo/client';

export const SEE_USER_INFO_DETAIL = gql`
    query SeeUserInfoDetail {
        seeUserInfoDetail {
            hsp_email
            user_id
            user_createdAt
            user_updatedAt
            user_creatorName
            user_creatorRank
            user_creatorId
            user_editorName
            user_editorRank
            user_editorId
            user_isDelete
            user_deleteDate
            userType
            user_name
            user_birthday
            user_cellphone
            user_email
            user_org
            user_rank
            user_job
            user_passwordInit
            user_pwUpdateDate
            user_address
            user_detailAddress
            user_img
            user_hnAlim
            user_pnAlim
            user_resAlim
            user_specialAlim
            user_hospitalName
            user_rankPermission {
                home
                reservation
                schedule
                patient
                did
                insurance
                cs
                setting
            }
        }
    }
`;

export const SEE_DOCTOR_ROOM = gql`
    query SeeDoctorRoom {
        seeDoctorRoom {
            totalLength
            doctorRoomList {
                dr_id
                dr_deptCode
                dr_roomName
                dr_doctorName
                dr_doctorRank
                dr_didOffDay
            }
        }
    }
`;

export const SEE_MEDICAL_SUBJECT = gql`
    query SeeMedicalSubject {
        seeMedicalSubject {
            medicalSubjectList {
                ms_id
                ms_name
            }
            totalLength
        }
    }
`;

export const SEE_MEDICAL_SUBJECT_DETAIL = gql`
    query SeeMedicalSubjectDetail($msId: Int!) {
        seeMedicalSubjectDetail(ms_id: $msId) {
            medicalSubjectDetailList {
                msd_name
                msd_id
            }
            totalLength
        }
    }
`;

export const SEE_RES_ALIM_TEMPLATE = gql`
    query SeeResAlimTemplate {
        seeResAlimTemplate {
            templateList {
                rat_title
                rat_id
                rat_text
            }
            totalLength
        }
    }
`;

export const SEE_HOSPITAL_RES_ALIM_SET = gql`
    query SeeHospitalResAlimSet {
        seeHospitalResAlimSet {
            as_id
            as_type
            as_time1
            as_time2
            as_time3
            as_time4
            as_templateId
            templateList {
                rat_id
                rat_title
                rat_text
            }
        }
    }
`;

export const SEARCH_PATIENT = gql`
    query SearchPatient($searchTerm: String) {
        searchPatient(searchTerm: $searchTerm) {
            totalLength
            patientList {
                pati_id
                pati_name
                pati_rrn
                pati_cellphone
                pati_chartNumber
            }
        }
    }
`;

export const SEE_DEFAULT_SCHEDULE = gql`
    query SeeDefaultSchedule {
        seeDefaultSchedule {
            totalLength
            defaultScheduleList {
                ds_id
                ds_day
                ds_startHour
                ds_startMin
                ds_endHour
                ds_endMin
                ds_lunchBreak
                ds_lbStartHour
                ds_lbStartMin
                ds_lbEndHour
                ds_lbEndMin
            }
        }
    }
`;

export const SEE_SEARCH_HISTORY = gql`
    query SeeSearchHistory {
        seeSearchHistory {
            sh_id
            sh_createdAt
            sh_text
        }
    }
`;

export const SEE_RANK = gql`
    query SeeRank {
        seeRank {
            totalLength
            rankList {
                rank_id
                rank_name
                rank_permission {
                    home
                    reservation
                    schedule
                    patient
                    did
                    insurance
                    cs
                    setting
                }
            }
        }
    }
`;

export const SEE_RESERVATION_ALIM_SET = gql`
    query SeeReservationAlimSet {
        seeReservationAlimSet {
            type
            time1
            time2
            time3
            time4
            template
            templateId
        }
    }
`;

export const SEE_MY_NOTI_HISTORY = gql`
    query SeeMyNotiHistory {
        seeMyNotiHistory {
            ng_id
            text
        }
    }
`;
