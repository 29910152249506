import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/logo.png';
import theme from "../../styles/theme";

const LogoutLogo = () => {
    return (
        <Wrapper>
            <Image src={logo}/>
        </Wrapper>
    );
}

export default LogoutLogo;

const Wrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid ${theme.colors.lightGrayBorder};
  position: relative;
  top: 42px;
  left: 42px;
  overflow: hidden;
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
`;
