import React, {useCallback, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {useLazyQuery, useMutation, useReactiveVar} from "@apollo/client";
import {SEE_SPECIAL_SCHEDULE_DETAIL} from "../../../graphql/Schedule/query";
import {statusChange} from "../../../pages/Schedule/schedule.lib";
import theme from "../../../styles/theme";
import Loader from "../../share/Loader";
import noUser from '../../../assets/images/noUser.png';
import ModalBackground from "../../layout/ModalBackground";
import {
    DetailModalContents,
    Section,
    Line,
    StatusView,
    GrayText,
    CommentList,
    HistoryBox,
    ImageBox,
    Image,
    CommentText,
    CommentSubject,
    StrongText,
    CommentContents,
    PreviewList,
    HoverMenu,
    IconButton,
    Icon,
    IconButtonText
} from "./schedule.style";
import {errorMessage, handleBodyScroll, timeCalculator} from "../../../utils/commons";
import CommentInput from "../../share/CommentInput";
import {CREATE_SPECIAL_SCHEDULE_COMMENT, DELETE_SPECIAL_SCHEDULE_COMMENT} from "../../../graphql/Schedule/mutation";
import PreviewFile from "../../share/PreviewFile";
import {IoBanOutline, IoCheckmarkCircleOutline, IoTrashOutline} from "react-icons/io5";
import {userVar} from "../../../store";

const ScheduleDetailModal = ({
                                 selectedId,
                                 setSelectedId,
                                 scheduleDetailModal,
                                 setScheduleDetailModal
                             }) => {
    const inputRef = useRef();
    const scrollRef = useRef();
    const [bgColor, setBgColor] = useState(theme.colors.activeBlue);
    const [input, setInput] = useState('');
    const [previewList, setPreviewList] = useState([]);
    const [commentList, setCommentList] = useState([]);
    const user = useReactiveVar(userVar);
    const now = dayjs();

    const [seeSpecialScheduleDetail, {data, loading, refetch}] = useLazyQuery(SEE_SPECIAL_SCHEDULE_DETAIL, {
        variables: {
            ssId: selectedId,
        },
        fetchPolicy: 'network-only'
    });
    const [createSpecialScheduleComment] = useMutation(CREATE_SPECIAL_SCHEDULE_COMMENT);
    const [deleteSpecialScheduleComment] = useMutation(DELETE_SPECIAL_SCHEDULE_COMMENT);

    const handleClose = useCallback(() => {
        setScheduleDetailModal(false);
        setSelectedId(null);
        setInput('');
        setPreviewList([]);
        setCommentList([]);
        setBgColor(theme.colors.activeBlue);
        handleBodyScroll('initial');
    }, []);


    const handleDeleteComment = useCallback(async id => { // 댓글 삭제
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deleteSpecialScheduleComment({
                variables: {
                    sshId: id
                }
            });

            if (data.deleteSpecialScheduleComment) {
                toast.info('댓글을 삭제했습니다.');
                await refetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const handleSave = useCallback(async () => {
        if (input === '') {
            toast.error('댓글을 입력해주세요.');
            return;
        }

        try {
            const {data} = await createSpecialScheduleComment({
                variables: {
                    ssId: selectedId,
                    sshText: input
                }
            });

            if (data.createSpecialScheduleComment) {
                setInput('');
                toast.info('댓글을 추가했습니다.');
                await refetch();
                setTimeout(() => {
                    scrollRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }, 100);
            }
        } catch (e) {
            errorMessage(e.message);
            ;
        }
    }, [selectedId, input]);

    const onInputKeyPress = useCallback(async e => { // input keypress
        if (e.key === 'Enter') {
            if (input === '') {
                toast.error('댓글을 입력해주세요.');
                return;
            }
            await handleSave();
        }
    }, [input]);

    useEffect(() => {
        (async () => {
            if (scheduleDetailModal && selectedId) {
                await seeSpecialScheduleDetail();
            }
        })();
    }, [selectedId, scheduleDetailModal]);

    useEffect(() => {
        if (!loading && data) {
            switch (data?.seeSpecialScheduleDetail?.ss_status) {
                case 'sign':
                    setBgColor(theme.colors.activeBlue);
                    break;
                case 'noSign':
                    setBgColor(theme.colors.activeGray);
                    break;
                case 'reject':
                    setBgColor(theme.colors.activeOrange);
                    break;
            }

            const tmpCommentList = data?.seeSpecialScheduleDetail?.specialScheduleHistory?.map(comment => {
                if (!comment) return [];

                return {
                    commentId: comment.ssh_id || 0,
                    creatorId: comment.ssh_creatorId || null,
                    createDate: comment.ssh_createdAt || new Date(),
                    creator: comment.ssh_creatorName || '',
                    creatorRank: comment.ssh_creatorRank || '',
                    creatorImage: comment.ssh_creatorImg || '',
                    creatorName: comment.ssh_creatorName || '',
                    comment: comment.ssh_text || '',
                    type: comment.ssh_type,
                    status: comment.ssh_confirmStatus
                }
            });
            const tmpPreviewList = data?.seeSpecialScheduleDetail?.specialScheduleAttactheds?.map(file => {
                if (!file) return [];
                const splitUrl = file.sa_url.split('/');
                let fileName = splitUrl[splitUrl.length - 1];
                fileName = fileName.slice(14, fileName.length);

                return {
                    name: fileName || '',
                    url: file.sa_url,
                    size: file.sa_fileSize
                }
            });

            setCommentList(tmpCommentList);
            setPreviewList(tmpPreviewList);
        }
    }, [loading, data]);

    return (
        <ModalBackground
            width={900}
            height={724}
            title='특별일정'
            visible={scheduleDetailModal}
            onClose={handleClose}>
            <DetailModalContents>
                {loading
                    ? <Loader/>
                    : (
                        <>
                            <Section $flex={1.5}>
                                <StatusView $bgColor={bgColor}>
                                    {statusChange(data?.seeSpecialScheduleDetail?.ss_status)}
                                </StatusView>
                                <GrayText>
                                    {dayjs(data?.seeSpecialScheduleDetail?.ss_createdAt).format('YYYY-MM-DD (ddd) A HH:mm')} 작성
                                </GrayText>
                                <CommentList>
                                    {commentList.map(comment => {
                                        let timeText = '';

                                        let duration = now.diff(comment?.createDate, 'second');
                                        timeText = timeCalculator(duration);

                                        if (comment?.type !== 'comment') { // 특별 일정 history
                                            return (
                                                <HistoryBox key={`${comment?.commentId}-list`}>
                                                    <ImageBox>
                                                        {comment?.status === 'notSign'
                                                            ? <Image
                                                                src={comment?.creatorImage ? comment?.creatorImage : noUser}
                                                                onError={e => e.target.src = noUser}
                                                            />
                                                            : comment?.status === 'reject'
                                                                ? <IoBanOutline fontSize={21} color={theme.colors.activeRed}/>
                                                                : <IoCheckmarkCircleOutline fontSize={24} color={theme.colors.activeBlue}/>
                                                        }
                                                    </ImageBox>
                                                    <CommentContents>
                                                        <CommentSubject>
                                                            <StrongText>
                                                                {comment?.comment || ''}
                                                            </StrongText>
                                                            <GrayText $margin='0 0 0 12px'>{timeText}</GrayText>
                                                        </CommentSubject>
                                                    </CommentContents>
                                                </HistoryBox>
                                            );
                                        }

                                        return ( // 특별 일정 댓글
                                            <HistoryBox key={`${comment?.commentId}-list`}>
                                                <ImageBox>
                                                    <Image
                                                        src={comment?.creatorImage ? comment?.creatorImage : noUser}
                                                        onError={e => e.target.src = noUser}
                                                    />
                                                </ImageBox>
                                                <CommentContents>
                                                    <CommentSubject>
                                                        <StrongText>
                                                            {comment?.creatorName || ''}&nbsp;
                                                            {comment?.creatorRank || ''}
                                                        </StrongText>

                                                        <GrayText $margin='0 0 0 12px'>{timeText}</GrayText>
                                                    </CommentSubject>
                                                    <CommentText>
                                                        {comment?.comment || ''}
                                                    </CommentText>
                                                </CommentContents>
                                                {user.user_id === comment.creatorId && (
                                                    <HoverMenu>
                                                        <IconButton onClick={() => handleDeleteComment(comment.commentId)}>
                                                            <Icon>
                                                                <IoTrashOutline fontSize={18}/>
                                                            </Icon>
                                                            <IconButtonText>삭제하기</IconButtonText>
                                                        </IconButton>
                                                    </HoverMenu>
                                                )}
                                            </HistoryBox>
                                        );
                                    })}
                                    <div ref={scrollRef}/>
                                </CommentList>
                                <CommentInput
                                    width='100%'
                                    input={input}
                                    setInput={setInput}
                                    onKeyPress={onInputKeyPress}
                                    handleSaveComment={handleSave}
                                    ref={inputRef}
                                    placeholder="댓글을 입력해주세요."
                                />
                            </Section>
                            <Line/>
                            <Section>
                                <GrayText>{previewList.length || 0}개의 첨부파일</GrayText>
                                <PreviewList>
                                    {previewList.map((file, index) => (
                                        <PreviewFile
                                            DOWNLOAD
                                            key={`${index}-file`}
                                            file={file}
                                            // onDownload={handleDownloadFile}
                                        />
                                    ))}
                                </PreviewList>
                            </Section>
                        </>
                    )
                }
            </DetailModalContents>
        </ModalBackground>
    )
}

export default ScheduleDetailModal;
