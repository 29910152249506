import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";

// 청구 요청 목록
export const TableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const TableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const ImageBox = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;

// 상세보기 모달
export const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
`;
export const StatusView = styled.div`
  min-width: 60px;
  height: 30px;
  font-size: 14px;
  color: ${theme.colors.whiteColor};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: ${({ $bgColor }) => $bgColor};
`;
export const HistoryList = styled.div`
  height: 530px;
  margin-top: 26px;
  overflow: scroll;
`;
export const HistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const LineText = styled.div`
  width: 55%;
  line-height: 1.4;
  word-break: break-all;
  white-space: pre-wrap;
`;
