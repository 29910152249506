import React, {useCallback, useEffect, useState} from 'react';
import ModalBackground from "../../../layout/ModalBackground";
import styled from "styled-components";
import StyledCheckbox from "../../../styled/StyledCheckbox";
import {MultiCheckBox} from "./setting.modal.style";
import {BoldText} from "../../../styled/StyledText";
import theme from "../../../../styles/theme";
import StyledButton from "../../../styled/StyledButton";
import {useMutation} from "@apollo/client";
import {UPDATE_RANK_PERMISSION} from "../../../../graphql/Manager/mutation";
import {toast} from "react-toastify";

const Contents = styled.div`

`;
const SubTitle = styled(BoldText)`
  font-size: 18px;
  margin-bottom: 30px;
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: 60px;
`;

const JobPermissionModal = ({jobPermissionModal, setJobPermissionModal, selectedRank, refetch}) => {
    const [permission, setPermission] = useState({
        home: true,
        appointment: false,
        schedule: false,
        patient: false,
        did: false,
        insurance: false,
        service: false,
        setting: false
    });

    const [updateRankPermission] = useMutation(UPDATE_RANK_PERMISSION);

    const onChange = useCallback(e => {
        const {name, checked} = e.target;

        setPermission({
            ...permission,
            [name]: checked
        });
    }, [permission]);

    const handleClose = useCallback(() => {
        setJobPermissionModal(false);
    }, []);

    const handleSave = useCallback(async () => {
        try {
            const { data } = await updateRankPermission({
                variables: {
                    rankId: selectedRank.rank_id,
                    rpReservation: permission.appointment,
                    rpSchedule: permission.schedule,
                    rpPatient: permission.patient,
                    rpDid: permission.did,
                    rpInsurance: permission.insurance,
                    rpCs: permission.service,
                    rpSetting: permission.setting,
                }
            });

            if (data.updateRankPermission) {
                toast.info('권한을 수정했습니다.');
                await refetch();
                handleClose();
            }
        } catch (e) {
            ;
        }
    }, [selectedRank, permission]);

    useEffect(() => {
        if (jobPermissionModal && selectedRank) {
            setPermission({
                home: true,
                appointment: selectedRank.rank_permission.reservation,
                schedule: selectedRank.rank_permission.schedule,
                patient: selectedRank.rank_permission.patient,
                did: selectedRank.rank_permission.did,
                insurance: selectedRank.rank_permission.insurance,
                service: selectedRank.rank_permission.cs,
                setting: selectedRank.rank_permission.setting
            });
        }
    }, [jobPermissionModal, selectedRank]);

    return (
        <ModalBackground
            title='직책 별 권한 수정'
            width={520}
            height={424}
            visible={jobPermissionModal}
            onClose={handleClose}>
            <Contents>
                <SubTitle>{selectedRank?.rank_name || ''}</SubTitle>
                <MultiCheckBox>
                    <StyledCheckbox
                        label='홈'
                        id='modify_permission_home'
                        name='home'
                        checked={permission.home}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='예약'
                        id='modify_permission_appointment'
                        name='appointment'
                        checked={permission.appointment}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='일정'
                        id='modify_permission_schedule'
                        name='schedule'
                        checked={permission.schedule}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='환자'
                        id='modify_permission_patient'
                        name='patient'
                        checked={permission.patient}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='DID'
                        id='modify_permission_did'
                        name='did'
                        checked={permission.did}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='보험'
                        id='modify_permission_insurance'
                        name='insurance'
                        checked={permission.insurance}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='고객센터'
                        id='modify_permission_service'
                        name='service'
                        checked={permission.service}
                        onChange={onChange}
                    />
                    <StyledCheckbox
                        label='환경설정'
                        id='modify_permission_setting'
                        name='setting'
                        checked={permission.setting}
                        onChange={onChange}
                    />
                </MultiCheckBox>

                <ButtonBox>
                    <StyledButton
                        title='닫기'
                        width={214}
                        fontColor={theme.colors.blackColor}
                        border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        bgColor={theme.colors.whiteColor}
                        onClick={handleClose}
                    />
                    <StyledButton
                        width={214}
                        title='수정하기'
                        bgColor={theme.colors.activeBlue}
                        onClick={handleSave}
                    />
                </ButtonBox>
            </Contents>
        </ModalBackground>
    );
}

export default JobPermissionModal;
