import React, {forwardRef, useCallback, useEffect, useRef} from "react";
import styled, {css} from "styled-components";
import {BoldText, RegularText} from "../styled/StyledText";
import theme from "../../styles/theme";
import StyledButton from "../styled/StyledButton";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(256, 256, 256, 0.7);
  transition: 0.25s;
  opacity: 0;
  visibility: hidden;
  
  ${({$visible}) => $visible && css`
    opacity: 1;
    visibility: visible;
    z-index: 101;
  `};
`;
const ModalContents = styled.div`
  width: 400px;
  min-height: 340px;
  padding: 40px;
  border-radius: 20px;
  position: relative;
  background-color: ${theme.colors.whiteColor};
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
`;
const Title = styled(BoldText)`
  text-align: center;
`;
const SubText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
  line-height: 1.6;
  word-break: keep-all;
  white-space: pre-wrap;
  margin: 30px 0 40px;
`;

const ConfirmModal = forwardRef((
    {
        bgColor = theme.colors.activeOrange,
        title = '작성을 취소할까요?',
        subTitle = '지금 작성을 완료하지 않고 취소하면, 작성한 내용이\n저장되지 않고 삭제됩니다.',
        confirmTitle = '작성 취소하기',
        visible,
        handleConfirm,
        handleCancel
    },
    ref
) => {
    // 모달 밖 클릭 시 닫기
    const handleClickOutside = useCallback(e => {
        if (visible && ref?.current?.contains(e.target)) {
            handleCancel();
        }
    }, [visible, ref]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);


    return (
        <Wrapper $visible={visible} ref={ref}>
            <ModalContents>
                <Title>{title}</Title>
                <SubText>
                    {subTitle}
                </SubText>
                <StyledButton
                    width={320}
                    bgColor={bgColor}
                    title={confirmTitle}
                    onClick={handleConfirm}
                />
                <StyledButton
                    width={320}
                    fontColor={theme.colors.blackColor}
                    fontFamily='AppleSDGothicNeoRegular'
                    margin='12px 0 0'
                    border='none'
                    bgColor={theme.colors.whiteColor}
                    title='취소'
                    onClick={handleCancel}
                />
            </ModalContents>
        </Wrapper>
    )
});

export default React.memo(ConfirmModal);
