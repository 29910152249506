import theme from "../styles/theme";
import dayjs from "dayjs";
import {toast} from "react-toastify";

// 휴대폰 번호 Hyphen 삽입
export const autoHyphen = (phoneNumber) => {
    return phoneNumber?.replace(/[^0-9]/g, "")?.replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        "$1-$2-$3"
    )?.replace("--", "-");
};

// 휴대폰 번호 hyphen 제거
export const removeHyphen = phoneNumber => {
    return phoneNumber.replace(/-/g, "");
}

export const isTime = time => {
    const timeRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
    return timeRegex.test(time);
}

// 생년월일 yyyy-mm-dd
export const birthFormatter = (number, type) => {
    if (!number){
        return '';
    }

    let formatNumber;
    number = number.replace(/\s/gi, "");
    if (number.length === 8) {
        switch (type) {
            case 'YYYY-MM-DD':
                formatNumber = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                break;
            default:
                formatNumber = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1년 $2월 $3일');
                break;
        }
    } else {
        formatNumber = number;
    }
    return formatNumber;
}

// 이메일 야부 정규식
export const isEmail = email => {
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
}

// 숫자만 입력 가능
export const onlyNumber = text => {
    return text.replace(/[^0-9]/g, '');
    // return text.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
};

// file size 단위 구하기
export const getFileSize = fileSize => {
    let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let index = Math.floor(Math.log(fileSize) / Math.log(1024));
    let size = (fileSize / Math.pow(1024, index)).toFixed(1);

    return `${size} ${units[index]}`;
}

// dayjs 시간 구하기
export const getHourMinute = (hour, minute) => {
    let time;

    if (hour === -1 || minute === -1) {
        return;
    }

    time = dayjs().set('hour', hour).set('minute', minute).format('HH:mm');
    return time;
}

// dayjs 10분 뒤 시간 구하기
export const getTenMinutesLater = (time) => {
    if (!time) {
        return null;
    }

    let hour = time.split(':')[0];
    let minute = time.split(':')[1];

    return dayjs().set('hour', hour).set('minute', minute).add(10, 'minute').format('HH:mm');
}

// 운영 스케줄 파트 별 배경색상 구하기
export const scheduleBgColor = minute => {
    if (minute.isBreakTime) {
        return theme.colors.activeLightPink;
    }
    if (minute.isLunchTime) {
        return theme.colors.activeLightYellow;
    }
    if (minute.isAMOpen || minute.isPMOpen) {
        return theme.colors.activeLightBlue;
    }
    return theme.colors.whiteColor;
}

// 에러 처리
export const errorMessage = type => {
    switch (type) {
        case 'err_00':
            toast.error( '데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
            break;
        case 'err_01':
            toast.error( '데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
            break;
    }
}

// 모달 열릴 때 body scroll 컨트롤
export const handleBodyScroll = style => {
    document.body.style.overflow = style;
}

// 숫자 특수문자 포함 여부
export const passwordNumberCheck = password => {
    let reg = /(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
    return reg.test(password);
}

// 문자열 길이 8~16자리 체크 여부
export const passwordLengthCheck = password => {
    let reg = /^.{8,16}$/;
    return reg.test(password);
}

// 댓글 시간 계산
export const timeCalculator = duration => {
    let timeText = '';

    if (duration <= 60) {
        timeText = '방금 전';
    } else if (duration > 60 && duration <= 3600) {
        timeText = `${Math.floor(duration / 60)}분 전`;
    } else if (duration > 3600 && duration <= 86400) {
        timeText = `${Math.floor(duration / 60 / 60)}시간 전`;
    } else if (duration > 86400 && duration <= 604800) {
        timeText = `${Math.floor(duration / 60 / 60 / 24)}일 전`;
    } else if (duration > 604800 && duration <= 2592000) {
        timeText = `${Math.floor(duration / 60 / 60 / 24 / 7)}주 전`;
    } else if (duration > 2592000 && duration <= 31536000) {
        timeText = `${Math.floor(duration / 60 / 60 / 24 / 30)}달 전`;
    } else if (duration > 31536000) {
        timeText = `${Math.floor(duration / 60 / 60 / 24 / 30 / 12)}년 전`;
    }

    return timeText;
}

// 업로드 파일 타입
export const getFileType = fileType => {

    if (fileType.includes('pdf')) {
        return 'pdf';
    } else if (fileType.includes('xls')) {
        return 'excel';
    } else if (fileType.includes('mp4')) {

    }
}
