import React, {useCallback, useState} from "react";
import dayjs from "dayjs";
import theme from "../styles/theme";

const fillEmptyColumns = (columns, start, end) => {
    const filledColumns = columns.slice(0);

    const startDay = dayjs(start).get('day');
    for (let i = 1; i <= startDay; i += 1) {
        const date = dayjs(start).subtract(i, 'day');
        filledColumns.unshift(date);
    }
    const endDay = dayjs(end).get('day');
    for (let i = 1; i <= 6 - endDay; i++) {
        const date = dayjs(end).add(i, 'day');
        filledColumns.push(date);
    }

    return filledColumns;
}

export const getCalendarDays = now => {
    const start = dayjs(now).startOf('month');
    const end = dayjs(now).endOf('month');
    const endDate = dayjs(end).get('date');

    const days = [];
    for (let i = 0; i < endDate; i++) {
        const date = dayjs(start).add(i, 'day');
        days.push(date);
    }
    return days;
}

export const getCalendarColumns = now => {
    const start = dayjs(now).startOf('month');
    const end = dayjs(now).endOf('month');
    const endDate = dayjs(end).get('date');

    const columns = [];
    for (let i = 0; i < endDate; i++) {
        const date = dayjs(start).add(i, 'day');
        columns.push(date);
    }

    const allDates = fillEmptyColumns(columns, start, end).reduce((acc, cur, index) => {
        const columnIndex = Math.floor(index / 7);

        if (!acc[columnIndex]) {
            acc[columnIndex] = [];
        }
        acc[columnIndex].push(cur);
        return acc;
    }, []);

    return allDates;
}


export const useCalendar = now => {
    const [selectedDate, setSelectedDate] = useState(now || dayjs());
    const getDay = ['일', '월', '화', '수', '목', '금', '토'];
    const getDayColor = useCallback(day => (
        day === 0
            ? theme.colors.activeRed
            : day === 6
                ? theme.colors.activeBlue
                : theme.colors.blackColor
    ), []);

    const onClickPrevMonth = useCallback(() => {
        const newSelectedDate = dayjs(selectedDate).subtract(1, 'month');
        setSelectedDate(newSelectedDate);
    }, [selectedDate]);

    const onClickNextMonth = useCallback(() => {
        const newSelectedDate = dayjs(selectedDate).add(1, 'month');
        setSelectedDate(newSelectedDate);
    }, [selectedDate]);

    return {
        selectedDate,
        getDay,
        getDayColor,
        onClickPrevMonth,
        onClickNextMonth
    }
}
