import {gql} from "@apollo/client";

export const CREATE_USER = gql`
    mutation CreateUser(
        $name: String!
        $email: String!
        $rank: String!
        $birthday: String
        $cellphone: String
        $org: String
        $job: String
    ) {
        createUser(
            name: $name
            email: $email
            rank: $rank
            birthday: $birthday
            cellphone: $cellphone
            org: $org
            job: $job
        )
    }
`;


export const UPDATE_USER = gql`
    mutation UpdateUser(
        $userId: Int!
        $birthday: String
        $name: String
        $cellphone: String
        $email: String
        $permission: String
        $org: String
        $rank: String
        $job: String
    ) {
        updateUser(
            user_id: $userId
            birthday: $birthday
            name: $name
            cellphone: $cellphone
            email: $email
            permission: $permission
            org: $org
            rank: $rank
            job: $job
        )
    }
`;

export const DELETE_USER = gql`
    mutation DeleteUser($userId: Int!) {
        deleteUser(user_id: $userId)
    }
`;

export const CHECK_USER_EMAIL = gql`
    mutation CheckUserEmail($email: String) {
        checkUserEmail(email: $email)
    }
`;

export const CREATE_ORG = gql`
    mutation CreateOrg($orgName: String!) {
        createOrg(org_name: $orgName)
    }
`;

export const DELETE_ORG = gql`
    mutation DeleteOrg($orgId: Int!) {
        deleteOrg(org_id: $orgId)
    }
`;

export const CREATE_RANK = gql`
    mutation CreateRank(
        $rankName: String!
        $home: Boolean
        $reservation: Boolean
        $schedule: Boolean
        $patient: Boolean
        $insurance: Boolean
        $setting: Boolean
        $cs: Boolean
        $did: Boolean
    ) {
        createRank(
            rank_name: $rankName
            home: $home
            reservation: $reservation
            schedule: $schedule
            patient: $patient
            insurance: $insurance
            setting: $setting
            cs: $cs
            did: $did
        )
    }
`;

export const DELETE_RANK = gql`
    mutation DeleteRank($rankId: Int!) {
        deleteRank(rank_id: $rankId)
    }
`;

export const UPDATE_RANK_PERMISSION = gql`
    mutation UpdateRankPermission(
        $rankId: Int!
        $rpReservation: Boolean
        $rpSchedule: Boolean
        $rpInsurance: Boolean
        $rpPatient: Boolean
        $rpDid: Boolean
        $rpCs: Boolean
        $rpSetting: Boolean
    ) {
        updateRankPermission(
            rank_id: $rankId
            rp_reservation: $rpReservation
            rp_schedule: $rpSchedule
            rp_insurance: $rpInsurance
            rp_patient: $rpPatient
            rp_did: $rpDid
            rp_cs: $rpCs
            rp_setting: $rpSetting
        )
    }
`;

export const CREATE_MEDICAL_SUBJECT = gql`
    mutation CreateMedicalSubject($msName: String) {
        createMedicalSubject(ms_name: $msName)
    }
`;

export const DELETE_MEDICAL_SUBJECT = gql`
    mutation DeleteMedicalSubject($msId: Int!) {
        deleteMedicalSubject(ms_id: $msId)
    }
`;

export const CREATE_MEDICAL_SUBJECT_DETAIL = gql`
    mutation CreateMedicalSubjectDetail($msId: Int!, $msdName: String) {
        createMedicalSubjectDetail(ms_id: $msId, msd_name: $msdName)
    }
`;

export const DELETE_MEDICAL_SUBJECT_DETAIL = gql`
    mutation DeleteMedicalSubjectDetail($msdId: Int!) {
        deleteMedicalSubjectDetail(msd_id: $msdId)
    }
`;

export const CREATE_RES_ALIM_TEMPLATE = gql`
    mutation CreateResAlimTemplate($title: String!, $text: String!) {
        createResAlimTemplate(title: $title, text: $text)
    }
`;

export const DELETE_RES_ALIM_TEMPLATE = gql`
    mutation DeleteResAlimTemplate($ratId: Int!) {
        deleteResAlimTemplate(rat_id: $ratId)
    }
`;
