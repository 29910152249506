import {toast} from "react-toastify";

// export const updateHospitalValidate = inputs => {
//     const { hospitalName, ceoName, businessNumber, address, detailAddress, medicalSubject, kakaoId, kaakoUrl, smsClientNumber, smsSenderNumber } = inputs;
//     let valid;
//
//     if (hospitalName === '') {
//         toast.error('병원명을 입력해주세요.');
//         valid = true;
//     } else if (ceoName === '') {
//         toast.error('대표자명을 입력해주세요.');
//         valid = true;
//     } else if (businessNumber === '') {
//         toast.error('사업자번호를 입력해주세요.');
//         valid = true;
//     } else if (businessNumber === '') {
//         toast.error('사업자번호를 입력해주세요.');
//         valid = true;
//     }
//
//     return valid;
// }

export const dayKorChange = day => {
    switch (day) {
        // Kor
        case 'mon':
            return '월';
        case 'tue':
            return '화';
        case 'wed':
            return '수';
        case 'thu':
            return '목';
        case 'fri':
            return '금';
        case 'sat':
            return '토';
        case 'sun':
            return '일';
        // Eng
        case '월요일':
            return 'mon';
        case '화요일':
            return 'tue';
        case '수요일':
            return 'wed';
        case '목요일':
            return 'thu';
        case '금요일':
            return 'fri';
        case '토요일':
            return 'sat';
        case '일요일':
            return 'sun';
    }
}

export const hourToInteger = value => {
    return parseInt(value.replace('시', ''), 10);
}

export const minuteToInteger = value => {
    return parseInt(value.replace('분', ''), 10);
}

export const permissionText = permissions => {
    const {home, reservation, schedule, patient, did, insurance, cs, setting } = permissions;

    return [
        home && '홈',
        reservation && '예약',
        schedule && '운영',
        patient && '환자',
        did && 'DID',
        insurance && '보험',
        cs && '고객센터',
        setting && '설정'
    ].filter(Boolean).join(' / ');
};

export const hourOption = [
    '00시',
    '01시',
    '02시',
    '03시',
    '04시',
    '05시',
    '06시',
    '07시',
    '08시',
    '09시',
    '10시',
    '11시',
    '12시',
    '13시',
    '14시',
    '15시',
    '16시',
    '17시',
    '18시',
    '19시',
    '20시',
    '21시',
    '22시',
    '23시'
];
export const minuteOption = [
    '00분',
    '10분',
    '20분',
    '30분',
    '40분',
    '50분'
];
