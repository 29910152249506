import React, {createContext, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {toast} from "react-toastify";
import ModalBackground from "../../../layout/ModalBackground";
import {
    defaultState,
    screenState,
    clinicState,
    monitorTypeChange,
    mediaTypeChange,
    booleanTypeChange,
    transmitMethodChange,
    resLocationChange,
    patientInfoTypeChange,
    replacedAndParsedText,
    screenRatioValidate, fileSizeValidation
} from '../../../../pages/DID/did.lib';
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledRadio from "../../../styled/StyledRadio";
import DefaultSetupForm from "./DefaultSetupForm";
import ScreenSetupForm from "./ScreenSetupForm";
import ClinicSetupForm from "./ClinicSetupForm";
import {useMutation} from "@apollo/client";
import {UPDATE_DID_MONITOR} from "../../../../graphql/DID/mutation";
import Loader from "../../../share/Loader";
import {useFileUpload} from "../../../../hooks/useFileUpload";

export const MonitorSetupContext = createContext(null);

const Contents = styled.div`
  height: 100%;
  position: relative;
`;
const SaveLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
`;

const MonitorSetupModal = ({setupModal, setSetupModal, selectedData, setSelectedData, refetch}) => {
    const { fileList, setFileList, previewList, setPreviewList, onChangeFile } = useFileUpload();
    const [setupType, setSetupType] = useState('default');
    const [options, setOptions] = useState({
        defaultState,
        screenState,
        clinicState
    });
    const [deletedFileId, setDeletedFileId] = useState([]);
    const [loading, setLoading] = useState(false);

    const [updateDIDMonitor] = useMutation(UPDATE_DID_MONITOR);

    const onChangeType = useCallback(e => {
        setSetupType(e.target.value);
        e.target.checked = false;
    }, []);

    const onChange = useCallback((e, type) => { // 입력 값 onChange
        const { name, value } = e.target;
        const updatedOptions = { ...options };

        if (value === '사용안함') { // 환자정보 표시 순서 사용안함 일 경우 비율 0% 고정
            const ratioName = `ratio_${name.split('_')[1]}`;

            updatedOptions.screenState = {
                ...options.screenState,
                [name]: value,
                [ratioName]: '0 %'
            };
        } else if (name === 'screenRatio') {
            updatedOptions[type] = {
                ...options[type],
                [name]: value,
                appointmentInfoLocation: '위'
            };
        } else {
            updatedOptions[type] = {
                ...options[type],
                [name]: value
            };
        }

        setOptions(updatedOptions);
    }, [options]);

    const handleClose = useCallback(() => {
        setOptions({
            defaultState,
            screenState,
            clinicState
        });
        setFileList([]);
        setPreviewList([]);
        setDeletedFileId([]);
        setSetupType('default');
        setSelectedData(null);
        setSetupModal(false);
    }, []);

    const handleSave = useCallback(async () => {
        const {defaultState, screenState, clinicState,} = options;
        const {
            patientInfo_1st,
            patientInfo_2nd,
            patientInfo_3rd,
            patientInfo_4th,
            ratio_1st,
            ratio_2nd,
            ratio_3rd,
            ratio_4th
        } = screenState;
        let daFileList = [];

        if (screenRatioValidate(
            patientInfo_1st,
            patientInfo_2nd,
            patientInfo_3rd,
            patientInfo_4th,
            ratio_1st,
            ratio_2nd,
            ratio_3rd,
            ratio_4th
        )) {
            return;
        }

        // 중복파일 검사
        if (fileSizeValidation(fileList)) {
            toast.error('첨부 파일은 10MB를 초과할 수 없습니다.');
            return;
        }

        setLoading(true);

        for (let file of fileList) {
            if (file.da_id) {
                daFileList.push(file);
            } else {
                daFileList.push({
                    da_id: 0,
                    da_file: file
                });
            }
        }

        try {
            const {data} = await updateDIDMonitor({
                variables: {
                    // 기본 설정
                    didId: selectedData.did_id, // DID id
                    didTitle: defaultState.name, // 모니터 제목
                    didDoctorRoomExpression: defaultState.clinicDisplayMethod, // 진료실 표시 방법
                    didStandbyPersonExpression: defaultState.standbyPersonDisplayMethod, // 대기자수 표시 방법
                    didErColorUsed: booleanTypeChange(defaultState.usingEmergencyPatient), // 응급환자 색상 구분
                    didErColor: defaultState.emergencyPatientColor, // 응급환자 색상
                    didHoldingColorUsed: booleanTypeChange(defaultState.usingHolders), // 보류자 색상 구분
                    didHoldingColor: defaultState.holdersColor, // 보류자 색상
                    didStandbyPersonFontsize: defaultState.holdersFontSize, // 대지가 글자 크기
                    didCalledPersonFontsize: defaultState.callerFontSize, // 호출자 글자 크기
                    didCalledTextUsed: booleanTypeChange(defaultState.usingCallPhrase), // 호출 문구 노출 사용
                    didCalledVoiceUsed: booleanTypeChange(defaultState.usingCallVoice), // 호출시 음성 호출 사용
                    didAttached: daFileList, // 업로드 파일
                    // 화면 설정
                    didMonitorType: monitorTypeChange(screenState.monitorType), // 모니터 종류 (가로, 세로)
                    didResUsed: booleanTypeChange(screenState.usingAppointmentInfo), // 예약 정보 사용 여부
                    didTransmitType: transmitMethodChange(screenState.transmissionMethod), // 예약 정보 송출 방식 (상시, 일시)
                    didMediaType: selectedData.did_mediaType, // 정보 분류 (영상, 이미지)
                    didResInfoLocation: resLocationChange(screenState.appointmentInfoLocation), //예약 정보 위치
                    didMonitorRatio: screenState.screenRatio, // 화면 비율
                    didPatExpress1: patientInfoTypeChange(screenState.patientInfo_1st), // 환자정보 표시 1번
                    didPatExpRatio1: replacedAndParsedText(screenState.ratio_1st, ' %'), // 1번 비율
                    didPatExpress2: patientInfoTypeChange(screenState.patientInfo_2nd), // 환자정보 표시 2번
                    didPatExpRatio2: replacedAndParsedText(screenState.ratio_2nd, ' %'), // 2번 비율
                    didPatExpress3: patientInfoTypeChange(screenState.patientInfo_3rd), // 환자정보 표시 3번
                    didPatExpRatio3: replacedAndParsedText(screenState.ratio_3rd, ' %'), // 3번 비율
                    didPatExpress4: patientInfoTypeChange(screenState.patientInfo_4th), // 환자정보 표시 4번
                    didPatExpRatio4: replacedAndParsedText(screenState.ratio_4th, ' %'), // 4번 비율
                    didResInfoTime: replacedAndParsedText(screenState.appointmentInfoDisplayTime, ' 초'), // 예약 정보 노출 시간
                    didResInfoCycle: replacedAndParsedText(screenState.appointmentInfoDisplayCycle, ' 초'), // 예약 정보 노출 주기
                    didLowMsgUsed: screenState.usingMessage === 'true', // 하단 메시지 사용
                    didNameMasking: screenState.isNameMaking, // 이름 마스킹 처리
                    didDoctorRoomIsHorizontal: screenState.isDoctorsHorizontal, // 진료실 가로 보기 사용
                    didDoctorRoomIsDivide: screenState.isDoctorsDivide, // 진료실 분할 보기 사용
                    // 진료실 설정
                    didDoctorRoomMerge: booleanTypeChange(clinicState.clinicIntegratedView), // 진료실 통합 보기 사용
                    didDoctorRoomInfoUpdate: clinicState.clinicRoom.map(list => ({ // 진료실 정보
                        ddr_id: list.ddr_id,
                        ddr_number: replacedAndParsedText(list.ddr_number, ' 순위'),
                        ddr_dayOff: booleanTypeChange(list.ddr_dayOff),
                        ddr_viewSelect: list.ddr_viewSelect
                    })),
                    didDeleteAttachedId: deletedFileId.filter(id => id)
                }
            });

            if (data.updateDidMonitor) {
                toast.info('모니터 설정을 변경했습니다.');
                await refetch();
                handleClose();
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    }, [selectedData, fileList, options, deletedFileId]);

    useEffect(() => {
        let clinicRoom = [];
        let tmpPreviewList = [];
        let tmpFileList = [];

        if (selectedData) {
            selectedData.didDoctorRoom.forEach(list => { // 진료실 정보 상태 생성
                clinicRoom.push({
                    ddr_id: list.ddr_id,
                    ddr_info: list.ddr_info,
                    ddr_number: `${list.ddr_number} 순위`,
                    ddr_dayOff: list.ddr_dayOff ? 'O' : 'X',
                    ddr_viewSelect: list.ddr_viewSelect
                });
            });

            selectedData.didAttached.forEach(file => {
                if (file) {
                    const fullName = file.da_url.split('/')[3] || '';
                    const fileName = fullName.substr(14, fullName.length);
                    const fileObj = new File([file.da_url], fileName, {type: file?.da_fileType});

                    tmpPreviewList.push({
                        da_id: file.da_id,
                        name: fileName,
                        url: file.da_url,
                        size: file.da_fileSize
                    });
                    tmpFileList.push({
                        da_id: file.da_id,
                        da_file: fileObj
                    });
                }
            });

            setPreviewList(tmpPreviewList);
            setFileList(tmpFileList);
            setOptions({
                defaultState: {
                    name: selectedData.did_title,
                    clinicDisplayMethod: selectedData.did_doctorRoomExpression,
                    standbyPersonDisplayMethod: selectedData.did_standbyPersonExpression,
                    usingEmergencyPatient: booleanTypeChange(selectedData.did_erColorUsed),
                    emergencyPatientColor: selectedData.did_erColor,
                    usingHolders: booleanTypeChange(selectedData.did_holdingColorUsed),
                    holdersColor: selectedData.did_holdingColor,
                    holdersFontSize: selectedData.did_standbyPersonFontsize,
                    callerFontSize: selectedData.did_calledPersonFontsize,
                    usingCallPhrase: booleanTypeChange(selectedData.did_calledTextUsed),
                    usingCallVoice: booleanTypeChange(selectedData.did_calledVoiceUsed)
                },
                screenState: {
                    monitorType: monitorTypeChange(selectedData.did_monitorType),
                    mediaType: mediaTypeChange(selectedData.did_mediaType),
                    usingAppointmentInfo: booleanTypeChange(selectedData.did_resUsed),
                    transmissionMethod: transmitMethodChange(selectedData.did_transmitType),
                    appointmentInfoLocation: resLocationChange(selectedData.did_resInfoLocation),
                    screenRatio: selectedData.did_monitorRatio,
                    patientInfo_1st: patientInfoTypeChange(selectedData.did_patExpress1),
                    ratio_1st: `${selectedData.did_patExpRatio1} %`,
                    patientInfo_2nd: patientInfoTypeChange(selectedData.did_patExpress2),
                    ratio_2nd: `${selectedData.did_patExpRatio2} %`,
                    patientInfo_3rd: patientInfoTypeChange(selectedData.did_patExpress3),
                    ratio_3rd: `${selectedData.did_patExpRatio3} %`,
                    patientInfo_4th: patientInfoTypeChange(selectedData.did_patExpress4),
                    ratio_4th: `${selectedData.did_patExpRatio4} %`,
                    appointmentInfoDisplayTime: `${selectedData.did_resInfoTime} 초`,
                    appointmentInfoDisplayCycle: `${selectedData.did_resInfoCycle} 초`,
                    usingMessage: selectedData.did_lowMsgUsed ? 'true' : 'false',
                    isNameMaking: !!selectedData.did_nameMasking,
                    isDoctorsHorizontal: !!selectedData.did_doctorRoomIsHorizontal,
                    isDoctorsDivide: !!selectedData.did_doctorRoomIsDivide
                },
                clinicState: {
                    clinicIntegratedView: booleanTypeChange(selectedData.did_doctorRoomMerge),
                    clinicRoom
                }
            });
        }
    }, [selectedData]);

    if (!selectedData) return;

    return (
        <ModalBackground
            CONFIM
            title='모니터 설정 변경'
            width={1042}
            vhHeight="calc(100vh - 200px)"
            // height={setupType === 'clinic' ? 776 : 934}
            visible={setupModal}
            onClose={handleClose}>
            <MonitorSetupContext.Provider value={{
                options,
                setOptions,
                onChange,
                onChangeFile,
                fileList,
                setFileList,
                previewList,
                setPreviewList,
                deletedFileId,
                setDeletedFileId,
                selectedData,
                handleClose,
            }}>
                <Contents>
                    {loading && (
                        <SaveLoading>
                            <Loader/>
                        </SaveLoading>
                    )}
                    <StyledFlexBox margin='0 0 36px'>
                        <StyledRadio
                            label='기본 설정'
                            name='setupType'
                            value='default'
                            checked={setupType}
                            onChange={onChangeType}
                        />
                        <StyledRadio
                            margin='0 20px'
                            label='화면 설정'
                            name='setupType'
                            value='screen'
                            checked={setupType}
                            onChange={onChangeType}
                        />
                        <StyledRadio
                            label='진료실 설정'
                            name='setupType'
                            value='clinic'
                            checked={setupType}
                            onChange={onChangeType}
                        />
                    </StyledFlexBox>
                    {setupType === 'default' && (
                        <DefaultSetupForm
                            handleSave={handleSave}
                        />
                    )}
                    {setupType === 'screen' && (
                        <ScreenSetupForm
                            handleSave={handleSave}
                        />
                    )}
                    {setupType === 'clinic' && (
                        <ClinicSetupForm
                            selectedData={selectedData}
                            handleSave={handleSave}
                        />
                    )}
                </Contents>
            </MonitorSetupContext.Provider>
        </ModalBackground>
    )
}

export default React.memo(MonitorSetupModal);
