import React, {useCallback, useEffect, useRef, useState} from "react";
import RightSlideModal from "../../../layout/RightSlideModal";
import noUser from "../../../../assets/images/noUser.png";
import platformLogo from '../../../../assets/images/plaformLogo.png';
import dayjs from "dayjs";
import PreviewFile from "../../../share/PreviewFile";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_PLATFORM_NOTICE_DETAIL} from "../../../../graphql/Home/query";
import {
    CREATE_PLATFORM_NOTICE_COMMENT,
    UPDATE_PLATFORM_NOTICE_COMMENT,
    DELETE_PLATFORM_NOTICE_COMMENT,
} from "../../../../graphql/Home/mutation";
import {toast} from "react-toastify";
import {errorMessage} from "../../../../utils/commons";
import {
    FileList,
    FileSection,
    FileTitle,
    InfoBox,
    InfoText,
    NoticeContents,
    NoticeHeader,
    NoticeSection,
    ContentBox,
    Image,
    ImageBox,
    Line,
    Title
} from "./notice.styles";
import CommentList from "./CommentList";

const PlatformNoticeDetailModal = ({
                                       commentModal,
                                       setCommentModal,
                                       selectedId,
                                       setSelectedId,
                                       noticeListRefetch,
                                   }) => {
    const scrollRef = useRef();
    const inputRef = useRef();
    const [input, setInput] = useState('');
    const [detailData, setDetailData] = useState({
        title: '',
        creatorName: '',
        creatorRank: '',
        creatorImage: '',
        createDate: new Date(),
        content: '',
        attachedFile: [],
        comments: []
    });
    const [commentId, setCommentId] = useState(null);

    const {data, loading, refetch} = useQuery(SEE_PLATFORM_NOTICE_DETAIL, {
        variables: {
            pnId: selectedId
        }
    });
    const [createPlatformNoticeComment] = useMutation(CREATE_PLATFORM_NOTICE_COMMENT);
    const [updatePlatformNoticeComment] = useMutation(UPDATE_PLATFORM_NOTICE_COMMENT);
    const [deletePlatformNoticeComment] = useMutation(DELETE_PLATFORM_NOTICE_COMMENT);

    const handleDownloadFile = useCallback(url => window.open(url), []);

    const handleAddComment = useCallback(async () => { // 댓글 등록
        try {
            const {data} = await createPlatformNoticeComment({
                variables: {
                    pnId: detailData.id,
                    text: input
                }
            });

            if (data.createPlatformNoticeComment) {
                toast.info('댓글을 작성했습니다.');
                setInput('');
                await refetch();
                await noticeListRefetch();
                setTimeout(() => {
                    scrollRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }, 100);
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [detailData, scrollRef, input]);

    const handleUpdateComment = useCallback(async () => { // 댓글 수정 api 호출
        try {
            const {data} = await updatePlatformNoticeComment({
                variables: {
                    pncId: commentId,
                    text: input,
                }
            });

            if (data.updatePlatformNoticeComment) {
                await refetch();
                setCommentId(null);
                setInput('');
                toast.info('댓글을 수정했습니다.');
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [input, commentId]);

    const handleModifyComment = useCallback(id => { // 댓글 수정하기 버튼 클릭
        const findComment = detailData.comments.find(list => list.commentId === id);
        if (findComment) {
            setInput(findComment.comment);
            setCommentId(id);
            inputRef?.current?.focus();
            return;
        }
        toast.error('수정할 수 없는 댓글입니다.');
    }, [detailData, inputRef]);

    const handleDeleteComment = useCallback(async id => { // 댓글 삭제
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deletePlatformNoticeComment({
                variables: {
                    pncId: id
                }
            });

            if (data.deletePlatformNoticeComment) {
                toast.info('댓글을 삭제했습니다.');
                await refetch();
                await noticeListRefetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const onInputKeyPress = useCallback(async e => { // input keypress
        if (e.key === 'Enter') {
            if (input === '') {
                toast.error('댓글을 입력해주세요.');
                return;
            }
            if (commentId) {
                await handleUpdateComment();
            } else {
                await handleAddComment();
            }
        }
    }, [commentId, input]);

    const handleSaveComment = useCallback(async () => { // 댓글 저장 버튼 클릭
        if (input === '') {
            toast.error('댓글을 입력해주세요.');
            return;
        }

        if (commentId) {
            await handleUpdateComment();
        } else {
            await handleAddComment();
        }
    }, [commentId, input]);

    const handleClose = useCallback(() => {
        setCommentModal(false);
        setSelectedId(null);
        setInput('');
        setCommentId(null);
        setDetailData({
            title: '',
            creatorName: '',
            creatorRank: '',
            creatorImage: '',
            createDate: new Date(),
            content: '',
            attachedFile: [],
            comments: []
        });
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setDetailData({
                id: data?.seePlatformNoticeDetail?.pn_id,
                title: data?.seePlatformNoticeDetail?.pn_title,
                creatorName: data?.seePlatformNoticeDetail?.pn_adminCreatorName,
                creatorImage: platformLogo,
                creatorRank: data?.seePlatformNoticeDetail?.pn_adminCreatorRank,
                createDate: data?.seePlatformNoticeDetail?.pn_createdAt,
                content: data?.seePlatformNoticeDetail?.pn_text,
                attachedFile: data?.seePlatformNoticeDetail?.pnAttached?.map(file => {
                    if (!file) return [];
                    const splitUrl = file.pna_url.split('/');
                    let fileName = splitUrl[splitUrl.length - 1];
                    fileName = fileName.slice(14, fileName.length);

                    return {
                        name: fileName || '',
                        url: file.pna_url,
                        size: file.pna_fileSize
                    }
                }),
                comments: data?.seePlatformNoticeDetail?.pnComment?.map(comment => {
                    if (!comment) return [];

                    return {
                        commentId: comment.pnc_id || 0,
                        creatorId: comment.pnc_creatorId || null,
                        createDate: comment.pnc_createdAt || new Date(),
                        creator: comment.pnc_creatorName || '',
                        creatorRank: comment.pnc_creatorRank || '',
                        creatorImage: comment.pnc_creatorImg || '',
                        comment: comment.pnc_text || '',
                        isAdmin: comment.pnc_admin || false
                    }
                })
            });
        }
    }, [loading, data]);

    return (
        <RightSlideModal
            title='공지사항'
            visible={commentModal}
            onClose={handleClose}>
            <NoticeContents>
                <NoticeSection>
                    <NoticeHeader>
                        <ImageBox>
                            <Image src={detailData.creatorImage} onError={e => e.target.src = noUser}/>
                        </ImageBox>
                        <InfoBox>
                            <Title>{detailData.title}</Title>
                            <InfoText>
                                {detailData.creatorName} {detailData.creatorRank}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {dayjs(detailData?.createDate).format('YYYY-MM-DD HH:mm')}
                            </InfoText>
                        </InfoBox>
                    </NoticeHeader>
                    <ContentBox>
                        {detailData.content}
                    </ContentBox>

                    <FileSection>
                        <FileTitle>{detailData.attachedFile.length}개의 첨부파일</FileTitle>
                        {detailData.attachedFile?.length > 0 && (
                            <FileList>
                                {detailData.attachedFile.map((file, index) => (
                                    <PreviewFile
                                        DOWNLOAD
                                        key={`${index}-file`}
                                        file={file}
                                        // onDownload={handleDownloadFile}
                                    />
                                ))}
                            </FileList>
                        )}
                    </FileSection>
                </NoticeSection>
                <Line/>
                <CommentList
                    // listHeight="calc(100% - 120px)"
                    input={input}
                    setInput={setInput}
                    onInputKeyPress={onInputKeyPress}
                    handleSaveComment={handleSaveComment}
                    commentList={detailData?.comments}
                    handleDeleteComment={handleDeleteComment}
                    handleModifyComment={handleModifyComment}
                    ref={{scrollRef, inputRef}}
                />
            </NoticeContents>
        </RightSlideModal>
    )
}

export default PlatformNoticeDetailModal;
