import React, {useEffect, useRef} from "react";
import styled, {css} from "styled-components";
import {IoCloseOutline} from 'react-icons/io5';
import theme from "../../styles/theme";
import {RegularText} from "../styled/StyledText";
import {getFileSize} from "../../utils/commons";

const Wrapper = styled.div`
  width: 100%;
  height: 42px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
const PreviewBox = styled.div`
  width: 42px;
  heighT: 40px;
  margin-right: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: 1px solid ${theme.colors.ultraLightGrayBorder};
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const InfoBox = styled.div`
  width: calc(100% - 60px);
`;
const Title = styled(RegularText)`
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 2px;
`;
const SizeText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.fontGrayColor};
`;
const DeleteIcon = styled.div`
  opacity: 0;
  transition: .25s;
  
  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

const SortablePreviewFile = ({
                         type,
                         file,
                         onDelete,
                     }) => {
    const ref = useRef();

    useEffect(() => {
        ref?.current?.load();
    }, [file, ref]);

    return (
        <Wrapper>
            <PreviewBox>
                {type === '영상'
                    ? (
                        <Video ref={ref}>
                            <source src={file.url}/>
                        </Video>
                    ) : (
                        <Image src={file.url}/>
                    )
                }
            </PreviewBox>
            <InfoBox>
                <Title>{file?.name}</Title>
                <SizeText>{getFileSize(file?.size || 0)}</SizeText>
            </InfoBox>
            <DeleteIcon onClick={onDelete}>
                <IoCloseOutline fontSize={20} color={theme.colors.activeOrange} />
            </DeleteIcon>
        </Wrapper>
    )
}

export default React.memo(SortablePreviewFile);
