import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import AppleSDGothicNeoBold from './fonts/AppleSDGothicNeoB.ttf';
import AppleSDGothicNeoMedium from './fonts/AppleSDGothicNeoM.ttf';
import AppleSDGothicNeoRegular from './fonts/AppleSDGothicNeoR.ttf';
import AppleSDGothicNeoLight from './fonts/AppleSDGothicNeoL.ttf';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

export default createGlobalStyle`
  ${reset};

  @font-face {
    font-family: 'AppleSDGothicNeoBold';
    src: url(${AppleSDGothicNeoBold});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoMedium';
    src: url(${AppleSDGothicNeoMedium});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoRegular';
    src: url(${AppleSDGothicNeoRegular});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoLight';
    src: url(${AppleSDGothicNeoLight});
  }

  * {
    box-sizing: border-box;
    white-space: nowrap;

    //scrollbar-width: none; /* 파이어폭스 */
    //-ms-overflow-style: none; /* 인터넷 익스플로러 */
    //&::-webkit-scrollbar {
    //  display: none;
    //}
    
    -ms-overflow-style: auto !important;; /* 인터넷 익스플로러 */
    scrollbar-width: block !important;; /* 파이어폭스 */
    &::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      display: block !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px !important;;
      background: ${({ theme }) => theme.colors.activeGray} !important;; /* 스크롤바의 색상 */
    }
  }

  body {
    font-size: 16px;
    font-family: 'AppleSDGothicNeoMedium', 'SF Pro Display', sans-serif;
    background-color: ${({ theme }) => theme.colors.whiteColor};
    overflow: auto;
  }

  a {
    color: ${({ theme }) => theme.colors.blackColor};
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    font-size: 16px;
    font-family: 'AppleSDGothicNeoMedium';
    border: none;
    outline: none;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      font-family: 'AppleSDGothicNeoRegular', 'SF Pro Display', sans-serif;
      color: ${({ theme }) => theme.colors.fontGrayColor};
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.whiteColor};
  }

  select {
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }
  
  li {
    list-style: none;
  }
  
  .Toastify__toast-container {
    width: 320px;
  }
  .Toastify__toast-body div {
    line-height: 1.2;
    white-space: pre-wrap;
    word-break: keep-all !important;
  }
`;
