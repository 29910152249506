import styled from "styled-components";
import {BoldText, RegularText} from "../../components/styled/StyledText";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  min-width: 1320px;
  width: 100vw;
  height: 100vh;
`;
export const Title = styled(BoldText)`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;
`;
export const GoBack = styled.button`
  font-size: 28px;
  display: flex;
  align-content: center;
  margin-right: 20px;
  cursor: pointer;
`;
export const FindBox = styled.div`
  width: 440px;
  margin: 62px auto 0;
`;
export const GuideLine = styled(RegularText)`
  color: ${theme.colors.fontGrayColor};
`;
export const BlueText = styled(BoldText)`
  font-size: 16px;
  color: ${theme.colors.activeBlue};
  margin-bottom: 20px;
`;
export const Line = styled.div`
  height: 1px;
  margin: 30px 0;
  background: ${theme.colors.ultraLightGrayBorder};
`;
export const EmailList = styled.ul`
  min-height: 200px;
  max-height: calc(100vh - 500px);
  margin-bottom: 40px;
  overflow-y: scroll;
`;
export const EmailItem = styled.li`
  height: 50px;
  padding: 16px 0;
  letter-spacing: 1px;
`;
